import React from 'react';
import QuizTable from './quizTable';
import QuizChart from './quizChart';
import QuizService from '../../services/quizService';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import LinkBtn from '../opionBtn/exportLink';
import { LoginContext } from '../../loginContext';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface QCProps {
    quizId: string;
    qdate: string;
}
interface QCState {
    quesionList: any[];
    correctAry: any[];
    timeAry: any[];
}

const columns = [
    {
        name: "試題編號",
        //selector: "quizQuesionId",
        sortable: true,
        width: "65%",
        cell: (row: any) => (
            <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top" style={{backgroundColor: '#fff', border: 'none'}}>
            { (row['quizQuesionName']['zh-TW'])? row['quizQuesionName']['zh-TW'] : row.quizQuesionId }
            </button>
        )
    },
    {
        name: "答對率",
        selector: "correctPerscent",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "鑑別度",
        selector: "distinguish",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "平均答題次數",
        selector: "avgAnswerTimes",
        sortable: true,
        width: "15%",
        center: true
    }
    /*,
    {
        name: "平均答題時間",
        selector: "avgAnswerTime",
        sortable: true,
        width: "15%",
        center: true
    }
    */
];

class QuizQuesion extends React.Component<QCProps, QCState>{
    private quizService: QuizService;
    private org: string;
    private account: string;
    private role: string;
    static contextType = LoginContext;

    constructor(props: QCProps) {
        super(props);
        this.quizService = new QuizService();
        this.org = "";
        this.account = "";
        this.role = "";
        this.state = {
            quesionList: [-1],
            correctAry: [-1],
            timeAry: [-1]
        }
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        this.getSingleQuizByQuesion(this.props.qdate, this.props.quizId, this.org, this.account, this.role);
    }

    getSingleQuizByQuesion = async (sdate: string, quizId: string, org: string, filter: string, role: string, refresh: string = "no") => {
        let quesionList = await this.quizService.getSingleQuizByQuesion(sdate, quizId, org, filter, role);
        if (refresh !== "no" && refresh === "0") {
            let [correctAry, timeAry] = await this.dataToChart(quesionList);
            this.setState({ correctAry: correctAry });
        } else if (refresh !== "no" && refresh === "1") {
            let [correctAry, timeAry] = await this.dataToChart(quesionList);
            this.setState({ timeAry: timeAry });
        } else {
            let quizDistinguish = await this.quizService.getQuizQuesionByUser(sdate, quizId, org, filter, role);
            for (let i = 0; i < quesionList.length; i++) {
                quesionList[i].distinguish = 0;
                for (let j = 0; j < quizDistinguish.length; j++) {
                    if (quesionList[i].quizQuesionId === quizDistinguish[j].quesionId) {
                        quesionList[i].distinguish = quizDistinguish[j].distinguish;
                        break;
                    }
                }
            }
            let [correctAry, timeAry] = await this.dataToChart(quesionList);
            this.setState({ correctAry: correctAry, timeAry: timeAry, quesionList: quesionList });
        }
    }

    refresh = (id: number) => {
        switch (id) {
            case 0: {
                this.setState({ correctAry: [-1] })
                this.getSingleQuizByQuesion(this.props.qdate, this.props.quizId, this.org, this.account, this.role, "0");
                break;
            }
            case 1: {
                this.setState({ timeAry: [-1] })
                this.getSingleQuizByQuesion(this.props.qdate, this.props.quizId, this.org, this.account, this.role, "1");
                break;
            }
        }
    }

    dataToChart = (ary: any[]): any => {
        let correctAry = [], timeAry = [];
        for (let i = 0; i < ary.length; i++) {
            let quesion = {
                id: i + 1,
                qid: ary[i].quizQuesionId,
                correctPerscent: parseInt(ary[i].correctPerscent.split("%")[0]),
                avgAnswerTime: ary[i].avgAnswerTime
            }
            correctAry.push(quesion);
            timeAry.push(quesion);
        }
        return [correctAry, timeAry];
    }

    roleType = () => {
        if (this.role === "admin" || this.role === "owner")
            return true;
        else
            return false;
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-md-12 py-3">
                        <div className="btn-toolbar mb-2 mb-md-0 d-flex flex-row-reverse">
                            <div className="input-group">
                                <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                    data-bs-toggle="dropdown" aria-expanded="false">更多
                                    <span data-feather="calendar"></span>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><LinkBtn link={(this.roleType()) ? `/embed/quiz/correct/${this.props.qdate}/${this.org}?id=${this.props.quizId}` : `/embed/quiz/correct/${this.props.qdate}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.props.quizId}`} /></li>                                     
                                    <li><button className="dropdown-item" onClick={() => this.refresh(0)}>重新整理</button></li>
                                    <li><CSVBtn data={this.state.correctAry} /></li>
                                    <li><ImgBtn id={0} /></li>
                                </ul>
                            </div>
                            <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3"></Popover.Header>
                                        <Popover.Body>
                                        呈現每一題試題的答對率、鑑別度與平均被回答的次數     
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <QuizChart
                            domain={{ y: [0, 100] }}
                            labelx={"題號"}
                            labely={"答對率(%)"}
                            x={"id"}
                            y={"correctPerscent"}
                            data={this.state.correctAry}
                            datamFunc={({ datum }: { datum: any }) => `${datum.correctPerscent}%`}
                            chartType={"bar"}

                        />
                    </div>
                    {/*
                    <div className="col-md-6 py-3">
                        <div className="btn-toolbar mb-2 mb-md-0 d-flex flex-row-reverse">
                            <div className="input-group">
                                <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                    data-bs-toggle="dropdown" aria-expanded="false">更多
                                    <span data-feather="calendar"></span>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><LinkBtn link={(this.roleType()) ? `/embed/quiz/time/${this.props.qdate}/${this.org}?id=${this.props.quizId}` : `/embed/quiz/time/${this.props.qdate}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.props.quizId}`} /></li>                                      
                                    <li><button className="dropdown-item" onClick={() => this.refresh(1)}>重新整理</button></li>
                                    <li><CSVBtn data={this.state.timeAry} /></li>
                                    <li><ImgBtn id={1} /></li>
                                </ul>
                            </div>
                            <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3"></Popover.Header>
                                        <Popover.Body>
                                                    
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <QuizChart
                            domain={{ y: [0, 300] }}
                            labelx={"題號"}
                            labely={"平均答題時間(sec)"}
                            x={"id"}
                            y={"avgAnswerTime"}
                            data={this.state.timeAry}
                            datamFunc={({ datum }: { datum: any }) => `${datum.avgAnswerTime}`}
                            chartType={"bar"}
                        />
                    </div>
                    */}
                </div>
                <div className="bg-body rounded shadow-sm mt-3 mb-3 px-3 py-1">
                    <div
                        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h3></h3>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3"></Popover.Header>
                                            <Popover.Body>
                                            試題鑑別度：每一個題目將受試者依總分高低排列序，取極端的 20% 為高低分組，然後求出高分組與低分組在每一個試題的答對率，以 PH 及 PL 表示，以「D=(PH-PL)」表示試題的鑑別度指數(item discrimination index) 。D 值介於 -1.00 到 ＋1.00 之間，D 值愈大，表示鑑別度愈大；D 值愈小，表示鑑別度愈小；D 值為 0，表示沒有鑑別度，可能是因為試題太容易或太艱難
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                                {/* <button type="button" className="btn btn-sm btn-outline-secondary">Share</button>
                                <button type="button" className="btn btn-sm btn-outline-secondary">Export</button> */}
                            </div>
                        </div>
                    </div>
                    {this.state.quesionList[0] === -1 ? <QuizTable columns={columns} loading={true} /> : <QuizTable columns={columns} data={this.state.quesionList} selected={false} />}
                </div>
            </>
        )
    }
}

export default QuizQuesion;