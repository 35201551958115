import moment from 'moment';
import React from 'react';
import CourseChart from './courseChart';
import CourseService from '../../services/courseService';
import Loading from 'react-loading';

interface FProps { }
interface FState {
    courseListByTimes: any[];
    courseListByHours: any[];
    scoreList: any[];
    courseListByUnit: any[];
}

class CourseEmbed extends React.Component<FProps, FState>{
    private courseService: CourseService;
    private sdate: string;
    private edate: string;
    private type: string;
    private date: string;
    private courseId: string;
    private org: string;
    private account: string;
    private role: string;

    constructor(props: any) {
        super(props);
        this.courseService = new CourseService();
        this.org = window.location.pathname.split("/")[5];
        this.type = window.location.pathname.split("/")[3];
        this.date = window.location.pathname.split("/")[4];
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.role = urlParams.get("role") || "";
        this.account = urlParams.get("acct") || "";
        this.courseId = decodeURIComponent(urlParams.get("id") || "");
        this.state = {
            courseListByTimes: [-1],
            courseListByHours: [-1],
            scoreList: [-1],
            courseListByUnit: [-1]
        }
        this.sdate = moment(this.date.slice(0, 8)).format('YYYY-MM-DD');
        this.edate = moment(this.date.slice(8, 16)).format('YYYY-MM-DD');
    }

    componentDidMount() {
        switch (this.type) {
            case "times":
                this.getCourseTimesByDay(this.date, this.org, this.account, this.role);
                break;
            case "hours":
                this.getCourseHoursByDay(this.date, this.org, this.account, this.role);
                break;
            case "hoursct":
                this.getSingleCourseHours(this.date, this.courseId, this.org, this.account, this.role);
                break;
            case "timesct":
                this.getSingleCourseAttend(this.date, this.courseId, this.org, this.account, this.role);
                break;
            case "score":
                this.getSingleCourseAttendByUser(this.date, this.courseId, this.org, this.account, this.role);
                break;
            case "unittimes":
                this.getSingleCourseAttendByUnit(this.date, this.courseId, this.org, this.account, this.role);
                break;
            case "unithours":
                this.getSingleCourseAttendByUnit(this.date, this.courseId, this.org, this.account, this.role);
                break;
        }
    }

    getCourseHoursByDay = async (date: string, org: string, filter: string, role: string) => {
        let courseList: any = await this.courseService.getCourseHoursByDay(date, org, filter, role);
        let hourAry = await this.settingDailyData(courseList, "hour");
        this.setState({ courseListByHours: hourAry });
    }

    getCourseTimesByDay = async (date: string, org: string, filter: string, role: string) => {
        let courseList: any = await this.courseService.getCourseTimesByDay(date, org, filter, role);
        let timesAry = await this.settingDailyData(courseList, "times");
        this.setState({ courseListByTimes: timesAry });
    }

    getSingleCourseHours = async (date: string, courseId: string, org: string, filter: string, role: string) => {
        let courseList: any = await this.courseService.getSingleCourseHours(date, courseId, org, filter, role);
        let hourAry = await this.settingDailyData(courseList, "hour");
        this.setState({ courseListByHours: hourAry });
    }

    getSingleCourseAttend = async (date: string, courseId: string, org: string, filter: string, role: string) => {
        let courseList: any = await this.courseService.getSingleCourseAttend(date, courseId, org, filter, role);
        console.log(courseList)
        let timesAry = await this.settingDailyData(courseList, "times");
        console.log(timesAry);
        this.setState({ courseListByTimes: timesAry });
    }

    getSingleCourseAttendByUser = async (date: string, courseId: string, org: string, filter: string, role: string) => {
        let courseList: any = await this.courseService.getSingleCourseAttendByUser(date, courseId, org, filter, role);
        this.setState({ scoreList: courseList });
    }

    getSingleCourseAttendByUnit = async (date: string, courseId: string, org: string, filter: string, role: string) => {
        let courseList: any = await this.courseService.getSingleCourseAttendByUnit(date, courseId, org, filter, role);
        this.setState({ courseListByUnit: courseList });
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any, type: string) => {
        let dataAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false
            let index = 0
            let data = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }
            if (hasValue) {
                if (type === "hour") {
                    data.y = Math.floor(ary[index].costTime / 3600);
                    dataAry.push(data);
                } else if (type === "times") {
                    data.y = ary[index].attendTimes
                    dataAry.push(data);
                }
            }
            else {
                dataAry.push(data);
            }
        }
        return dataAry;
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    renderLoading() {
        return <Loading type={"spin"} color={"#ffffff"} height={'20%'} width={'20%'}></Loading>
    }

    renderType = () => {
        switch (this.type) {
            case "times":
                return (
                    <CourseChart
                        chartType={"line"}
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"次數"}
                        data={this.state.courseListByTimes}
                        datamFunc={({ datum }: { datum: any }) => `${datum.x}\n參與:${datum.y}次`}

                    />
                );
            case "hours":
                return (
                    <CourseChart
                        chartType={"line"}
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"小時"}
                        data={this.state.courseListByHours}
                        datamFunc={({ datum }: { datum: any }) => `${datum.x}\n參與:${datum.y}小時`}
                    />
                )
            case "timesct":
                return (
                    <CourseChart
                        chartType={"line"}
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"次數"}
                        data={this.state.courseListByTimes}
                        datamFunc={({ datum }: { datum: any }) => `${datum.x}\n參與${datum.y}次`}
                    />
                )
            case "hoursct":
                return (
                    <CourseChart
                        chartType={"line"}
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"小時"}
                        data={this.state.courseListByHours}
                        datamFunc={({ datum }: { datum: any }) => `${datum.x}\n參與${datum.y}小時`}
                    />
                )
            case "score":
                return (
                    <CourseChart
                        chartType={"point"}
                        labelx={"活躍度"}
                        labely={"成績"}
                        data={this.state.scoreList}
                        datamFunc={({ datum }: { datum: any }) => `${datum.name}\n活躍度:${datum.x} \n成績:${datum.y}`}
                    />
                )
            case "unittimes":
                return (
                    <CourseChart
                        chartType={"bar"}
                        labelx={"單元"}
                        labely={"次數"}
                        x={"unitName"}
                        y={"attendTimes"}
                        tickFormat={this.tickFormat}
                        data={this.state.courseListByUnit}
                        datamFunc={({ datum }: { datum: any }) => `${(datum.unitName).replace(/(.{5})/g, "$1\n")}\n參與:${datum.attendTimes}次`}

                    />
                )
            case "unithours":
                return (
                    <CourseChart
                        chartType={"bar"}
                        labelx={"單元"}
                        labely={"小時"}
                        x={"unitName"}
                        y={"costTime"}
                        tickFormat={this.tickFormat}
                        data={this.state.courseListByUnit}
                        datamFunc={({ datum }: { datum: any }) => `${(datum.unitName).replace(/(.{5})/g, "$1\n")}\n參與:${datum.costTime}小時`}
                    />
                )
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 pt-3">
                    {this.renderType()}
                </div>
            </div>
        );
    }
}

export default CourseEmbed;