import React from 'react';
import { getRandomString } from './randomFileName';

interface MProps {
    id: number
}
interface MState {

}

class ImgBtn extends React.Component<MProps, MState>{

    downloadImage = async (classId: any) => {
        const chart: any = document.getElementsByClassName("VictoryContainer")[classId];
        const svg = chart.querySelector('svg');
        const tempSVG = svg.cloneNode(true);
        tempSVG.setAttribute('height', 600)
        tempSVG.setAttribute('width', 1200)
        const s = new XMLSerializer().serializeToString(tempSVG);
        const src = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(s)))}`;
        const img = new Image();
        img.src = src;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const context: any = canvas.getContext('2d');
            context.drawImage(img, 0, 0);
            const ImgBase64 = canvas.toDataURL('image/png').replace(/^data:image\/[^;]/, 'data:application/octet-stream');
            const link = document.createElement('a');
            link.download = `${getRandomString()}_${new Date().toJSON().slice(0, 10)}.png`
            link.href = ImgBase64;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    render() {
        return (
            <button className="dropdown-item download" onClick={() => this.downloadImage(this.props.id)}>匯出圖片</button>
        )
    }
}

export default ImgBtn;