import HttpHandler from './APIServices';
import { ActorAPI } from './API';
import moment from 'moment';

class ActorService {
    private api: ActorAPI;

    constructor() {
        this.api = new ActorAPI()
    }

    getEventCountByActor = async (date: string, actorId: string, org:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventCountByActor}/${date}/${org}?actorId=${actorId}`;
        let data = await HttpHandler(url);
        return data;
    }

    getLastEventByActor = async (actorId: string, org:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getLastEventByActor}/${org}?actorId=${actorId}`;
        let data = await HttpHandler(url);
        let lastevent = {
            name: data[0].statement.actor.name,
            email: (data[0].statement.actor.mbox).replace("mailto:", ""),
            date: moment(data[0].timestamp).format('YYYY-MM-DD HH:mm:ss')
        }
        return lastevent;
    }

    getEventByActorAct = async (date: string, actorId: string, org:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventByActorAct}/${date}/${org}?actorId=${actorId}`;
        let data = await HttpHandler(url);
        return data;
    }

    getCoursefinishByActor = async (date: string, actorId: string, org:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getCoursefinishByActor}/${date}/${org}?actorId=${actorId}`;
        let data = await HttpHandler(url);
        return data;
    }

    getEventHourByActor = async (date: string, actorId: string, org:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventHourByActor}/${date}/${org}?actorId=${actorId}`;
        let data = await HttpHandler(url);
        return data;
    }

    getEventByActor = async (date: string, actorId: string, org:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventByActor}/${date}/${org}?actorId=${actorId}`;
        let data = await HttpHandler(url);
        return data;
    }

    getEventByActorDailyVerb = async (date: string, actorId: string, org:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventByActorDailyVerb}/${date}/${org}?actorId=${actorId}`;
        let data = await HttpHandler(url);
        return data;
    }

    getEventByActorDailyAct = async (date: string, actorId: string, org:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventByActorDailyAct}/${date}/${org}?actorId=${actorId}`;
        let data = await HttpHandler(url);
        return data;
    }

    getEventListByActor = async (date: string, actorId: string, org:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventListByActor}/${date}/${org}?actorId=${actorId}`;
        let data = await HttpHandler(url);
        return data;
    }

    getQuizListByActor = async (date: string, actorId: string, org:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getQuizListByActor}/${date}/${org}?actorId=${actorId}`;
        let data = await HttpHandler(url);
        if (data.length === 1)
            data.unshift({ name: "start", score: 0 })
        return data;
    }
}

export default ActorService;