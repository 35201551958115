import React from 'react';


interface CURProps {
    unitName: string;
    unitType: string;
    unitWeek: number;
    videoDuration: string;
    index: number;
    onInputChangeHandler: any;
    onSelectChangeHandler: any;
}

class CourseUnitRow extends React.Component<CURProps>{

    onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onInputChangeHandler(e.target.name, e.target.value, this.props.index);
    }

    onSelectChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.onSelectChangeHandler(e.target.name, e.target.value, this.props.index);
    }

    render() {
        return (
            <>
                <tr>
                    <td><input type="text" name="unitName" value={this.props.unitName} onChange={this.onInputChangeHandler} /></td>
                    <td><input type="text" name="videoDuration" value={this.props.videoDuration} onChange={this.onInputChangeHandler} /></td>
                    <td>
                        <select name="unitType" value={this.props.unitType} onChange={this.onSelectChangeHandler}>
                            <option>影片</option>
                            <option>討論</option>
                            <option>單元測驗</option>
                            <option>課程作業</option>
                            <option>後測</option>
                            <option>問卷</option>
                            <option>前測</option>
                        </select>
                    </td>
                    <td>
                        <select name="unitWeek" value={this.props.unitWeek} onChange={this.onSelectChangeHandler}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                        </select>
                    </td>
                </tr>
            </>
        )
    }

}

export default CourseUnitRow;