import React from 'react';
import memoize from 'memoize-one';
import AccountTable from './accountTable';
import { Link } from 'react-router-dom';
import PortalService from '../../services/portalService';

const columns = memoize((handleAction) => [
    {
        name: "使用者名稱",
        selector: "name",
        sortable: true,
        width: "40%"
    },
    {
        name: "帳戶",
        selector: "account",
        sortable: true,
        width: "40%"
    },
    {
        name: "",
        cell: (row: any) => <button className="genric-btn red-border" onClick={() => { handleAction(row) }}>刪除</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true,
        compact: true,
        button: true,
        width: "20%"
    }
]);

interface AState {
    studentList: any[];
}

class Student extends React.Component<{}, AState>{

    private portalService: PortalService;
    constructor(props: any) {
        super(props);
        this.portalService = new PortalService();
        this.state = {
            studentList: [-1],
        }
    }

    componentDidMount() {
        this.getStudent("student");
    }

    getStudent = async (role: string) => {
        let studentList = await this.portalService.getUserList(role);
        this.setState({ studentList: studentList });
    }

    deleteRow = async (data: any) => {
        if (window.confirm(`確定刪除 ${data.name} !!`)){
            let msg = await this.portalService.deleteUser(data);
            if (msg.success === false)
                alert("刪除失敗")
            else {
                this.getStudent("student");
            }
        }
    }

    render() {
        return (
            <>
                <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><Link to="/lrs/account/admin" style={{ textDecoration: "none", color: "#000000" }}><span>帳戶設定</span></Link>/</li>
                                <li><span>學生帳戶</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-1 pb-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                    <h4>學生帳戶</h4>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <div className="input-group">
                                            <Link to="/lrs/account/student/new" type="button"
                                                className="btn btn-success" >
                                                新增學生帳戶
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <AccountTable columns={columns(this.deleteRow)} data={this.state.studentList} />
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default Student;