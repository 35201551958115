import React from 'react';
import moment from 'moment';
import ActivityTable from './activityTable';
import ActivityChart from './activityChart';
import ActivityService from '../../services/activityService'
import Loading from 'react-loading';
import { Link } from 'react-router-dom';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import LinkBtn from '../opionBtn/exportLink';
import { LoginContext } from '../../loginContext';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const columns = [
    {
        name: "",
        selector: "statement",
        width: "100%",
        cell: (row: any) => {
            return (
                <p><strong>{row.statement.actor.name}</strong> {row.statement.verb.display['zh-TW']} {(row.statement.object.definition?.name !== undefined) ? row.statement.object.definition.name['zh-TW'] : row.statement.object.id} <span className="text-primary">{(row.statement.result?.score !== undefined) ? `成績${row.statement.result.score.raw}` : ""}{(row.statement.result?.duration !== undefined) ? ` 花費了${moment().startOf('day').second(moment.duration(row.statement.result.duration).asSeconds()).format('HH:mm:ss')}` : ""} </span> {moment(row.statement.timestamp).format('YYYY-MM-DD HH:mm:ss')}</p>
            )
        }

    }
];

interface QLState {
    lastEvent: string;
    actInfo: any;
    recentAct: any[];
    verbList: any[];
    sucessList: any[];
    actList: any[];
    actorList: any[];
    sdate: string;
    edate: string;
}

class ActivityContent extends React.Component<{}, QLState>{
    private actId: string;
    private sdate: string;
    private edate: string;
    private type: string;
    private name: string;
    private org: string;
    private account: string;
    private role: string;
    private date: string;
    private activityService: ActivityService
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        this.activityService = new ActivityService();
        this.org = "";
        this.account = "";
        this.role = "";
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.actId = decodeURIComponent(urlParams.get("id") || "")
        this.name = urlParams.get("name") || "";
        this.type = urlParams.get("type") || ""
        this.state = {
            lastEvent: "",
            recentAct: [-1],
            verbList: [-1],
            sucessList: [-1],
            actList: [-1],
            actorList: [-1],
            actInfo: {
                total: -1,
                people: -1,
                totalCostTime: "-1"
            },
            sdate: moment(urlParams.get("sdate"), 'YYYY-MM-DD', true).isValid() ? urlParams.get("sdate") || "" : moment().subtract(7, 'day').format('YYYY-MM-DD'),
            edate: moment(urlParams.get("edate"), 'YYYY-MM-DD', true).isValid() ? urlParams.get("edate") || "" : moment().format('YYYY-MM-DD'),
        }
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
        this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        this.getLastAct(this.actId, this.org, this.account, this.role);
        this.getActInfo(this.date, this.actId, this.org, this.account, this.role);
        this.getEventByDailyAct(this.date, this.actId, this.org, this.account, this.role);
        this.getActByVerb(this.date, this.actId, this.org, this.account, this.role);
        this.getActBySucess(this.date, this.actId, this.org, this.account, this.role);
        this.getRecentAct(this.date, this.actId, this.org, this.account, this.role);
    }

    retsetState = () => {
        this.setState({
            recentAct: [-1],
            verbList: [-1],
            sucessList: [-1],
            actList: [-1],
            actorList: [-1],
            actInfo: {
                total: -1,
                people: -1,
                totalCostTime: "-1"
            },
        })
    }

    getLastAct = async (actId: string, org: string, filter: string, role: string): Promise<any> => {
        let lastEvent = await this.activityService.getLastAct(actId, org, filter, role);
        this.setState({ lastEvent: lastEvent.date })
    }

    getActInfo = async (date: string, actId: string, org: string, filter: string, role: string): Promise<any> => {
        let actInfo = await this.activityService.getActInfo(date, actId, org, filter, role);
        this.setState({ actInfo: actInfo[0] })
    }

    getEventByDailyAct = async (date: string, actId: string, org: string, filter: string, role: string): Promise<any> => {
        let list = await this.activityService.getEventByDailyAct(date, actId, org, filter, role);
        let [actList, actorList] = await this.settingDailyData(list);
        this.setState({ actList: actList, actorList: actorList });
    }

    getActByVerb = async (date: string, actId: string, org: string, filter: string, role: string): Promise<any> => {
        let verbList = await this.activityService.getActByVerb(date, actId, org, filter, role);
        this.setState({ verbList: verbList })
    }

    getActBySucess = async (date: string, actId: string, org: string, filter: string, role: string): Promise<any> => {
        let sucessList = await this.activityService.getActBySucess(date, actId, org, filter, role);
        this.setState({ sucessList: sucessList })
    }

    getRecentAct = async (date: string, actId: string, org: string, filter: string, role: string): Promise<any> => {
        let recentAct = await this.activityService.getRecentAct(date, actId, org, filter, role);
        this.setState({ recentAct: recentAct });
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    roleType = () => {
        if (this.role === "admin" || this.role === "owner")
            return true;
        else
            return false;
    }

    refresh = (id: number) => {
        let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
        switch (id) {
            case 0: {
                this.setState({ actList: [-1] })
                this.getEventByDailyAct(date, this.actId, this.org, this.account, this.role);
                break;
            }
            case 1: {
                this.setState({ actorList: [-1] })
                this.getEventByDailyAct(date, this.actId, this.org, this.account, this.role);
                break;
            }
            case 2: {
                this.setState({ verbList: [-1] })
                this.getActByVerb(date, this.actId, this.org, this.account, this.role);
                break;
            }
            case 3: {
                this.setState({ sucessList: [-1] })
                this.getActBySucess(date, this.actId, this.org, this.account, this.role);
                break;
            }

        }
    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 30) {
            alert("日期區間請在一個月內")
        } else {
            this.retsetState();
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
            this.getLastAct(this.actId, this.org, this.account, this.role);
            this.getActInfo(this.date, this.actId, this.org, this.account, this.role);
            this.getEventByDailyAct(this.date, this.actId, this.org, this.account, this.role);
            this.getActByVerb(this.date, this.actId, this.org, this.account, this.role);
            this.getActBySucess(this.date, this.actId, this.org, this.account, this.role);
            this.getRecentAct(this.date, this.actId, this.org, this.account, this.role);
        }
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any) => {
        let actAry = [];
        let actorAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false
            let index = 0
            let actData = {
                x: dateAry[i],
                y: 0
            }
            let actordata = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                    break;
                }
            }
            if (hasValue) {
                actData.y = ary[index].event;
                actordata.y = ary[index].people;
                actAry.push(actData);
                actorAry.push(actordata);
            }
            else {
                actAry.push(actData);
                actorAry.push(actordata)
            }
        }
        return [actAry, actorAry];
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    renderLoading() {
        return <Loading type={"spin"} color={"#ffffff"} height={'20%'} width={'20%'}></Loading>
    }

    render() {
        return (
            <>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >學習行為分析</span></Link> \</li>
                                <li><Link to="/lrs/activity" style={{ textDecoration: "none", color: "#000000" }}><span>學習活動紀錄</span></Link> \</li>
                                <li><span>{this.name}</span></li>
                            </ul>
                        </div>
                        <div className="d-md-flex justify-content-md-end">
                            <div className="col-auto ms-2 py-1">
                                <div className="input-group">
                                    <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} />
                                </div>
                            </div>
                            <div className="col-auto ms-2 py-2 m-none lh-lg">~</div>
                            <div className="col-auto ms-2 py-1">
                                <div className="input-group">
                                    <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} />
                                </div>
                            </div>
                            <div className="col-auto ms-2 py-1">
                                <button className="btn btn-secondary" onClick={this.submitDateRange}>確定</button>
                            </div>
                        </div>
                        <div className="col-md-8 py-1">
                            <div className="h-100 p-3 bg-white rounded-3">
                                <h5>{this.name}</h5><br />
                                <h5>{this.type}</h5><br />
                                <h5>最近一次學習時間 {this.state.lastEvent}</h5><br />
                            </div >
                        </div >
                        <div className="col-md-4 py-1">
                            &nbsp;
                        </div>
                        <div className="col-md-2 py-1">
                            <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                                <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                    <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">活動紀錄</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，關於此學習活動的活動紀錄數量
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <h2>{(this.state.actInfo.total === -1) ? this.renderLoading() : this.state.actInfo.total}</h2>
                                <h5>活動紀錄</h5>
                            </div>
                        </div>
                        <div className="col-md-2 py-1">
                            <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                                <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                    <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">學生</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，參與此學習活動的不重複的學生數量
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <h2>{(this.state.actInfo.people === -1) ? this.renderLoading() : this.state.actInfo.people}</h2>
                                <h5>學生</h5>
                            </div>
                        </div>
                        <div className="col-md-2 py-1">
                            <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                                <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                    <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">學習時數</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，參與此學習活動所有學生的花費時間
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <h2>{(this.state.actInfo.totalCostTime === "-1") ? this.renderLoading() : this.state.actInfo.totalCostTime}</h2>
                                <h5>學習時數</h5>
                            </div>
                        </div>
                    </div >
                    <div className="row">
                        <div className="col-md-12 pt-3 pb-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h3>學習活動熱度</h3>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                            <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                <Popover>
                                                    <Popover.Header as="h3">學習活動熱度</Popover.Header>
                                                    <Popover.Body>
                                                    在篩選條件下，每天有關於此活動的所有學生的所有學習行為紀錄數量
                                                    </Popover.Body>
                                                </Popover>
                                            )}>
                                                <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="input-group">
                                            <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                                data-bs-toggle="dropdown" aria-expanded="false">更多
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li><LinkBtn link={(this.roleType()) ? `/embed/act/event/${this.date}/${this.org}?id=${this.actId}` : `/embed/act/event/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.actId}`} /></li>                                                
                                                <li><button className="dropdown-item" onClick={() => this.refresh(0)}>重新整理</button></li>
                                                <li><button className="dropdown-item" onClick={() => this.refresh(0)}>重新整理</button></li>
                                                <li><CSVBtn data={this.state.actList} /></li>
                                                <li><ImgBtn id={0} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                < ActivityChart
                                    tickFormat={this.tickFormat}
                                    labelx={"日期"}
                                    labely={"筆數"}
                                    data={this.state.actList}
                                    datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} records`}
                                    chartType={"line"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-3 pb-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h3>學生活動熱度</h3>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                            <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                <Popover>
                                                    <Popover.Header as="h3">學生活動熱度</Popover.Header>
                                                    <Popover.Body>
                                                    在篩選條件下，每天有學生對此活動進行學習的不重複的人數
                                                    </Popover.Body>
                                                </Popover>
                                            )}>
                                                <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="input-group">
                                            <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                                data-bs-toggle="dropdown" aria-expanded="false">更多
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li><LinkBtn link={(this.roleType()) ? `/embed/act/user/${this.date}/${this.org}?id=${this.actId}` : `/embed/act/user/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.actId}`} /></li>                                              
                                                <li><button className="dropdown-item" onClick={() => this.refresh(1)}>重新整理</button></li>
                                                <li><CSVBtn data={this.state.actorList} /></li>
                                                <li><ImgBtn id={1} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                < ActivityChart
                                    tickFormat={this.tickFormat}
                                    data={this.state.actorList}
                                    labelx={"日期"}
                                    labely={"筆數"}
                                    datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} students`}
                                    chartType={"line"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 pt-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                    <h4>相關學習動作</h4>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                            <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                <Popover>
                                                    <Popover.Header as="h3">相關學習動作</Popover.Header>
                                                    <Popover.Body>
                                                    在篩選條件下，有關於此活動的學習行為紀錄之「動作」數量百分比
                                                    </Popover.Body>
                                                </Popover>
                                            )}>
                                                <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="input-group">
                                            <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                                data-bs-toggle="dropdown" aria-expanded="false">更多
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li><LinkBtn link={(this.roleType()) ? `/embed/act/verb/${this.date}/${this.org}?id=${this.actId}` : `/embed/act/verb/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.actId}`} /></li>                                               
                                                <li><button className="dropdown-item" onClick={() => this.refresh(2)}>重新整理</button></li>
                                                <li><CSVBtn data={this.state.verbList} /></li>
                                                <li><ImgBtn id={2} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                < ActivityChart
                                    x={"name"}
                                    y={"event"}
                                    data={this.state.verbList}
                                    datum={({ datum }: { datum: any }) => `${datum.name}\n${Math.ceil(datum.event / this.state.verbList.map(x => x.event).reduce((a, c) => a + c) * 100)}%`}
                                    chartType={"circle"}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 pt-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                    <h4>學習結果</h4>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                            <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                <Popover>
                                                    <Popover.Header as="h3">學習結果</Popover.Header>
                                                    <Popover.Body>
                                                    在篩選條件下，有關於此活動的學習行為紀錄之「成功/通過與否」的數量百分比，以「測驗」活動為力，success 或 fail 是以是否及格 (60) 來區分
                                                    </Popover.Body>
                                                </Popover>
                                            )}>
                                                <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="input-group">
                                            <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                                data-bs-toggle="dropdown" aria-expanded="false">更多
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li><LinkBtn link={(this.roleType()) ? `/embed/act/sucess/${this.date}/${this.org}?id=${this.actId}` : `/embed/act/sucess/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.actId}`} /></li>                                              
                                                <li><button className="dropdown-item" onClick={() => this.refresh(3)}>重新整理</button></li>
                                                <li><CSVBtn data={this.state.sucessList} /></li>
                                                <li><ImgBtn id={3} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                < ActivityChart
                                    x={"name"}
                                    y={"total"}
                                    data={this.state.sucessList}
                                    chartType={"circle"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-3 pb-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h3>近期活動</h3>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                            <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                <Popover>
                                                    <Popover.Header as="h3">近期活動</Popover.Header>
                                                    <Popover.Body>
                                                    在篩選條件下，有關與此活動的所有學習行為紀錄
                                                    </Popover.Body>
                                                </Popover>
                                            )}>
                                                <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </div>
                                        {/*
                                        <div className="input-group">
                                            <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                更多
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li><a className="dropdown-item" href="#">Share</a></li>
                                            </ul>
                                        </div>
                                        */}
                                    </div>
                                </div>
                                {this.state.recentAct[0] === -1 ? < ActivityTable columns={columns} loading={true} /> : <ActivityTable columns={columns} data={this.state.recentAct} columnName={"name"} />}
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default ActivityContent;