import moment from 'moment';
import React from 'react';
import EventService from '../../services/eventService';
import ActorService from '../../services/actorService';
import ActorChart from './actorChart';
import Loading from 'react-loading';

interface OProps { }
interface OState {
    eventDateList: any[];
    eventList: any[];
    actorEventList: any[];
    hourList: any[];
    avgHourList: any[];
    eventVerb: any[];
    eventAct: any[];
    quizList: any[];

}

class ActorEmbed extends React.Component<OProps, OState>{
    private eventService: EventService;
    private sdate: string;
    private edate: string;
    private org: string;
    private account: string;
    private role: string;
    private type: string;
    private date: string;
    private actorService: ActorService;

    constructor(props: any) {
        super(props);
        this.eventService = new EventService();
        this.actorService = new ActorService();
        this.org = "";
        this.account = "";
        this.role = "";
        this.org = window.location.pathname.split("/")[5];
        this.type = window.location.pathname.split("/")[3];
        this.date = window.location.pathname.split("/")[4];
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.role = urlParams.get("role") || "";
        this.account = urlParams.get("acct") || "";
        this.state = {
            eventDateList: [-1],
            eventList: [-1],
            actorEventList: [-1],
            hourList: [-1],
            avgHourList: [-1],
            eventVerb: [-1],
            eventAct: [-1],
            quizList: [-1]
        }
        this.sdate = moment(this.date.slice(0, 8)).format('YYYY-MM-DD');
        this.edate = moment(this.date.slice(8, 16)).format('YYYY-MM-DD');
    }

    componentDidMount() {
        switch (this.type) {
            case "list":
                this.getEventByDate(this.date, this.org, this.account, this.role);
                break;
            case "hour":
                this.getEventHourByActor(this.date, window.atob(this.account), this.org)
                break;
            case "event":
                this.getEventByActor(this.date, window.atob(this.account), this.org)
                break;
            case "verb":
                this.getEventByActorDailyVerb(this.date, window.atob(this.account), this.org)
                break;
            case "act":
                this.getEventByActorDailyAct(this.date, window.atob(this.account), this.org)
                break;
            case "score":
                this.getQuizListByActor(this.date, window.atob(this.account), this.org)
                break;
        }
    }

    resetState = () => {
        this.setState({
            eventDateList: [-1],
        })
    }

    getEventByDate = async (date: string, org: string, filter: string, role: string): Promise<any> => {
        let eventDateList = await this.eventService.getEventByDate(date, org, filter, role);
        let eventList = await this.settingDailyData(eventDateList)
        this.setState({ eventDateList: eventList });
    }

    getEventHourByActor = async (date: string, actorId: string, org: string): Promise<any> => {
        let totalHour = await this.actorService.getEventHourByActor(date, actorId, org);
        let actorHourList = await this.settingContextDailyData(totalHour.actorCourseList, "hour");
        let avgHourList = await this.settingContextDailyData(totalHour.courseList, "avgHour");
        this.setState({ avgHourList: avgHourList, hourList: actorHourList })
    }

    getEventByActor = async (date: string, actorId: string, org: string): Promise<any> => {
        let event = await this.actorService.getEventByActor(date, actorId, org);
        let actorEventList = await this.settingContextDailyData(event.actorEventList, "event");
        let eventList = await this.settingContextDailyData(event.eventList, "avgEvent");
        this.setState({ eventList: eventList, actorEventList: actorEventList })
    }

    getEventByActorDailyVerb = async (date: string, actorId: string, org: string): Promise<any> => {
        let eventlist = await this.actorService.getEventByActorDailyVerb(date, actorId, org);
        let eventVerb = await this.settingBarData(eventlist);
        this.setState({ eventVerb: eventVerb })
    }

    getEventByActorDailyAct = async (date: string, actorId: string, org: string): Promise<any> => {
        let eventlist = await this.actorService.getEventByActorDailyAct(date, actorId, org);
        let eventAct = await this.settingBarData(eventlist);
        this.setState({ eventAct: eventAct })
    }

    getQuizListByActor = async (date: string, actorId: string, org: string): Promise<any> => {
        let quizList = await this.actorService.getQuizListByActor(date, actorId, org);
        this.setState({ quizList: quizList })
    }

    renderLoading() {
        return <Loading type={"spin"} color={"#FFFFFF"} height={'20%'} width={'20%'}></Loading>
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingBarData = (ary: any[]) => {
        let dataAry: any[] = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let j = 0; j < ary.length; j++) {
            let temp: any[] = []
            for (let i = 0; i < dayOfMonth; i++) {
                let hasValue = false
                let index = 0
                for (let k = 0; k < ary[j].events.length; k++) {
                    if (`${ary[j].events[k].year}/${("0" + ary[j].events[k].month).slice(-2)}/${("0" + ary[j].events[k].day).slice(-2)}` === dateAry[i]) {
                        hasValue = true;
                        index = k;
                        break;
                    }
                }
                let data = {
                    x: dateAry[i],
                    y: 0,
                    name: ""
                }
                if (hasValue) {
                    data.y = ary[j].events[index].event
                    data.name = ary[j].events[index].name
                    temp.push(data)
                }
                else {
                    temp.push(data)
                }
            }
            dataAry.push(temp);
        }
        return dataAry;
    }

    settingDailyData = (ary: any[]) => {
        let userAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false
            let index = 0
            let userData = {
                x: dateAry[i],
                y: 0
            }

            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }

            if (hasValue) {
                userData.y = Math.floor(ary[index].users)
                userAry.push(userData);
            }
            else {
                userAry.push(userData);
            }
        }
        return userAry;
    }

    settingContextDailyData = (ary: any, type: string) => {
        let dataAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false
            let index = 0
            let data = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }
            if (hasValue) {
                if (type === "event") {
                    data.y = Math.floor(ary[index].event)
                    dataAry.push(data);
                } else if (type === "avgEvent") {
                    data.y = Math.floor(ary[index].avgEvent)
                    dataAry.push(data);
                } else if (type === "hour") {
                    data.y = Math.floor(ary[index].costTime / 3600)
                    dataAry.push(data);
                } else if (type === "avgHour") {
                    data.y = Math.floor(ary[index].avgCostTime / 3600)
                    dataAry.push(data);
                }
            }
            else {
                dataAry.push(data);
            }
        }
        return dataAry;
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    renderType = () => {
        switch (this.type) {
            case "list":
                return (
                    <ActorChart
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"人數"}
                        data={this.state.eventDateList}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} users`}
                        chartType={"line"} />
                );
            case "event":
                return (
                    <ActorChart
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"活躍度"}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} records`}
                        data={this.state.actorEventList}
                        data2={this.state.eventList}
                        chartType={"doubleLine"}
                    />
                )
            case "hour":
                return (
                    <ActorChart
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"時數"}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} hour`}
                        data={this.state.hourList}
                        data2={this.state.avgHourList}
                        chartType={"doubleLine"} />
                )

            case "verb":
                return (
                    <ActorChart
                        tickFormat={this.tickFormat}
                        dataStack={this.state.eventVerb}
                        datamFunc={({ datum }: { datum: any }) => (datum.y !== 0) ? `${datum.x} \n${datum.name} ${datum.y} times` : ""}
                        chartType={"stack"}
                    />
                )
            case "act":
                return (
                    <ActorChart
                        tickFormat={this.tickFormat}
                        dataStack={this.state.eventAct}
                        datamFunc={({ datum }: { datum: any }) => (datum.y !== 0) ? `${datum.x} \n${datum.name} ${datum.y} times` : ""}
                        chartType={"stack"}
                    />
                )
            case "score":
                return (
                    <ActorChart
                        domain={{ y: [0, 100] }}
                        data={this.state.quizList}
                        x={"name"}
                        y={"score"}
                        datamFunc={({ datum }: { datum: any }) => (datum.year !== undefined) ? `${datum.year}/${("0" + datum.month).slice(-2)}/${("0" + datum.day).slice(-2)} \n${datum.score}` : ""}
                        chartType={"line"}
                    />
                )
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 pt-3">
                    {this.renderType()}
                </div>
            </div>
        );
    }
}
export default ActorEmbed;