import React from 'react';
import { Link } from 'react-router-dom';
import config from '../config';

const Footer: React.FC<{}> = () => (
    <footer id="footer" className="footer col-md-9 ms-sm-auto col-lg-10 px-md-4 fixed-bottom">
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    <Link to="/" className="pt-20" style={{ textDecoration: "none", color: "#FFFFFF	" }}>
                        {/* <img src="/img/visca-logo-white.png" width="100" alt="LOGO" /> */}
                        <p>{config.SITE_NAME}</p>
                    </Link>
                </div>
                {/*
                <div className="col-lg-5">
                    <ul className="me-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="#">隱私權政策</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">服務條款</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">開發者專區</a>
                        </li>
                        <li className="nav-item dropup">
                            <a className="nav-link dropdown-toggle" href="#" id="dropup" data-bs-toggle="dropdown"
                                aria-expanded="false">語系</a>
                            <ul className="dropdown-menu" aria-labelledby="dropup">
                                <li><a className="dropdown-item" href="#">English</a></li>
                                <li><a className="dropdown-item" href="#">中文</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                */}
            </div>
        </div>
    </footer>
);
export default Footer;