import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import QuizGrid from './quizGrid';
import QuizTable from './quizTable';
import QuizChart from './quizChart';
import QuizQuesion from './quizQuesion';
import QuizService from '../../services/quizService';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import LinkBtn from '../opionBtn/exportLink';
import { LoginContext } from '../../loginContext';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface QCProps { }
interface QCState {
    quizInfo: any;
    quizUser: any[];
    anayType: string;
    typeText: string;
    score: any[];
}

const columns = [
    {
        name: "學生",
        selector: "actorName",
        sortable: true,
        width: "22%",
        center: true
    },
    {
        name: "分數",
        selector: "score",
        sortable: true,
        width: "18%",
        center: true,
        cell: (row: any) => {
            if(row.score >= 60){
                return "是";
            }else{
                return <span style={{color: '#ff0000'}}>{'否'}</span>
            }
        }
    },
    {
        name: "作答時間",
        selector: "costTime",
        sortable: true,
        width: "20%",
        center: true
    },
    {
        name: "通過與否",
        selector: "passed",
        sortable: true,
        width: "10%",
        center: true,
        cell: (row: any) => {
            if(row.passed === '是'){
                return "是";
            }else{
                return <span style={{color: '#ff0000'}}>{'否'}</span>
            }
        }
    },
    {
        name: "完成時間",
        selector: "finishTime",
        sortable: true,
        width: "30%",
        center: true
    }
];

class QuizContent extends React.Component<QCProps, QCState>{
    private quizService: QuizService;
    private quizId: string;
    private qdate: string;
    private org: string;
    private account: string;
    private role: string;
    static contextType = LoginContext;

    constructor(props: QCProps) {
        super(props);
        this.quizService = new QuizService();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.quizId = decodeURIComponent(urlParams.get("quizId") || "");
        this.org = "";
        this.account = "";
        this.role = "";
        this.state = {
            quizInfo: {
                quizId: "",
                courseName: "",
                name: "",
                people: -1,
                passPeople: -1,
                avgcostTime: "-1",
                avgScore: -1,
                high: -1,
                low: -1,
                sigma: -1
            },
            score: [-1],
            quizUser: [-1],
            anayType: "complex",
            typeText: "綜合分析"
        }
        this.qdate = urlParams.get("qdate") || moment().clone().startOf('month').format('YYYY-MM-DD');
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        this.getSingleQuiz(this.qdate, this.quizId, this.org, this.account, this.role);
        this.getSingleQuizByUser(this.qdate, this.quizId, this.org, this.account, this.role);
    }

    getSingleQuiz = async (sdate: string, quizId: string, org: string, filter: string, role: string) => {
        let quiz = await this.quizService.getSingleQuiz(sdate, quizId, org, filter, role);
        quiz.avgcostTime = moment().startOf('day').seconds(quiz.avgcostTime).format("mm:ss");
        //let score = [-1];
        let score = await this.processScore(quiz.score);
        this.setState({ quizInfo: quiz, score: score });
    }


    getSingleQuizByUser = async (sdate: string, quizId: string, org: string, filter: string, role: string) => {
        let quiz = await this.quizService.getSingleQuizByUser(sdate, quizId, org, filter, role);
        for (let i = 0; i < quiz.length; i++) {
            quiz[i].costTime = moment().startOf('day').seconds(quiz[i].costTime).format("mm:ss")
            quiz[i].finishTime = moment(quiz[i].finishTime).format("YYYY-MM-DD HH:mm:ss");
            quiz[i].passed = quiz[i].passed === true ? "是" : "否";
        }
        this.setState({ quizUser: quiz });
    }

    changeAnalysis = (type: any, text: string) => {
        this.setState({ anayType: type });
        this.setState({ typeText: text });
    }

    refresh = () => {
        this.setState({ score: [-1] })
        this.getSingleQuiz(this.qdate, this.quizId, this.org, this.account, this.role);
    }

    roleType = () => {
        if (this.role === "admin" || this.role === "owner")
            return true;
        else
            return false;
    }

    exportBtn = () => {
        if (this.state.anayType === "complex") {
            return (
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                            <Popover>
                                <Popover.Header as="h3"></Popover.Header>
                                <Popover.Body>
                                呈現測驗分數的人數分布     
                                </Popover.Body>
                            </Popover>
                        )}>
                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                        </OverlayTrigger>
                    </div>
                    <div className="input-group">
                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            更多
                            <span data-feather="calendar"></span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li><LinkBtn link={(this.roleType()) ? `/embed/quiz/score/${this.qdate}/${this.org}?id=${this.quizId}` : `/embed/quiz/score/${this.qdate}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.quizId}`} /></li>
                            <li><button className="dropdown-item" onClick={this.refresh}>重新整理</button></li>
                            <li><CSVBtn data={this.state.score} /></li>
                            <li><ImgBtn id={0} /></li>
                        </ul>
                    </div>
                </div>
            )
        }
    }

    processScore = (ary: any) => {
        console.log(ary)
        let scoreRangeAry = [];
        for (let i = 0; i < 10; i++) {
            let scoreRange = {
                range: `${i * 10}-${(i + 1) * 10}`,
                value: 0
            }
            scoreRangeAry.push(scoreRange)
        }
        for (let i = 0; i < ary.length; i++) {
            if (ary[i] === 100) {
                scoreRangeAry[9].value++;
            }
            else {
                scoreRangeAry[Math.floor(ary[i] / 10)].value++;
            }
        }
        return scoreRangeAry;
    }

    analysisType = () => {
        if (this.state.anayType === "complex") {
            return (
                <>
                    <QuizChart
                        labelx={"分數區間"}
                        labely={"人數"}
                        x={"range"}
                        y={"value"}
                        data={this.state.score}
                        datamFunc={({ datum }: { datum: any }) => `${datum.value}`}
                        chartType={"bar"}
                    />
                    <div className="bg-body rounded shadow-sm mt-3 mb-3 px-3 py-1">
                        <div
                            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3>參與測驗學生結果</h3>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3">參與測驗學生結果</Popover.Header>
                                            <Popover.Body>
                                            呈現每一個學生的測驗結果，包含：分數、作答花費時間、通過與否(60 分)與完成測驗的時間
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        {this.state.quizUser[0] === -1 ? <QuizTable columns={columns} loading={true} /> : <QuizTable columns={columns} data={this.state.quizUser} columnName={"name"} selected={false} />}
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <QuizQuesion qdate={this.qdate} quizId={this.quizId} />
                </>
            )
        }
    }

    render() {
        return (
            <>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >學習內容分析</span></Link>/</li>
                                <li><Link to="/lrs/quiz" style={{ textDecoration: "none", color: "#000000" }}><span>測驗學習分析</span></Link>/</li>
                                <li><span>{this.state.quizInfo.name}</span></li>
                            </ul>
                        </div>
                        <h2>{this.state.quizInfo.courseName}-{this.state.quizInfo.name}</h2>
                        <QuizGrid
                            attendPeople={this.state.quizInfo.people}
                            passPeople={this.state.quizInfo.passPeople}
                            avgScore={this.state.quizInfo.avgScore}
                            avgCostTime={this.state.quizInfo.avgcostTime}
                            sigma={this.state.quizInfo.sigma}
                            highRange={this.state.quizInfo.high}
                            lowRange={this.state.quizInfo.low}
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                                data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                                aria-selected="true" onClick={() => this.changeAnalysis("complex", "綜合分析")}>綜合分析</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                                data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                                aria-selected="false" onClick={() => this.changeAnalysis("test", "試題分析")}>試題分析</button>
                                        </li>
                                    </ul>
                                    {this.exportBtn()}
                                </div>
                                <div className="tab-content" id="myTabContent">
                                    {this.analysisType()}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default QuizContent;