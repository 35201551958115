import React from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component'

interface CTProps {
    columns: IDataTableColumn[];
    data?: any;
    columnName?: string;
    tableStyle?: any;
    loading?: boolean;
    condictionStyle?: any
}
interface CTState { }

class CourseTable extends React.Component<CTProps, CTState>{

    render() {
        return (
            <DataTable
                columns={this.props.columns}
                data={this.props.data}
                defaultSortField={this.props.columnName}
                noHeader={true}
                style={this.props.tableStyle}
                pagination
                progressPending={this.props.loading}
                conditionalRowStyles={this.props.condictionStyle}
            />
        )
    }
}

export default CourseTable;