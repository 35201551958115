import React from 'react';

const Home:React.FC<{}> = () => (
    <>
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            {/*
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active"
                aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                aria-label="Slide 3"></button>
            </div>
            */}
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src="/img/background.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block mb-6">
                        <div className="row">
                            <div className="col-md-6 div-l text-left">
                                <h1>VisCa LRS</h1>
                                <p>學習數據分析平台</p>
                                <a href="#home-contact" className="genric-btn white-border circle me-2">Request a demo</a>
                                {/*
                                <a href="javascript:;" className="genric-btn red-border circle">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                        className="bi bi-play-fill" viewBox="0 0 16 16">
                                        <path
                                            d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                                    </svg>
                                    Play video</a>
                                */}
                            </div>
                            <div className="col-md-6 div-r">
                                <img src="/img/table-1.png" className="d-block w-100" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
                {/*
                <div className="carousel-item">
                    <img src="/img/background.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                        <h5>VisCa LRS</h5>
                        <p>合作夥伴能利用 VisCa 平台提供其使用者更多加值服務與專業分析，提升產品多元性與競爭力！</p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src="/img/background.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                        <h5>VisCa LRS</h5>
                        <p>合作夥伴能利用 VisCa 平台提供其使用者更多加值服務與專業分析，提升產品多元性與競爭力！</p>
                    </div>
                </div>
                */}
            </div>
            {/*
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
            */}
        </div>
        <a id="home-about"></a>
        <section className="section main">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="special">
                            <span className="solid">
                                <h2>關於 VisCa</h2>
                                <p>VisCa 將是你最佳的學習數據分析解決方案</p>
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <img src="/img/table-2.png" className="d-block w-100" alt="..." /><br />
                    </div>
                    <div className="col-lg-4">
                        <h3>學習數據流通標準</h3>
                        <p>藉由 XAPI 標準整合學習者在多平台的學習數據</p>
                        <br />
                        <h3>學習數據視覺化分析</h3>
                        <p>以視覺化的互動式圖表呈現學習者的學習狀況、歷程與洞見</p>
                        <br />
                        <h3>學習數據智能 API</h3>
                        <p>提供以人工智慧技術發展之個人化推薦與學習預警服務</p>
                    </div>
                </div>
            </div>
        </section>
        <a id="home-service"></a>
        <section className="section service">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="special">
                            <span className="solid">
                                <h2>服務說明</h2>
                                <p>VisCa 提供的獨家服務</p>
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-4 gx-5">
                        <h3>學習內容歷程分析</h3>
                        <p>針對影片、測驗、課程、XR 與討論區所特別設計的歷程標準與數據分析</p>
                    </div>
                    <div className="col-lg-4 gx-5">
                        <h3>學習履歷區塊鍊</h3>
                        <p>針對完課證明、證照、徽章、競賽成果提供上區塊鍊之服務</p>
                    </div>
                    <div className="col-lg-4 gx-5">
                        <h3>智能推薦</h3>
                        <p>提供基於學習者的履歷、學習者輪廓與基於學習內容之不同技術為基礎之推薦服務</p>
                    </div>
                    <div className="col-lg-4 gx-5">
                        <h3>學習落後預警</h3>
                        <p>提供學習者可能會中途中斷學習(未修課完成)的預測分析</p>
                    </div>
                    <div className="col-lg-4 gx-5">
                        <h3>個人化互動圖表</h3>
                        <p>使用者可自定義績效指標並快速根據學習歷程產生個人化圖表</p>
                    </div>
                    <div className="col-lg-4 gx-5">
                        <h3>數據輸出與分享</h3>
                        <p>互動圖表可匯出原始數據，並可被自由分享或嵌入到其他網站</p>
                    </div>
                </div>
            </div>
        </section>
        {/*
        <section className="section feature">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="special">
                            <span className="solid">
                                <h2>功能特色</h2>
                                <p>VisCa 將是你最佳的全面性解決方案</p>
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <h4>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                className="bi bi-play-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                            </svg>
                            免費且完整的xAPI學習參考文件
                        </h4>
                        <p className="ms-5 mb-4">VisCa 將是你最佳的全面性解決方案</p>
                        <h4>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                className="bi bi-play-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                            </svg>
                            免費且完整的xAPI學習參考文件
                        </h4>
                        <p className="ms-5 mb-4">VisCa 將是你最佳的全面性解決方案</p>
                        <h4>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                className="bi bi-play-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                            </svg>
                            免費且完整的xAPI學習參考文件
                        </h4>
                        <p className="ms-5 mb-4">VisCa 將是你最佳的全面性解決方案</p>
                        <h4>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                className="bi bi-play-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                            </svg>
                            免費且完整的xAPI學習參考文件
                        </h4>
                        <p className="ms-5 mb-4">VisCa 將是你最佳的全面性解決方案</p>
                    </div>
                    <div className="col-lg-7">
                        <img src="img/table-3.png" className="d-block w-100" alt="..." /><br />
                    </div>
                </div>
            </div>
        </section>
        */}
        <a id="home-contact"></a>
        <section className="section form">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="special">
                            <span className="solid">
                                <h2>聯絡我們</h2>
                                <p>關於平台應用與合作探詢，您可以留下資訊，後續我們將有專人與您聯繫。</p>
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="mb-3 me-4">
                            <label htmlFor="FormControlInputName" className="form-label ms-2">姓名</label>
                            <input type="text" className="form-control" id="FormControlInputName" placeholder="" />
                        </div>
                        <div className="mb-3 me-4">
                            <label htmlFor="FormControlInputEmail" className="form-label ms-2">電子信箱</label>
                            <input type="email" className="form-control" id="FormControlInputEmail" placeholder="" />
                        </div>
                        <div className="mb-3 me-4">
                            <label htmlFor="FormControlInputCompany" className="form-label ms-2">公司名稱</label>
                            <input type="text" className="form-control" id="FormControlInputCompany" placeholder="" />
                        </div>
                        <div className="mb-3 me-4">
                            <label htmlFor="FormControlInputTextrea" className="form-label ms-2">您的需求</label>
                            <textarea className="form-control" id="FormControlInputTextrea" placeholder=""></textarea>
                        </div>
                        <div className="text-right me-4"><a href="javascript:;" className="genric-btn red-border circle ms-2">送出</a>
                        </div>
                        <br />
                    </div>
                    <div className="col-lg-4">
                        <div className="information vertical-center">
                            <p className="text-center">關於平台介接有任何問題，或有客製合作之需求您可以聯絡我們<br /><br />
                                <img src="/img/mail-icon.png" width="36" alt="" />viscaservice@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
);
export default Home;