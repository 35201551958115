import React from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component'

interface CTProps {
    columns: IDataTableColumn[];
    data?: any;
    columnName?: string;
    tableStyle?: any;
    loading?:boolean;
    expandableComponent ?:any;
}
interface CTState { }

class BehaviorTable extends React.Component<CTProps, CTState>{

    render() {
        return (
            <DataTable
                className="behavior"
                columns={this.props.columns}
                data={this.props.data}
                defaultSortField={this.props.columnName}
                noHeader={true}
                style={this.props.tableStyle}
                pagination
                progressPending={this.props.loading}
                expandableRows={true}
                expandableRowsComponent={this.props.expandableComponent}
            />
        )
    }
}

export default BehaviorTable;