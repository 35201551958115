import React from 'react';
import { Link } from 'react-router-dom';
import AccountService from '../../services/accountService';

interface AState {
    account?: string;
    password?: string;
    name?: string;
    status?: string;
}

class NewAdmin extends React.Component<{}, AState>{

    private accountService: AccountService;
    constructor(props: any) {
        super(props);
        this.accountService = new AccountService();
        this.state = {
            account: '',
            password: '',
            name: '',
            status: ''
        }
    }



    submitInfo = async () => {
        let data = {
            name: this.state.name,
            account: this.state.account,
            password: this.state.password,
            email: this.state.account,
            role: "admin"
        }
        if (!this.validateEmail(data.account)) {
            this.setState({ status: '帳號格式錯誤' });
            return;
        }
        if (data.account === '' || data.name === '' || data.password === '')
            this.setState({ status: '欄位不得空白' });
        else {
            let msg = await this.accountService.register(data);
            if (msg.success === false)
                this.setState({ status: '帳號已存在!' });
            else {
                this.setState({ status: '' });
                window.location.href = '/lrs/account/admin';
            }
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        this.setState({ [e.target.name]: e.target.value }) //html tag <name="name" value="value">
    }

    validateEmail = (email: any) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render() {
        return (
            <>
                <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><Link to="/lrs/account/admin" style={{ textDecoration: "none", color: "#000000" }}><span>帳戶設定</span></Link>/</li>
                                <li><Link to="/lrs/account/admin" style={{ textDecoration: "none", color: "#000000" }}><span>管理者帳戶</span></Link>/</li>
                                <li><span>新增管理者帳戶</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 pt-1 pb-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="one-user-tab" data-bs-toggle="tab"
                                                data-bs-target="#one-user" type="button" role="tab" aria-controls="one-user"
                                                aria-selected="true">新增使用者</button>
                                        </li>
                                        {/* <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="multi-user-tab" data-bs-toggle="tab"
                                                data-bs-target="#multi-user" type="button" role="tab"
                                                aria-controls="multi-user" aria-selected="false">批次匯入使用者</button>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="one-user" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div className="col-md-12">
                                            <div className="input-group py-3">
                                                <label className="col-lg-1 me-2">帳戶名稱</label>
                                                <input type="text" className="form-control date"
                                                    placeholder="jokic@iii.org.tw" name="account" value={this.state.account} onChange={this.handleChange} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-1 me-2">使用者名稱</label>
                                                <input type="text" className="form-control date" placeholder="金塊阿肥" name="name" value={this.state.name} onChange={this.handleChange} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-1 me-2">帳戶密碼</label>
                                                <input type="password" className="form-control" placeholder="密碼" name="password" value={this.state.password} onChange={this.handleChange} required></input>
                                            </div>
                                            <div className="input-group py-3">
                                                <label style={{ color: "red" }}>{this.state.status}</label>
                                            </div>
                                            <div className="text-center pb-3">
                                                <Link to="/lrs/account/admin"
                                                    className="genric-btn circle red-border">取消</Link>&nbsp;&nbsp;<button
                                                        className="genric-btn circle white-border" onClick={this.submitInfo}>建立</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default NewAdmin;