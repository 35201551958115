import React from 'react';
import QuizChart from './quizChart';
import QuizService from '../../services/quizService';
import Loading from 'react-loading';

interface FProps { }
interface FState {
    score: any[];
    correctAry: any[];
    timeAry: any[];
}

class QuizEmbed extends React.Component<FProps, FState>{
    private quizService: QuizService;
    private type: string;
    private date: string;
    private quizId: string;
    private org: string;
    private account: string;
    private role: string;

    constructor(props: any) {
        super(props);
        this.quizService = new QuizService();
        this.org = window.location.pathname.split("/")[5];
        this.type = window.location.pathname.split("/")[3];
        this.date = window.location.pathname.split("/")[4];
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.role = urlParams.get("role") || "";
        this.account = urlParams.get("acct") || "";
        this.quizId = decodeURIComponent(urlParams.get("id") || "");
        this.state = {
            score: [-1],
            timeAry: [-1],
            correctAry: [-1]
        }
        //this.sdate = moment(this.date.slice(0, 8)).format('YYYY-MM-DD');
        //this.edate = moment(this.date.slice(8, 16)).format('YYYY-MM-DD');
    }

    componentDidMount() {
        switch (this.type) {
            case "score":
                this.getSingleQuiz(this.date, this.quizId, this.org, this.account, this.role);
                break;
            case "correct":
                this.getSingleQuizByQuesion(this.date, this.quizId, this.org, this.account, this.role);
                break;
            case "time":
                this.getSingleQuizByQuesion(this.date, this.quizId, this.org, this.account, this.role);
                break;
        }
    }

    getSingleQuiz = async (sdate: string, quizId: string, org: string, filter: string, role: string) => {
        let quiz = await this.quizService.getSingleQuiz(sdate, quizId, org, filter, role);
        let score = await this.processScore(quiz.score);
        this.setState({ score: score });
    }

    getSingleQuizByQuesion = async (sdate: string, quizId: string, org: string, filter: string, role: string, refresh: string = "no") => {
        let quesionList = await this.quizService.getSingleQuizByQuesion(sdate, quizId, org, filter, role);
        let [correctAry, timeAry] = await this.dataToChart(quesionList);
        this.setState({ correctAry: correctAry, timeAry: timeAry });
    }

    dataToChart = (ary: any[]): any => {
        let correctAry = [], timeAry = [];
        for (let i = 0; i < ary.length; i++) {
            let quesion = {
                id: i + 1,
                qid: ary[i].quizQuesionId,
                correctPerscent: parseInt(ary[i].correctPerscent.split("%")[0]),
                avgAnswerTime: ary[i].avgAnswerTime
            }
            correctAry.push(quesion);
            timeAry.push(quesion);
        }
        return [correctAry, timeAry];
    }


    processScore = (ary: any) => {
        console.log(ary)
        let scoreRangeAry = [];
        for (let i = 0; i < 10; i++) {
            let scoreRange = {
                range: `${i * 10}-${(i + 1) * 10}`,
                value: 0
            }
            scoreRangeAry.push(scoreRange)
        }
        for (let i = 0; i < ary.length; i++) {
            if (ary[i] === 100) {
                scoreRangeAry[9].value++;
            }
            else {
                scoreRangeAry[Math.floor(ary[i] / 10)].value++;
            }
        }
        return scoreRangeAry;
    }


    renderLoading() {
        return <Loading type={"spin"} color={"#ffffff"} height={'20%'} width={'20%'}></Loading>
    }

    renderType = () => {
        switch (this.type) {
            case "score":
                return (
                    <QuizChart
                        labelx={"分數區間"}
                        labely={"人數"}
                        x={"range"}
                        y={"value"}
                        data={this.state.score}
                        datamFunc={({ datum }: { datum: any }) => `${datum.value}`}
                    />
                );
            case "correct":
                return (
                    <QuizChart
                        domain={{ y: [0, 100] }}
                        labelx={"題號"}
                        labely={"答對率(%)"}
                        x={"id"}
                        y={"correctPerscent"}
                        data={this.state.correctAry}
                        //datum={({ datum }: { datum: any }) => `${datum.correctPerscent}%`}
                        datamFunc={({ datum }: { datum: any }) => `${datum.correctPerscent}%`}

                    />
                )
            case "time":
                return (
                    <QuizChart
                        domain={{ y: [0, 300] }}
                        labelx={"題號"}
                        labely={"平均答題時間(sec)"}
                        x={"id"}
                        y={"avgAnswerTime"}
                        data={this.state.timeAry}
                        datamFunc={({ datum }: { datum: any }) => `${datum.avgAnswerTime}`}

                    />
                )
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 pt-3">
                    {this.renderType()}
                </div>
            </div>
        );
    }
}

export default QuizEmbed;