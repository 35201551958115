import React from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component'

interface BTProps {
    columns: IDataTableColumn[];
    data?: any;
    columnName?: string;
    tableStyle?: any;
    loading?:boolean;
}
interface BTProps { }

class BlockchainTable extends React.Component<BTProps, BTProps>{

    render() {
        return (
            <DataTable
                columns={this.props.columns}
                data={this.props.data}
                defaultSortField={this.props.columnName}
                noHeader={true}
                style={this.props.tableStyle}
                pagination
                progressPending={this.props.loading}
            />
        )
    }
}

export default BlockchainTable;