export class VideoAPI {
    basePath: string = "/api/video"
    getTotalWatchPeople: string = "/watch/people";
    getVideos: string = "/list";
    getWatchTimesAndHours: string = "/watch/times/hours";
    getVideosByDay: string = "/list/day";
    getSingleVideo: string = "/info";
    getSingleVideoByDay: string = "/day";
    getSingleVideoFinish: string = "/finish";
    getSingleVideoEvent: string = "/event";
    videoParams: string = "videoId";
}

export class QuizAPI {
    basePath: string = "/api/quiz"
    getQuizs: string = "/list";
    getSingleQuiz: string = "/info";
    getSingleQuizByUser: string = "/user";
    getSingleQuizByQuesion: string = "/quesion";
    getQuizQuesionByUser: string = "/user/quesion";
}

export class CourseAPI {
    basePath: string = "/api/course";
    getCoursefinish: string = "/list";
    getAllCoursefinish: string = "/alist";
    getFullCourses: string = "/flist";
    getCourseHoursByDay: string = "/list/hours";
    getCourseTimesByDay: string = "/list/times";
    getSingleCourse: string = "/info";
    getSingleCourseHours: string = "/hour";
    getSingleCourseAttend: string = "/times";
    getSingleCourseAttendByUser: string = "/times/user";
    getSingleCourseAttendByUnit: string = "/unit";
    getSingleCourseEvent: string = "/event";
    getCourseWarning: string = "/warning"
    getCourseWeekWarning: string = "/week/warning"
}

export class EventAPI {
    basePath: string = "/api/event";
    getEventList: string = "/list";
    getEventTextAgent: string = "/text/agent";
    getEventTextVerb: string = "/text/verb";
    getEventTextAct: string = "/text/act";
    getEventCount: string = "/total/events";
    getEventUser: string = "/total/users";
    getEventByDate: string = "/list/day";
    getEventFinishCourseByDate: string = "/couse/fin/day";
    getEventByMostUser: string = "/most/user";
    getEventByMostVerb: string = "/most/verb";
    getEventByMostActivities: string = "/most/act";
    getEventByMostActType: string = "/most/act/type";
    getEventCourseByMost: string = "/most/course";
    getEventByUser: string = "/user";
    getEventByDailyVerb: string = "/verb";
    getFacet: string = '/facet';
}

export class ActorAPI {
    basePath: string = "/api/actor";
    getEventCountByActor: string = "/total/event";
    getLastEventByActor: string = "/last/event";
    getEventByActorAct: string = "/total/event/act";
    getCoursefinishByActor: string = "/finish/course";
    getEventHourByActor: string = "/event/hour";
    getEventByActor: string = "/event";
    getEventByActorDailyVerb: string = "/event/verb";
    getEventByActorDailyAct: string = "/event/act";
    getEventListByActor: string = "/event/list";
    getQuizListByActor: string = "/quiz/list";
}

export class ActivityAPI {
    basePath: string = "/api/activity";
    getTotalAct: string = "/total/act";
    getActList: string = "/act/list";
    getLastAct: string = "/last/act";
    getActInfo: string = "/act/info";
    getEventByDailyAct: string = "/act/daily";
    getActByVerb: string = "/act/verb";
    getActBySucess: string = "/act/sucess";
    getRecentAct: string = "/recent/act";
}

export class DiscussionAPI {
    basePath: string = "/api/discussion";
    getDisscussionInfo: string = "/info";
    getDiscussionByDaily: string = "/list";
    getDiscussionByMost: string = "/most";
    getDiscussionByCourse: string = "/course";
    getDiscussionByUser: string = "/user";
    getDiscussListByCourse: string = "/list/course";
    getCourseInDiscussion: string = "/course/text";
    getWordCloud: string = "/wordcloud";
}

export class AccountAPI {
    basePath: string = "/api/account";
    getStudentAccount: string = "/student";
    getTeacherAccount: string = "/teacher";
    getAdmin: string = "/admin";
    getApp: string = "/app";
    getOpencert: string = "/opencert";
    logout: string = "/logout";
    login: string = "/login";
    isLogin: string = "/islogin";
    register: string = "/register"
    deleteAdmin: string = "/admin";
}

export class PortalAPI {
    basePath: string = "/api/portal";
    getUserList: string = "/userlist";
    register: string = "/register";
    deleteUser: string = "/user";
}

export class BCAPI {
    basePath: string = "/api/blockchain";
    getData: string = "/data";
    login: string = "/login";
    addData: string = "/data";
    getOpenCert: string = "/cert";
    getOpenCerts: string = "/certs";
    getOpenCertInfo: string = "/certinfo";
    getTransaction: string = "/transaction";
}

export class AnalyticsSettingAPI {
    basePath: string = "/api/analytics/setting";
    getCourse: string = '/course';
    getCourses: string = '/courses';
    deleteCourse: string = '/courses';
    registerCourse: string = '/courses';
    registerCourseContent: string = '/course';
}