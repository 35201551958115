import React from 'react';
import { VictoryLine, VictoryChart, VictoryAxis, DataGetterPropType, VictoryLabel, VictoryTooltip, VictoryVoronoiContainer, VictoryTheme } from 'victory';
import Loading from 'react-loading';

interface FCProps {
    chartType?: string;
    tickValues?: any[];
    tickFormat?: any;
    x?: DataGetterPropType;
    y?: DataGetterPropType;
    labelx?: string;
    labely?: string;
    data?: any[];
    datum?: any;
    datamFunc?: any;
    domain?: any;
    angle?: number;
    name?: any[];
}

interface FCState { }

class BlockchainChart extends React.Component<FCProps, FCState>{
    renderLine() {
        return (
            <VictoryChart
                height={200}
                width={400}
                theme={VictoryTheme.material}
                containerComponent={
                    <VictoryVoronoiContainer
                        labels={this.props.datamFunc}
                        labelComponent={
                            <VictoryTooltip
                                cornerRadius={10}
                                flyoutWidth={70}
                                flyoutStyle={{
                                    stroke: "none",
                                    fill: "black"
                                }}
                                style={{ fill: "white", fontSize: 6 }}
                            />
                        }
                    />
                }
                domain={this.props.domain}
            >
                <linearGradient id="lineGradient"
                    x1="0%"
                    x2="100%"
                    y1="0%"
                    y2="100%">
                    <stop offset="0%" stopColor="#00FFCC" />
                    <stop offset="50%" stopColor="#77FFCC" />
                    <stop offset="100%" stopColor="#99FF99" />
                </linearGradient>
                <VictoryLabel x={20} y={24}
                    text={this.props.labely}
                    style={{ fontSize: "8px" }}
                />
                <VictoryLabel x={400} y={280}
                    text={this.props.labelx}
                    style={{ fontSize: "8px" }}
                />
                <VictoryAxis
                    tickLabelComponent={<VictoryLabel angle={43} />}
                    style={{
                        tickLabels: {
                            fontSize: 6
                        }
                    }}
                    tickValues={this.props.tickValues}
                    tickFormat={this.props.tickFormat}
                />
                <VictoryAxis
                    dependentAxis
                    style={{
                        tickLabels: {
                            fontSize: 8
                        }
                    }}
                />
                <VictoryLine
                    data={this.props.data}
                    x={this.props.x}
                    y={this.props.y}
                    style={{
                        data: {
                            stroke: 'url(#lineGradient)',
                            strokeWidth: 3.0
                        }
                    }}
                />
            </VictoryChart>
        )
    }

    renderLoading() {
        return <Loading type={"cylon"} color={"black"} height={'30%'} width={'30%'}></Loading>
    }

    chartType = () => {
        if (this.props.data !== undefined && this.props.data[0] === -1) {
            return (
                <>
                    {this.renderLoading()}
                </>
            )
        }
        else if (this.props.chartType === "line")
            return (
                <>
                    {this.renderLine()}
                </>
            )
    }

    render() {
        return (
            <>
                {this.chartType()}
            </>
        )
    }
}

export default BlockchainChart;