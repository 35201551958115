import moment from 'moment';
import React from 'react';
import ActorService from '../../services/actorService';
import ActorTable from './actorTable';
import ActorChart from './actorChart';
import Loading from 'react-loading';
import { Link } from 'react-router-dom';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import LinkBtn from '../opionBtn/exportLink';
import { LoginContext } from '../../loginContext';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import StudentInfo from '../../utils/StudentInfo';

interface OProps { }
interface OState {
    eventList: any[];
    totalEvent: number;
    totalEventAct: number;
    totalFinishCourse: number;
    lastEvent: any;
    totalHour: string;
    hourList: any[];
    hourCSV: any[];
    avgHourList: any[];
    actorEventList: any[];
    actorCSV: any[];
    eventVerb: any[];
    verbCSV: any[];
    eventAct: any[];
    actCSV: any[];
    recentEvent: any[];
    quizList: any[];
    sdate: string;
    edate: string;
}

const columns = [
    {
        name: "",
        selector: "statement",
        width: "100%",
        cell: (row: any) => {
            return (
                <p><strong>{row.statement.actor.name}</strong> {row.statement.verb.display['zh-TW']} {(row.statement.object.definition?.name !== undefined) ? row.statement.object.definition.name['zh-TW'] : row.statement.object.id} <span className="text-primary">{(row.statement.result?.score !== undefined) ? `成績${row.statement.result.score.raw}` : ""}{(row.statement.result?.duration !== undefined) ? ` 花費了${moment().startOf('day').second(moment.duration(row.statement.result.duration).asSeconds()).format('HH:mm:ss')}` : ""} </span> {moment(row.statement.timestamp).format('YYYY-MM-DD HH:mm:ss')}</p>
            )
        }

    }
];

class ActorContent extends React.Component<OProps, OState>{
    private actorService: ActorService
    private actorId: string
    private sdate: string;
    private edate: string;
    private org: string;
    private date: string;
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        this.actorService = new ActorService();
        this.org = "";
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.actorId = urlParams.get("id") || ""
        this.state = {
            eventList: [-1],
            actorEventList: [-1],
            totalEvent: -1,
            lastEvent: {},
            totalEventAct: -1,
            totalFinishCourse: -1,
            totalHour: "-1",
            hourList: [-1],
            hourCSV: [],
            actorCSV: [],
            avgHourList: [-1],
            eventVerb: [-1],
            eventAct: [-1],
            recentEvent: [-1],
            quizList: [-1],
            verbCSV: [],
            actCSV: [],
            sdate: moment(urlParams.get("sdate"), 'YYYY-MM-DD', true).isValid() ? urlParams.get("sdate") || "" : moment().subtract(7, 'day').format('YYYY-MM-DD'),
            edate: moment(urlParams.get("edate"), 'YYYY-MM-DD', true).isValid() ? urlParams.get("edate") || "" : moment().format('YYYY-MM-DD'),
        }
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
        this.date = this.state.sdate.replace(/-/g, '') + this.state.edate.replace(/-/g, '');
    }

    componentDidMount() {
        this.org = this.context.org;
        this.date = this.state.sdate.replace(/-/g, '') + this.state.edate.replace(/-/g, '')
        this.getEventCountByActor(this.date, window.atob(this.actorId), this.org)
        this.getLastEventByActor(window.atob(this.actorId), this.org)
        this.getEventByActorAct(this.date, window.atob(this.actorId), this.org)
        this.getCoursefinishByActor(this.date, window.atob(this.actorId), this.org)
        this.getEventHourByActor(this.date, window.atob(this.actorId), this.org)
        this.getEventByActor(this.date, window.atob(this.actorId), this.org)
        this.getEventByActorDailyVerb(this.date, window.atob(this.actorId), this.org)
        this.getEventByActorDailyAct(this.date, window.atob(this.actorId), this.org)
        this.getEventListByActor(this.date, window.atob(this.actorId), this.org)
        this.getQuizListByActor(this.date, window.atob(this.actorId), this.org)
    }

    resetState = () => {
        this.setState({
            eventList: [-1],
            actorEventList: [-1],
            totalEvent: -1,
            totalEventAct: -1,
            totalFinishCourse: -1,
            totalHour: "-1",
            hourList: [-1],
            avgHourList: [-1],
            eventVerb: [-1],
            eventAct: [-1],
            recentEvent: [-1],
            quizList: [-1],
        })
    }

    getEventCountByActor = async (date: string, actorId: string, org: string): Promise<any> => {
        let totalEvent = await this.actorService.getEventCountByActor(date, actorId, org);
        this.setState({ totalEvent: totalEvent.number })
    }

    getLastEventByActor = async (actorId: string, org: string): Promise<any> => {
        let lastEvent = await this.actorService.getLastEventByActor(actorId, org);
        this.setState({ lastEvent: lastEvent })
    }

    getEventByActorAct = async (date: string, actorId: string, org: string): Promise<any> => {
        let totalEventAct = await this.actorService.getEventByActorAct(date, actorId, org);
        this.setState({ totalEventAct: totalEventAct.number })
    }

    getCoursefinishByActor = async (date: string, actorId: string, org: string): Promise<any> => {
        let totalFinishCourse = await this.actorService.getCoursefinishByActor(date, actorId, org);
        this.setState({ totalFinishCourse: totalFinishCourse.number })
    }

    getEventHourByActor = async (date: string, actorId: string, org: string): Promise<any> => {
        let totalHour = await this.actorService.getEventHourByActor(date, actorId, org);
        let actorHourList = await this.settingDailyData(totalHour.actorCourseList, "hour");
        let avgHourList = await this.settingDailyData(totalHour.courseList, "avgHour");
        let hourCSV = actorHourList.concat(avgHourList);
        let totalHours = `${Math.floor(totalHour.totalHours / 3600)}H`
        this.setState({ totalHour: totalHours, avgHourList: avgHourList, hourList: actorHourList, hourCSV: hourCSV })
    }

    getEventByActor = async (date: string, actorId: string, org: string): Promise<any> => {
        let event = await this.actorService.getEventByActor(date, actorId, org);
        let actorEventList = await this.settingDailyData(event.actorEventList, "event");
        let eventList = await this.settingDailyData(event.eventList, "avgEvent");
        let actorCSV = actorEventList.concat(eventList);
        this.setState({ eventList: eventList, actorEventList: actorEventList, actorCSV: actorCSV });
    }

    getEventByActorDailyVerb = async (date: string, actorId: string, org: string): Promise<any> => {
        let eventlist = await this.actorService.getEventByActorDailyVerb(date, actorId, org);
        let eventVerb = await this.settingBarData(eventlist);
        let verbCSV = await this.processData(eventVerb);
        this.setState({ eventVerb: eventVerb, verbCSV: verbCSV });
    }

    getEventByActorDailyAct = async (date: string, actorId: string, org: string): Promise<any> => {
        let eventlist = await this.actorService.getEventByActorDailyAct(date, actorId, org);
        let eventAct = await this.settingBarData(eventlist);
        let actCSV = await this.processData(eventAct);
        this.setState({ eventAct: eventAct, actCSV: actCSV });
    }

    getEventListByActor = async (date: string, actorId: string, org: string): Promise<any> => {
        let eventlist = await this.actorService.getEventListByActor(date, actorId, org);
        this.setState({ recentEvent: eventlist })
    }

    getQuizListByActor = async (date: string, actorId: string, org: string): Promise<any> => {
        let quizList = await this.actorService.getQuizListByActor(date, actorId, org);
        this.setState({ quizList: quizList })
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    refresh = (id: number) => {
        let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
        switch (id) {
            case 0: {
                this.setState({ actorEventList: [-1], eventList: [-1] })
                this.getEventByActor(date, window.atob(this.actorId), this.org)
                break;
            }
            case 1: {
                this.setState({ hourList: [-1], avgHourList: [-1] })
                this.getEventHourByActor(date, window.atob(this.actorId), this.org)
                break;
            }
            case 2: {
                this.setState({ eventVerb: [-1] })
                this.getEventByActorDailyVerb(date, window.atob(this.actorId), this.org)
                break;
            }
            case 3: {
                this.setState({ eventAct: [-1] })
                this.getEventByActorDailyAct(date, window.atob(this.actorId), this.org)
                break;
            }
            case 4: {
                this.setState({ quizList: [-1] })
                this.getQuizListByActor(date, window.atob(this.actorId), this.org)
                break;
            }
        }

    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 30) {
            alert("日期區間請在一個月內")
        } else {
            this.resetState();
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
            this.getEventCountByActor(date, window.atob(this.actorId), this.org)
            this.getLastEventByActor(window.atob(this.actorId), this.org)
            this.getEventByActorAct(date, window.atob(this.actorId), this.org)
            this.getCoursefinishByActor(date, window.atob(this.actorId), this.org)
            this.getEventHourByActor(date, window.atob(this.actorId), this.org)
            this.getEventByActor(date, window.atob(this.actorId), this.org)
            this.getEventByActorDailyVerb(date, window.atob(this.actorId), this.org)
            this.getEventByActorDailyAct(date, window.atob(this.actorId), this.org)
            this.getEventListByActor(date, window.atob(this.actorId), this.org)
            this.getQuizListByActor(date, window.atob(this.actorId), this.org)
        }
    }

    processData = async (data: any[]) => {
        let ary: any = [];
        for (let i = 0; i < data.length; i++) {
            ary = ary.concat(data[i])
        }
        return ary;
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingBarData = (ary: any[]) => {
        let dataAry: any[] = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let j = 0; j < ary.length; j++) {
            let temp: any[] = []
            for (let i = 0; i < dayOfMonth; i++) {
                let hasValue = false
                let index = 0
                for (let k = 0; k < ary[j].events.length; k++) {
                    if (`${ary[j].events[k].year}/${("0" + ary[j].events[k].month).slice(-2)}/${("0" + ary[j].events[k].day).slice(-2)}` === dateAry[i]) {
                        hasValue = true;
                        index = k;
                        break;
                    }
                }
                let data = {
                    x: dateAry[i],
                    y: 0,
                    name: ""
                }
                if (hasValue) {
                    data.y = ary[j].events[index].event
                    data.name = ary[j].events[index].name
                    temp.push(data)
                }
                else {
                    temp.push(data)
                }
            }
            dataAry.push(temp);
        }
        return dataAry;
    }

    settingDailyData = (ary: any, type: string) => {
        let dataAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false
            let index = 0
            let data = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }
            if (hasValue) {
                if (type === "event") {
                    data.y = Math.floor(ary[index].event)
                    dataAry.push(data);
                } else if (type === "avgEvent") {
                    data.y = Math.floor(ary[index].avgEvent)
                    dataAry.push(data);
                } else if (type === "hour") {
                    data.y = Math.floor(ary[index].costTime / 3600)
                    dataAry.push(data);
                } else if (type === "avgHour") {
                    data.y = Math.floor(ary[index].avgCostTime / 3600)
                    dataAry.push(data);
                }
            }
            else {
                dataAry.push(data);
            }
        }
        return dataAry;
    }

    renderLoading() {
        return <Loading type={"spin"} color={"#FFFFFF"} height={'20%'} width={'20%'}></Loading>
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    render() {
        return (
            <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <ul id="title-spacer" className="breadcrumbs">
                            <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >學習行為分析</span></Link>/</li>
                            <li><Link to="/lrs/actor" style={{ textDecoration: "none", color: "#000000" }}><span>學習者紀錄</span></Link>/</li>
                            <li><span>{this.state.lastEvent.name}</span></li>
                        </ul>
                    </div>
                    <div className="d-md-flex justify-content-md-end">
                        <div className="col-auto ms-2 py-1">
                            <div className="input-group">
                                <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} />
                            </div>
                        </div>
                        <div className="col-auto ms-2 py-2 m-none lh-lg">~</div>
                        <div className="col-auto ms-2 py-1">
                            <div className="input-group">
                                <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} />
                            </div>
                        </div>
                        <div className="col-auto ms-2 py-1">
                            <button className="btn btn-secondary" onClick={this.submitDateRange}>確定</button>
                        </div>
                    </div>
                    <div className="col-md-8 py-1">
                        <div className="h-100 p-3 bg-white rounded-3">
                            <h5>{this.state.lastEvent.name}</h5><br />
                            <h5>{this.state.lastEvent.email}</h5><br />
                            <h5>{StudentInfo(this.state.lastEvent.email)}</h5><br />
                            <h5>最近一次學習時間：{this.state.lastEvent.date}</h5><br />
                        </div >
                    </div >
                    <div className="col-md-4 py-1">
                        &nbsp;
                    </div>
                    <div className="col-md-2 py-1">
                        <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                            <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3">學習紀錄</Popover.Header>
                                            <Popover.Body>
                                            在篩選條件下，學生的學習行為紀錄數量
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <h2>{(this.state.totalEvent === -1) ? this.renderLoading() : this.state.totalEvent}</h2>
                            <h5>學習紀錄</h5>
                        </div>
                    </div>
                    <div className="col-md-2 py-1">
                        <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                            <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3">完成課程</Popover.Header>
                                            <Popover.Body>
                                            在篩選條件下，學生完成課程的數量
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <h2>{(this.state.totalFinishCourse === -1) ? this.renderLoading() : this.state.totalFinishCourse}</h2>
                            <h5>完成課程</h5>
                        </div>
                    </div>
                    <div className="col-md-2 py-1">
                        <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                            <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3">學習活動</Popover.Header>
                                            <Popover.Body>
                                            在篩選條件下，學生所進行學習的活動的數量
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <h2>{(this.state.totalEventAct === -1) ? this.renderLoading() : this.state.totalEventAct}</h2>
                            <h5>學習活動</h5>
                        </div>
                    </div>
                    <div className="col-md-2 py-1">
                        <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                            <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3">學習時數</Popover.Header>
                                            <Popover.Body>
                                            在篩選條件下，學生觀看網頁連結節點與觀看影片花費的時間的總和
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <h2>{(this.state.totalHour === "-1") ? this.renderLoading() : this.state.totalHour}</h2>
                            <h5>學習時數</h5>
                        </div>
                    </div>
                </div >
                <div className="row">
                    <div className="col-md-6 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>學習活躍度</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">學習活躍度</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，學生的學習行為紀錄數量
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={`/embed/actor/event/${this.date}/${this.org}?acct=${this.actorId}`} /></li>                                           
                                            <li><button className="dropdown-item" onClick={() => this.refresh(0)}>重新整理</button></li>
                                            <li><CSVBtn data={(this.state.actorCSV)} /></li>
                                            <li><ImgBtn id={0} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <ActorChart
                                tickFormat={this.tickFormat}
                                labelx={"日期"}
                                labely={"活躍度"}
                                datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} records`}
                                data={this.state.actorEventList}
                                data2={this.state.eventList}
                                chartType={"doubleLine"}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>學習時數</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">學習時數</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，學生自己觀看網頁連結節點與觀看影片花費的時間的總和，同時比較全體學生的平均
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={`/embed/actor/hour/${this.date}/${this.org}?acct=${this.actorId}`} /></li>
                                            <li><button className="dropdown-item" onClick={() => this.refresh(1)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.hourCSV} /></li>
                                            <li><ImgBtn id={1} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <ActorChart
                                tickFormat={this.tickFormat}
                                labelx={"日期"}
                                labely={"時數"}
                                datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} hour`}
                                data={this.state.hourList}
                                data2={this.state.avgHourList}
                                chartType={"doubleLine"} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>近期學習動作</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">近期學習動作</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，學生最近進行學習行為的動作
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={`/embed/actor/verb/${this.date}/${this.org}?acct=${this.actorId}`} /></li>
                                            <li><button className="dropdown-item" onClick={() => this.refresh(2)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.verbCSV} /></li>
                                            <li><ImgBtn id={2} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <ActorChart
                                tickFormat={this.tickFormat}
                                dataStack={this.state.eventVerb}
                                datamFunc={({ datum }: { datum: any }) => (datum.y !== 0) ? `${datum.x} \n${datum.name} ${datum.y} times` : ""}
                                chartType={"stack"}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>近期學習活動</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">近期學習活動</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，學生最近進行學習行為的活動
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={`/embed/actor/act/${this.date}/${this.org}?acct=${this.actorId}`} /></li>
                                            <li><button className="dropdown-item" onClick={() => this.refresh(3)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.actCSV} /></li>
                                            <li><ImgBtn id={3} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <ActorChart
                                tickFormat={this.tickFormat}
                                dataStack={this.state.eventAct}
                                datamFunc={({ datum }: { datum: any }) => (datum.y !== 0) ? `${datum.x} \n${datum.name} ${datum.y} times` : ""}
                                chartType={"stack"}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>成績變化</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">成績變化</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，學生的每一次的測驗成績
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={`/embed/actor/score/${this.date}/${this.org}?acct=${this.actorId}`} /></li>
                                            <li><button className="dropdown-item" onClick={() => this.refresh(4)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.quizList} /></li>
                                            <li><ImgBtn id={4} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <ActorChart
                                domain={{ y: [0, 100] }}
                                data={this.state.quizList}
                                x={"name"}
                                y={"score"}
                                datamFunc={({ datum }: { datum: any }) => (datum.year !== undefined) ? `${datum.year}/${("0" + datum.month).slice(-2)}/${("0" + datum.day).slice(-2)} \n${datum.score}` : ""}
                                chartType={"line"}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>學習預警</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            功能
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>近期學習紀錄</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">近期學習紀錄</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，學生的學習行為紀錄
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            {this.state.recentEvent[0] === -1 ? < ActorTable columns={columns} loading={true} /> : <ActorTable columns={columns} data={this.state.recentEvent} />}
                        </div>
                    </div>
                </div>
            </main >
        );
    }

}

export default ActorContent;