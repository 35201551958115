import moment from 'moment';
import React from 'react';
import OverviewChart from './overviewChart';
import EventService from '../../services/eventService';

interface OProps { }
interface OState {
    eventAry: any[];
    userAry: any[];
    courseAry: any[];
    verbLegend: any[];
    actLegend: any[];
    actTypeLegend: any[];
    courseLegend: any[];
    eventMostUserList: any[];
    eventMostVerb: any[];
    eventMostAct: any[];
    eventMostActType: any[];
    eventMostcourse: any[];
}

class OverviewEmbed extends React.Component<OProps, OState>{
    private eventService: EventService;
    private org: string;
    private type: string;
    private date: string;
    private sdate: string;
    private edate: string;
    private role: string ;
    private account: string;

    constructor(props: any) {
        super(props);
        this.eventService = new EventService();
        this.org = window.location.pathname.split("/")[5];
        this.type = window.location.pathname.split("/")[3];
        this.date = window.location.pathname.split("/")[4];
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.role = urlParams.get("role") || "";
        this.account = urlParams.get("acct") || "";
        this.state = {
            eventAry: [-1],
            userAry: [-1],
            courseAry: [-1],
            eventMostUserList: [-1],
            eventMostVerb: [-1],
            verbLegend: [-1],
            eventMostAct: [-1],
            actLegend: [-1],
            eventMostActType: [-1],
            actTypeLegend: [-1],
            eventMostcourse: [-1],
            courseLegend: [-1],
        }
        this.sdate = moment(this.date.slice(0, 8)).format('YYYY-MM-DD');
        this.edate = moment(this.date.slice(8, 16)).format('YYYY-MM-DD');
    }

    componentDidMount() {
        switch (this.type) {
            case "event":
                this.getEventByDate(this.date, this.org, this.account, this.role);
                break;
            case "users":
                this.getEventByDate(this.date, this.org, this.account, this.role);
                break;
            case "verb":
                this.getEventByMostVerb(this.date, this.org, this.account, this.role);
                break;
            case "act":
                this.getEventByMostActivities(this.date, this.org, this.account, this.role);
                break;
            case "type":
                this.getEventByMostActType(this.date, this.org, this.account, this.role);
                break;
            case "course":
                this.getEventCourseByMost(this.date, this.org, this.account, this.role);
                break;
            case "user":
                this.getEventByMostUser(this.date, this.org, this.account, this.role);
                break;
            case "finish":
                this.getEventFinishCourseByDate(this.date, this.org, this.account, this.role);
                break;
        }
    }

    getEventByDate = async (date: string, org: string, filter: string, role: string): Promise<any> => {
        let eventDateList = await this.eventService.getEventByDate(date, org, filter, role);
        let [eventAry, userAry]: any = await this.settingDailyData(eventDateList, "event");
        this.setState({ eventAry: eventAry, userAry: userAry });
    }

    getEventFinishCourseByDate = async (date: string, org: string, filter: string, role: string): Promise<any> => {
        let eventFinCourseDate = await this.eventService.getEventFinishCourseByDate(date, org, filter, role);
        let courseAry = await this.settingDailyData(eventFinCourseDate, "course");
        this.setState({ courseAry: courseAry });
    }

    getEventByMostUser = async (date: string, org: string, filter: string, role: string): Promise<any> => {
        let eventMostUserList = await this.eventService.getEventByMostUser(date, org, filter, role);
        this.setState({ eventMostUserList: eventMostUserList });
    }

    getEventByMostVerb = async (date: string, org: string, filter: string, role: string): Promise<any> => {
        let eventMostVerb = await this.eventService.getEventByMostVerb(date, org, filter, role);
        let verbLegend = await this.processLegend(eventMostVerb);
        this.setState({ eventMostVerb: eventMostVerb, verbLegend: verbLegend });
    }

    getEventByMostActivities = async (date: string, org: string, filter: string, role: string): Promise<any> => {
        let eventMostAct = await this.eventService.getEventByMostActivities(date, org, filter, role);
        let actLegend = await this.processLegend(eventMostAct);
        this.setState({ eventMostAct: eventMostAct, actLegend: actLegend });
    }

    getEventByMostActType = async (date: string, org: string, filter: string, role: string): Promise<any> => {
        let eventMostActType = await this.eventService.getEventByMostActType(date, org, filter, role);
        let actTypeLegend = await this.processLegend(eventMostActType);
        this.setState({ eventMostActType: eventMostActType, actTypeLegend: actTypeLegend });
    }

    getEventCourseByMost = async (date: string, org: string, filter: string, role: string): Promise<any> => {
        let eventMostcourse = await this.eventService.getEventCourseByMost(date, org, filter, role);
        let courseLegend = await this.processLegend(eventMostcourse, "course");
        this.setState({ eventMostcourse: eventMostcourse, courseLegend: courseLegend });
    }

    processLegend = (ary: any[], type: string = "123") => {
        let nameAry = [];
        for (let i = 0; i < ary.length; i++) {
            let data = {}
            if (type === "course") {
                data = {
                    name: `${Math.ceil(ary[i].totalAttend / ary.map((x: any) => x.totalAttend).reduce((a: any, c: any) => a + c) * 100)}% ${(ary[i].name.length > 7) ? ary[i].name.slice(0, 7) : ary[i].name}${(ary[i].name.length > 7) ? "..." : ""}`
                }
            } else {
                data = {
                    name: `${Math.ceil(ary[i].event / ary.map((x: any) => x.event).reduce((a: any, c: any) => a + c) * 100)}% ${(ary[i].name.length > 7) ? ary[i].name.slice(0, 7) : ary[i].name}${(ary[i].name.length > 7) ? "..." : ""}`
                }
            }
            nameAry.push(data)
        }
        return nameAry;
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any[], type: string) => {
        let eventAry = [], userAry = [], courseAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false
            let index = 0
            let eventData = {
                x: dateAry[i],
                y: 0
            }
            let userData = {
                x: dateAry[i],
                y: 0
            }
            let courseData = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }
            if (hasValue) {
                if (type === "event") {
                    eventData.y = Math.floor(ary[index].event);
                    userData.y = Math.floor(ary[index].users)
                    eventAry.push(eventData);
                    userAry.push(userData);
                } else if (type === "course") {
                    courseData.y = Math.floor(ary[index].totalFinish);
                    courseAry.push(courseData);
                }

            }
            else {
                eventAry.push(eventData);
                userAry.push(userData);
                courseAry.push(courseData);
            }
        }
        if (type === "event")
            return [eventAry, userAry];
        else
            return courseAry;
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    renderType = () => {
        switch (this.type) {
            case "event":
                return (
                    <OverviewChart
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"筆數"}
                        data={this.state.eventAry}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} records`}
                        chartType={"line"}
                    />
                )
            case "users":
                return (
                    <OverviewChart
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"人數"}
                        data={this.state.userAry}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} users`}
                        chartType={"line"}
                    />
                )
            case "verb":
                return (
                    <OverviewChart
                        data={this.state.eventMostVerb}
                        name={this.state.verbLegend}
                        x={"name"}
                        y={"event"}
                        chartType={"circle"}
                        datum={({ datum }: { datum: any }) => `${datum.name}\n${Math.ceil(datum.event / this.state.eventMostVerb.map(x => x.event).reduce((a, c) => a + c) * 100)}%`}
                    />
                )
            case "act":
                return (
                    <OverviewChart
                        data={this.state.eventMostAct}
                        name={this.state.actLegend}
                        x={"name"}
                        y={"event"}
                        chartType={"circle"}
                        datum={({ datum }: { datum: any }) => `${(datum.name).slice(0, 9)}...\n${Math.ceil(datum.event / this.state.eventMostAct.map(x => x.event).reduce((a, c) => a + c) * 100)}%`}
                    />
                )
            case "type":
                return (
                    <OverviewChart
                        data={this.state.eventMostActType}
                        name={this.state.actTypeLegend}
                        x={"name"}
                        y={"event"}
                        datum={({ datum }: { datum: any }) => `${(datum.name).slice(0, 9)}\n${Math.ceil(datum.event / this.state.eventMostActType.map(x => x.event).reduce((a, c) => a + c) * 100)}%`}
                        chartType={"circle"}
                    />
                )
            case "course":
                return (
                    <OverviewChart
                        data={this.state.eventMostcourse}
                        name={this.state.courseLegend}
                        x={"name"}
                        y={"totalAttend"}
                        chartType={"circle"}
                        datum={({ datum }: { datum: any }) => `${(datum.name).slice(0, 9)}...\n${Math.ceil(datum.totalAttend / this.state.eventMostcourse.map(x => x.totalAttend).reduce((a, c) => a + c) * 100)}%`}
                    />
                )
            case "user":
                return (
                    <OverviewChart
                        data={this.state.eventMostUserList}
                        x={"name"}
                        y={"event"}
                        angle={45}
                        datamFunc={({ datum }: { datum: any }) => `${datum.event}次`}
                        domain={{ x: [0, this.state.eventMostUserList.length + 1], y: [0, Math.max(...this.state.eventMostUserList.map(p => p.event)) + 1000] }}
                        chartType={"bar"}
                    />
                )
            case "finish":
                return (
                    <OverviewChart
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"人數"}
                        data={this.state.courseAry}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} users`}
                        chartType={"line"}
                    />
                )
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 pt-3">
                    {this.renderType()}
                </div>
            </div>
        );
    }

}

export default OverviewEmbed;