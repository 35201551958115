import HttpHandler from './APIServices';
import { PortalAPI } from './API';

class PortalService {
    private api: PortalAPI;

    constructor() {
        this.api = new PortalAPI()
    }

    getUserList = async (role:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getUserList}/${role}`;
        let credentials: RequestCredentials = "include"
        let option = {
            method: 'GET',
            credentials: credentials
        }
        let data = await HttpHandler(url, option);
        return data;
    }

    register = async (user: any): Promise<any> => {
        let url = `${this.api.basePath}${this.api.register}`;
        let option = {
            method: 'POST',
            body: JSON.stringify(user),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }
        let data = await HttpHandler(url, option);
        return data;
    }

    deleteUser = async (user: any): Promise<any> => {
        let url = `${this.api.basePath}${this.api.deleteUser}`;
        let option = {
            method: 'DELETE',
            body: JSON.stringify(user),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }
        let data = await HttpHandler(url, option);
        return data;
    }
}

export default PortalService;