import React from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component'

interface VTProps {
    columns: IDataTableColumn[];
    data?: any
    columnName?: string;
    loading?:boolean;
}
interface VTState { }

class VideoTable extends React.Component<VTProps, VTState>{

    render() {
        return (
            <DataTable
                columns={this.props.columns}
                data={this.props.data}
                defaultSortField={this.props.columnName}
                noHeader={true}
                pagination
                progressPending={this.props.loading}
            />
        )
    }
}

export default VideoTable;