import React from 'react';
import { renderRoutes } from 'react-router-config';
import HomeNavbar from './components/homeNavBar';
import Footer from './components/homeFooter';
import './App.css';
import AccountService from './services/accountService';
import { Redirect } from "react-router-dom";

interface AState {
  loginstatus: any
}

class App extends React.Component<{}, AState> {
  private route: any;
  private accountService: AccountService;
  constructor(props: any) {
    super(props);
    this.accountService = new AccountService();
    this.route = props.route;
    this.state = {
      loginstatus: {
        status: undefined,
        uname: ""
      }
    }
  }

  componentDidMount() {
    this.isLogin();
  }

  isLogin = async () => {
    let data: any = await this.accountService.isLogin();
    this.setState({
      loginstatus: {
        status: data.status,
        uname: data.uname
      }
    });
  }

  redirect = () => {
    if (this.state.loginstatus.status && this.state.loginstatus.status !== undefined) {
      return <Redirect to={"/lrs/overview"} />
    }else if(this.state.loginstatus === undefined){
      return <div></div>
    }
     else {
      return (
        <>
          <HomeNavbar />
          {renderRoutes(this.route.routes)}
          <Footer />
        </>
      )
    }
  }

  render() {
    return (
      <>
        {this.redirect()}
      </>
    );
  }
}

export default App;
