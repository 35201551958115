import React from 'react';
import Loading from 'react-loading';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface CGProps {
    totalRecord: number;
    totalStudent: number;
    totalFinishEvent: number;
}
interface CGState { }

class OverviewGrid extends React.Component<CGProps, CGState>{
    renderLoading() {
        return <Loading type={"spin"} color={"#ffffff"} height={'20%'} width={'20%'}></Loading>
    }

    render() {
        return (
            <>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">學習紀錄</Popover.Header>
                                        <Popover.Body>
                                        在篩選條件下，所有學習紀錄的數量 (<a href="https://leaverij.gitbook.io/visca/tech/recipes" target="_blank" rel="noreferrer">所有學習行為說明頁面</a>)
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.totalRecord === -1) ? this.renderLoading() : this.props.totalRecord}</h2>
                        <h5>學習紀錄</h5>
                    </div>
                </div>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">學生</Popover.Header>
                                        <Popover.Body>
                                        在篩選條件下，有任何學習行為的不重複的學生數量
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.totalStudent === -1) ? this.renderLoading() : this.props.totalStudent}</h2>
                        <h5>學生</h5>
                    </div>
                </div>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">學習活動</Popover.Header>
                                        <Popover.Body>
                                        在篩選條件下，所有不重複的學生所進行的不重複的學習活動數量
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.totalFinishEvent === -1) ? this.renderLoading() : this.props.totalFinishEvent}</h2>
                        <h5>學習活動</h5>
                    </div>
                </div>
            </>
        )
    }
}

export default OverviewGrid;