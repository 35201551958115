import HttpHandler from './APIServices';
import { AnalyticsSettingAPI } from './API';

class AnalyticsSettingService {
    private api: AnalyticsSettingAPI;

    constructor() {
        this.api = new AnalyticsSettingAPI()
    }

    componentDidMount() {
        this.getCourses();
    }

    getCourse = async (courseId: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getCourse}/${courseId}`;
        let credentials: RequestCredentials = "include"
        let option = {
            method: 'GET',
            credentials: credentials
        }
        let data = await HttpHandler(url, option);
        return data;
    }

    getCourses = async (): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getCourses}`;
        let credentials: RequestCredentials = "include"
        let option = {
            method: 'GET',
            credentials: credentials
        }
        let data = await HttpHandler(url, option);
        return data;
    }

    deleteCourse = async (user: any): Promise<any> => {
        let url = `${this.api.basePath}${this.api.deleteCourse}`;
        let option = {
            method: 'DELETE',
            body: JSON.stringify(user),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }
        let data = await HttpHandler(url, option);
        return data;
    }

    registerCourse = async (course: any): Promise<any> => {
        let url = `${this.api.basePath}${this.api.registerCourse}`;
        let option = {
            method: 'PUT',
            body: JSON.stringify(course),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }
        let data = await HttpHandler(url, option);
        return data;
    }

    addCourseContent = async (course: any): Promise<any> => {
        let url = `${this.api.basePath}${this.api.registerCourseContent}`;
        let option = {
            method: 'POST',
            body: JSON.stringify(course),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }
        let data = await HttpHandler(url, option);
        return data;
    }

}

export default AnalyticsSettingService;