import React from 'react';
import { NavLink } from 'react-router-dom';
import { LoginContext } from '../loginContext';

interface MProps { }
interface MState {

}

class Menu extends React.Component<MProps, MState>{
    static contextType = LoginContext;

    render() {

        return (
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-dark sidebar collapse" >
                <div className="position-sticky pt-3">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink to="/lrs/overview" className="btn btn-toggle nav-link rounded" exact={true}>
                                <span data-feather="file"></span>
                                總覽
                            </NavLink >
                        </li>
                        <li className="nav-item">
                            <NavLink to="#" className="btn btn-toggle nav-link rounded collapsed" data-bs-toggle="collapse" data-bs-target="#home-collapse-1" aria-expanded="true" isActive={(location) => {
                                let path = location?.url.split('/')[2]
                                if (path === "behavior" || path === "actor" || path === "verb" || path === "activity")
                                    return true
                                else
                                    return false
                            }}>
                                <span data-feather="file"></span>
                                學習行為分析
                            </NavLink>
                            <div className={`collapse ${(window.location.pathname.split('/')[2] === "behavior" || window.location.pathname.split('/')[2] === "actor" || window.location.pathname.split('/')[2] === "verb" || window.location.pathname.split('/')[2] === "activity") ? "show" : ""}`} id="home-collapse-1">
                                <ul className="btn-toggle-nav fw-normal pb-1 small">
                                    <li><NavLink to="/lrs/behavior" className="nav-link">行為紀錄</NavLink></li>
                                    <li><NavLink to="/lrs/actor" className="nav-link">學習者紀錄</NavLink></li>
                                    <li><NavLink to="/lrs/verb" className="nav-link">學習動作紀錄</NavLink></li>
                                    <li><NavLink to="/lrs/activity" className="nav-link">學習活動紀錄</NavLink></li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <NavLink to="#" className="btn btn-toggle nav-link rounded collapsed" data-bs-toggle="collapse" data-bs-target="#home-collapse-2" aria-expanded="true" isActive={(location) => {
                                let path = location?.url.split('/')[2]
                                if (path === "course" || path === "video" || path === "quiz")
                                    return true
                                else
                                    return false
                            }}>
                                <span data-feather="file"></span>
                                學習內容分析
                            </NavLink>
                            <div className={`collapse ${(window.location.pathname.split('/')[2] === "course" || window.location.pathname.split('/')[2] === "video" || window.location.pathname.split('/')[2] === "quiz") ? "show" : ""}`} id="home-collapse-2">
                                <ul className="btn-toggle-nav fw-normal pb-1 small">
                                    <li><NavLink to="/lrs/course" className="nav-link">課程學習分析</NavLink></li>
                                    <li><NavLink to="/lrs/video" className="nav-link">影片學習分析</NavLink></li>
                                    <li><NavLink to="/lrs/quiz" className="nav-link">測驗學習分析</NavLink></li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <NavLink to="#" className="btn btn-toggle nav-link rounded collapsed" data-bs-toggle="collapse" data-bs-target="#home-collapse-3" aria-expanded="true" isActive={(location) => {
                                if (location?.url === "/lrs/discussion")
                                    return true
                                else
                                    return false
                            }}>
                                <span data-feather="file"></span>
                                社群互動分析
                            </NavLink>
                            <div className="collapse" id="home-collapse-3">
                                <ul className="btn-toggle-nav fw-normal pb-1 small">
                                    <li><NavLink to="/lrs/discussion" className="nav-link">論壇討論分析</NavLink></li>
                                </ul>
                            </div>
                        </li>
                        {/* <li className="nav-item">
                            <button className="btn btn-toggle nav-link rounded collapsed" data-bs-toggle="collapse" data-bs-target="#home-collapse-4" aria-expanded="true">
                                <span data-feather="file"></span>
                                智慧推薦分析
                            </button>
                            <div className="collapse" id="home-collapse-4">
                                <ul className="btn-toggle-nav fw-normal pb-1 small">
                                    <li><a href="#" className="nav-link">課程推薦</a></li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <NavLink to="#" className="btn btn-toggle nav-link rounded collapsed" data-bs-toggle="collapse" data-bs-target="#home-collapse-5" aria-expanded="true" isActive={(location) => {
                                if (location?.url === "/lrs/blockchain")
                                    return true
                                else
                                    return false
                            }}>
                                <span data-feather="file"></span>
                                學習履歷
                            </NavLink>
                            <div className="collapse" id="home-collapse-5">
                                <ul className="btn-toggle-nav fw-normal pb-1 small">
                                    <li><NavLink to="/lrs/blockchain" className="nav-link">證書</NavLink></li>
                                </ul>
                            </div>
                        </li>
                        */}
                        {(this.context.role === "admin" || this.context.role === "owner") ?
                            <li className="nav-item">
                                <NavLink to="#" className="btn btn-toggle nav-link rounded collapsed" data-bs-toggle="collapse" data-bs-target="#home-collapse-6" aria-expanded="true" isActive={(location) => {
                                    let path = location?.url.split('/')[2]
                                    if (path === "account")
                                        return true
                                    else
                                        return false
                                }}>
                                    <span data-feather="file"></span>
                                    帳戶設定
                                </NavLink>
                                <div className={`collapse ${(window.location.pathname.split('/')[2] === "account") ? "show" : ""}`} id="home-collapse-6">
                                    <ul className="btn-toggle-nav fw-normal pb-1 small">
                                        <li><NavLink to="/lrs/account/admin" className="nav-link">管理者帳戶</NavLink></li>
                                        <li><NavLink to="/lrs/account/teacher" className="nav-link">教師帳戶</NavLink></li>
                                        <li><NavLink to="/lrs/account/student" className="nav-link">學生帳戶</NavLink></li>
                                    </ul>
                                </div>
                            </li>
                            :
                            <></>
                        }
                        {(this.context.role === "admin" || this.context.role === "owner") ?
                            <li className="nav-item">
                                <NavLink to="#" className="btn btn-toggle nav-link rounded collapsed" data-bs-toggle="collapse" data-bs-target="#home-collapse-7" aria-expanded="true" isActive={(location) => {
                                    let path = location?.url.split('/')[2]
                                    if (path === "analytics")
                                        return true
                                    else
                                        return false
                                }}>
                                    <span data-feather="file"></span>
                                    分析設定
                                </NavLink>
                                <div className={`collapse ${(window.location.pathname.split('/')[2] === "analytics") ? "show" : ""}`} id="home-collapse-7">
                                    <ul className="btn-toggle-nav fw-normal pb-1 small">
                                        <li><NavLink to="/lrs/analytics/setting/courses" className="nav-link">課程設定</NavLink></li>
                                    </ul>
                                </div>
                            </li>
                            :
                            <></>
                        }
                    </ul>
                </div>
            </nav>
        )
    }
}

export default Menu;