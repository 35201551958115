import moment from 'moment';
import React from 'react';
import VerbChart from './verbChart';
import EventService from '../../services/eventService';

interface VProps { }
interface VState {
    eventMostVerb: any[];
    eventDailyVerb: any[];
    verbName: any[];
}

class VerbEmbed extends React.Component<VProps, VState>{
    private eventService: EventService;
    private sdate: string;
    private edate: string;
    private type: string;
    private date: string;
    private org: string;
    private account: string;
    private role: string;

    constructor(props: any) {
        super(props);
        this.eventService = new EventService();
        this.org = window.location.pathname.split("/")[5];
        this.type = window.location.pathname.split("/")[3];
        this.date = window.location.pathname.split("/")[4];
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.role = urlParams.get("role") || "";
        this.account = urlParams.get("acct") || "";
        this.state = {
            eventMostVerb: [-1],
            eventDailyVerb: [-1],
            verbName: [],
        }
        this.sdate = moment(this.date.slice(0, 8)).format('YYYY-MM-DD');
        this.edate = moment(this.date.slice(8, 16)).format('YYYY-MM-DD');
    }

    componentDidMount() {
        switch (this.type) {
            case "most":
                this.getEventByMostVerb(this.date, this.org, this.account, this.role);
                break;
            case "event":
                this.getEventByDailyVerb(this.date, this.org, this.account, this.role);
                break;
        }
    }

    getEventByMostVerb = async (date: string, org: string, filter: string, role: string): Promise<any> => {
        let eventMostVerb = await this.eventService.getEventByMostVerb(date, org, filter, role);
        this.setState({ eventMostVerb: eventMostVerb });
    }

    getEventByDailyVerb = async (date: string, org: string, filter: string, role: string): Promise<any> => {
        let eventList = await this.eventService.getEventByDailyVerb(date, org, filter, role);
        let eventDailyVerb = await this.settingDailyData(eventList);
        let verbName = await this.setLegendName(eventList)
        this.setState({ eventDailyVerb: eventDailyVerb, verbName: verbName });
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    setLegendName = (ary: any[]) => {
        let nameAry = [];
        for (let j = 0; j < ary.length; j++) {
            let data = {
                name: ary[j].events[0].name
            }
            nameAry.push(data)
        }
        return nameAry;
    }

    settingDailyData = (ary: any[]) => {
        let dataAry: any[] = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let j = 0; j < ary.length; j++) {
            let temp: any[] = []
            for (let i = 0; i < dayOfMonth; i++) {
                let hasValue = false
                let index = 0
                for (let k = 0; k < ary[j].events.length; k++) {
                    if (`${ary[j].events[k].year}/${("0" + ary[j].events[k].month).slice(-2)}/${("0" + ary[j].events[k].day).slice(-2)}` === dateAry[i]) {
                        hasValue = true;
                        index = k;
                        break;
                    }
                }
                let data = {
                    x: dateAry[i],
                    y: 0,
                    name: ""
                }
                if (hasValue) {
                    data.y = ary[j].events[index].event
                    data.name = ary[j].events[index].name
                    temp.push(data)
                }
                else {
                    temp.push(data)
                }
            }
            dataAry.push(temp);
        }
        return dataAry;
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }
    
    renderType = () => {
        switch (this.type) {
            case "most":
                return (
                    <VerbChart
                        data={this.state.eventMostVerb}
                        x={"name"}
                        y={"event"}
                        chartType={"circle"}
                        datum={({ datum }: { datum: any }) => `${datum.name}\n${Math.ceil(datum.event / this.state.eventMostVerb.map(x => x.event).reduce((a, c) => a + c) * 100)}%`}
                    />
                );
            case "event":
                return (
                    <VerbChart
                        tickFormat={this.tickFormat}
                        nameData={this.state.verbName}
                        dataStack={this.state.eventDailyVerb}
                        datamFunc={({ datum }: { datum: any }) => (datum.y !== 0) ? `${datum.x} \n${datum.name} ${datum.y} times` : ""}
                        chartType={"line"}
                    />
                )
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 pt-3">
                    {this.renderType()}
                </div>
            </div>
        );
    }

}

export default VerbEmbed;