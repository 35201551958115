import React from 'react';
import memoize from 'memoize-one';
import moment from 'moment';
import QuizTable from './quizTable';
import QuizCompare from './quizCompare';
import QuizService from '../../services/quizService';
import CourseService from '../../services/courseService';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../loginContext';
import Select from 'react-select';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';

const columns = memoize((handleAction) => [
    {
        name: "測驗名稱",
        selector: "name",
        sortable: true,
        width: "20%",
        wrap: true
    },
    {
        name: "課程名稱",
        selector: "courseName",
        sortable: true,
        width: "16%",
        wrap: true
    },
    {
        name: "測驗人數",
        selector: "people",
        sortable: true,
        width: "5%",
        center: true
    },
    {
        name: "通過人數",
        selector: "passPeople",
        sortable: true,
        width: "5%",
        center: true
    },
    {
        name: "通過率",
        //selector: "passPercent",
        sortable: true,
        width: "10%",
        center: true,
        cell: (row: any) => {
            const passPercent = row.passPercent;
            const passPercentNum = parseInt(passPercent.split('%')[0]);
            if(passPercentNum < 80){
                return <span style={{color: '#ff0000'}}>{passPercent}</span>
            }else{
                return passPercent;
            }
        }
    },
    {
        name: "平均分數",
        selector: "avgScore",
        sortable: true,
        width: "10%",
        center: true,
        cell: (row: any) => {
            const avgScore = row.avgScore;
            const avgScoreNum = parseFloat(avgScore);
            if(avgScoreNum < 60){
                return <span style={{color: '#ff0000'}}>{avgScore}</span>
            }else{
                return avgScore;
            }
        }
    },
    {
        name: "標準差",
        selector: "sigma",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "高標",
        selector: "high",
        sortable: true,
        width: "5%",
        center: true
    },
    {
        name: "低標",
        selector: "low",
        sortable: true,
        width: "5%",
        center: true
    },
    {
        name: "詳細內容",
        cell: (row: any) => <button className="genric-btn white-border" onClick={() => { handleAction(row) }}>檢視</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true,
        compact: true,
        button: true,
        width: "10%"
    }
]);

interface QLState {
    courseList: any[];
    courseId: string;
    quizList: any[];
    selectRow: any[];
    colume: any[];
    sdate: string;
    edate: string;
    scoreAry: any[];
    passAry: any[];
    toggledClearRows: boolean;
    sDateDisabled: boolean;
    eDateDisabled: boolean;
    periodDate: string;
}

class QuizList extends React.Component<{}, QLState>{
    private courseService: CourseService;
    private quizService: QuizService;
    private sdate: string;
    private edate: string;
    private org: string;
    private account: string;
    private role: string;
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        this.courseService = new CourseService();
        this.quizService = new QuizService();
        this.org = "";
        this.account = "";
        this.role = "";
        this.state = {
            courseList: [],
            courseId: '',
            selectRow: [],
            colume: [],
            passAry: [],
            quizList: [-1],
            scoreAry: [],
            toggledClearRows: false,
            sdate: '2021-08-01',//moment().subtract(14, 'day').format('YYYY-MM-DD'),
            edate: '2022-01-31',//moment().format('YYYY-MM-DD')
            sDateDisabled: true,
            eDateDisabled: true,
            periodDate: '2021-08-01/2022-01-31'
        }
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
        this.getFullCourses(this.org);
        this.getQuizs(date, this.org, this.account, this.role, this.state.courseId);
    }

    resetState = () => {
        this.setState({
            quizList: [-1]
        })
    }

    getFullCourses = async (org: string) => {
        let fullCourses: any = await this.courseService.getFullCourses(org);
        let courseSelectValues = fullCourses.fullCourseList.map((course: any) => {
            return {
                label: course.name,
                value: course.courseId
            }
        });
        this.setState({ courseList: courseSelectValues });
    }

    getQuizs = async (sdate: string, org: string, filter: string, role: string, courseId: string) => {
        let quizList = await this.quizService.getQuizs(sdate, org, filter, role, courseId);
        this.setState({ quizList: quizList });
    }

    viewContent = (data: any) => {
        let date = moment(data.date).format('YYYYMMDD');
        let id = encodeURIComponent(data.id);
        window.location.href = `/lrs/quiz/content?quizId=${id}&qdate=${date}`
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 200) {
            alert("日期區間請在六個月內")
        } else {
            this.resetState();
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
            this.getQuizs(date, this.org, this.account, this.role, this.state.courseId);
        }
    }

    handleChange = (state: any) => {
        if (state.selectedRows.length > 10) {
            alert("最多只能選10個")
        } else {
            let [column, dataAry] = this.processData(state.selectedRows);
            let [scoreAry, passAry] = this.processChart(state.selectedRows)
            this.setState({ selectRow: dataAry, colume: column, scoreAry: scoreAry, passAry: passAry })
        }
    };

    processData = (list: any[]) => {
        if (list.length < 2)
            return [[], []];
        let column: any[] = [{ name: "", selector: "id", sortable: false }]
        for (let i = 0; i < list.length; i++) {
            let col = {
                name: list[i].name,
                selector: `${i}`,
                sortable: false,
                center: true
            }
            column.push(col);
        }
        let dataAry: any[] = []
        for (let i = 0; i < 7; i++) {
            let data: any = {}
            for (let j = 0; j < list.length; j++) {
                switch (i) {
                    case 0:
                        data.id = "測驗人數"
                        data[j] = list[j].people
                        break;
                    case 1:
                        data.id = "通過人數"
                        data[j] = list[j].passPeople
                        break;
                    case 2:
                        data.id = "通過率"
                        data[j] = list[j].passPercent
                        break;
                    case 3:
                        data.id = "平均分數"
                        data[j] = list[j].avgScore
                        break;
                    case 4:
                        data.id = "標準差"
                        data[j] = list[j].sigma
                        break;
                    case 5:
                        data.id = "高標"
                        data[j] = list[j].high
                        break;
                    case 6:
                        data.id = "低標"
                        data[j] = list[j].low
                        break;
                }
            }
            dataAry.push(data);
        }
        return [column, dataAry];
    }

    processChart = (list: any[]) => {
        let scoreAry: any[] = []
        let passAry: any[] = []
        for (let i = 0; i < 4; i++) {
            let score: any[] = [];
            let pass: any[] = [];
            for (let j = 0; j < list.length; j++) {
                let data: any = {
                    x: "",
                    y: 0
                }
                let passData = {
                    y: 0,
                    percent: "",
                    people: 0,
                    x: ""
                }
                switch (i) {
                    case 0:
                        data.x = (list[j].name.length > 6) ? list[j].name.substring(0, 6) : list[j].name
                        data.y = list[j].high
                        passData.x = (list[j].name.length > 6) ? list[j].name.substring(0, 6) : list[j].name;
                        passData.y = parseInt(list[j].passPercent.split('%')[0])
                        passData.people = list[j].passPeople;
                        passData.percent = list[j].passPercent;
                        pass.push(passData)
                        score.push(data)
                        break;
                    case 1:
                        data.x = (list[j].name.length > 6) ? list[j].name.substring(0, 6) : list[j].name
                        data.y = list[j].low
                        passData.x = (list[j].name.length > 6) ? list[j].name.substring(0, 6) : list[j].name;
                        passData.y = 100 - parseInt(list[j].passPercent.split('%')[0])
                        passData.people = list[j].people - list[j].passPeople;
                        passData.percent = `${100 - parseInt(list[j].passPercent.split('%')[0])}%`;
                        pass.push(passData)
                        score.push(data)
                        break;
                    case 2:
                        data.x = (list[j].name.length > 6) ? list[j].name.substring(0, 6) : list[j].name
                        data.y = list[j].sigma
                        score.push(data)
                        break;
                    case 3:
                        data.x = (list[j].name.length > 6) ? list[j].name.substring(0, 6) : list[j].name
                        data.y = list[j].avgScore
                        score.push(data)
                        break;
                }
            }
            if (i < 2)
                passAry.push(pass);
            scoreAry.push(score)
        }
        return [scoreAry, passAry];
    }

    handleCourseChange = (e: any) => {
        if (e === null)
            this.setState({ courseId: '' })
        else
            this.setState({ courseId: e.value })
    }

    periodDateHandler = (e: any) => {
        const targetPeriod = e.target.value;
        if(targetPeriod){
            const periodStartDate = targetPeriod.split('/')[0];
            const periodEndDate = targetPeriod.split('/')[1];
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: true,
                eDateDisabled: true,
                sdate: periodStartDate,
                edate: periodEndDate
            });
        }else{
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: false,
                eDateDisabled: false
            });
        }
    }

    render() {
        return (
            <>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >學習內容分析</span></Link>/</li>
                                <li><span>測驗學習分析</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 py-1">
                            <h5>課程</h5>
                            <Select options={this.state.courseList} onChange={this.handleCourseChange} isClearable={true} />
                        </div>
                        <div className="col-md-3 py-1">
                            <h5>學期</h5>
                            <Form.Select value={this.state.periodDate} onChange={this.periodDateHandler}>
                                <option value="">自訂</option>
                                <option value="2022-02-01/2022-07-31">110-第二學期</option>
                                <option value="2021-08-01/2022-01-31">110-第一學期</option>
                                <option value="2021-02-01/2021-07-31">109-第二學期</option>
                                <option value="2020-08-01/2021-01-31">109-第一學期</option>
                            </Form.Select>
                        </div>
                        <div className="col-md-5 py-1">
                            <h5>時間</h5>
                            <div className="row">
                                <div className="col-auto">
                                    <div className="input-group">
                                        <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} disabled={this.state.sDateDisabled} />
                                    </div>
                                </div>
                                <div className="col-auto py-2 m-none lh-lg">~</div>
                                <div className="col-auto">
                                    <div className="input-group">
                                        <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} disabled={this.state.eDateDisabled} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 py-1">
                            <h5>動作</h5>
                            <button className="btn btn-secondary" onClick={this.submitDateRange}>篩選</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-3 pb-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h3>測驗學習分析</h3>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                            <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                <Popover>
                                                    <Popover.Header as="h3">測驗學習分析</Popover.Header>
                                                    <Popover.Body>
                                                    
                                                    </Popover.Body>
                                                </Popover>
                                            )}>
                                                <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </div>
                                        {/* <div className="input-group">
                                            <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                功能
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li><a className="dropdown-item" href="#">Share</a></li>
                                                <li><a className="dropdown-item" href="#">Export</a></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                                {this.state.quizList[0] === -1 ? <QuizTable columns={columns(this.viewContent)} loading={true} /> : <QuizTable columns={columns(this.viewContent)} data={this.state.quizList} columnName={"name"} selected={true} handleChange={this.handleChange} />}

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {(this.state.selectRow.length >= 3) ? <QuizCompare column={this.state.colume} quizTable={this.state.selectRow} quizList={this.state.scoreAry} quizPass={this.state.passAry} />
                            : <></>}
                    </div>
                </main>
            </>
        )
    }
}

export default QuizList;