import React from 'react';
import NavBar from './navBar';
import Menu from './menu';
import Footer from './footer';
import { renderRoutes } from 'react-router-config';
import { Redirect } from "react-router-dom";
import AccountService from '../services/accountService';
import { LoginContext } from '../loginContext';

interface AState {
    loginstatus: any
}

class Dashboard extends React.Component<{}, AState>{
    private route: any;
    private accountService: AccountService;
    constructor(props: any) {
        super(props);
        this.accountService = new AccountService();
        this.route = props.route;
        this.state = {
            loginstatus: {
                status: undefined,
                uname: "",
            }
        }
    }

    componentDidMount() {
        this.isLogin();
    }

    isLogin = async () => {
        let data = await this.accountService.isLogin();
        this.setState({
            loginstatus: {
                status: data.status,
                uname: data.uname,
                role: data.role,
                account: data.account,
                org: data.org
            }
        });
    }

    redirect = () => {
        if (!this.state.loginstatus.status && this.state.loginstatus.status !== undefined) {
            return <Redirect to={"/login"} />
        } else if (this.state.loginstatus.status === undefined) {
            return <div></div>
        } else {
            return (
                <>
                    <LoginContext.Provider value={this.state.loginstatus}>
                        <NavBar />
                        <div className="container-fluid dashboard" style={{ backgroundColor: "#EFF0EA" }}>
                            <div className="row">
                                <Menu />
                                {renderRoutes(this.route.routes)}
                                <Footer />
                            </div>
                        </div>
                    </LoginContext.Provider>
                </>
            )
        }
    }

    render() {
        return (
            <>
                {this.redirect()}
            </>
        )
    }
}

export default Dashboard;