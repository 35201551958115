import moment from 'moment';
import React from 'react';
import OverviewChart from './overviewChart';
import OverviewGrid from './overviewGrid';
import EventService from '../../services/eventService';
import CourseService from '../../services/courseService';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import LinkBtn from '../opionBtn/exportLink';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../loginContext';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import Select from 'react-select';

interface OProps { }
interface OState {
    courseList: any[];
    courseId: string;
    role: string;
    totalEvent: any;
    eventUser: any;
    eventAry: any[];
    userAry: any[];
    courseAry: any[];
    verbLegend: any[];
    actLegend: any[];
    actTypeLegend: any[];
    courseLegend: any[];
    eventMostUserList: any[];
    eventMostVerb: any[];
    eventMostAct: any[];
    eventMostActType: any[];
    eventMostcourse: any[];
    sdate: string;
    edate: string;
    sDateDisabled: boolean;
    eDateDisabled: boolean;
    periodDate: string;
}

class Overview extends React.Component<OProps, OState>{
    private courseService: CourseService;
    private eventService: EventService;
    private sdate: string;
    private edate: string;
    private date: string;
    private org:string
    private account:string
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        this.courseService = new CourseService();
        this.eventService = new EventService();
        this.state = {
            courseList: [-1],
            courseId: '',
            role: "",
            totalEvent: {
                number: -1
            },
            eventUser: {
                agents: -1,
                activities: -1
            },
            eventAry: [-1],
            userAry: [-1],
            courseAry: [-1],
            eventMostUserList: [-1],
            eventMostVerb: [-1],
            verbLegend: [-1],
            eventMostAct: [-1],
            actLegend: [-1],
            eventMostActType: [-1],
            actTypeLegend: [-1],
            eventMostcourse: [-1],
            courseLegend: [-1],
            sdate: '2021-08-01',//moment().subtract(7, 'day').format('YYYY-MM-DD'),
            edate: '2022-01-31',//moment().format('YYYY-MM-DD')
            sDateDisabled: true,
            eDateDisabled: true,
            periodDate: '2021-08-01/2022-01-31'
        }
        this.org = "";
        this.account = "";
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
        this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
    }

    componentDidMount() {
        this.setState({ role: this.context.role })
        this.org = this.context.org;
        this.account = window.btoa(this.context.account);
        this.getFullCourses(this.org);
        this.getEventByMostUserAndVerb(this.date, this.org, this.account, this.context.role, this.state.courseId);
        this.getEventCount(this.date, this.org, this.account, this.context.role, this.state.courseId);
        this.getEventUser(this.date, this.org, this.account, this.context.role, this.state.courseId);
        this.getEventByDate(this.date, this.org, this.account, this.context.role, this.state.courseId);
        this.getEventFinishCourseByDate(this.date, this.org, this.account, this.context.role, this.state.courseId);
        this.getEventByMostUser(this.date, this.org, this.account, this.context.role, this.state.courseId);
        this.getEventByMostVerb(this.date, this.org, this.account, this.context.role, this.state.courseId);
        this.getEventByMostActivities(this.date, this.org, this.account, this.context.role, this.state.courseId);
        this.getEventByMostActType(this.date, this.org, this.account, this.context.role, this.state.courseId);
        this.getEventCourseByMost(this.date, this.org, this.account, this.context.role, this.state.courseId);
    }

    resetState = () => {
        this.setState({
            totalEvent: {
                number: -1
            },
            eventUser: {
                agents: -1,
                activities: -1
            },
            eventAry: [-1],
            userAry: [-1],
            courseAry: [-1],
            eventMostUserList: [-1],
            eventMostVerb: [-1],
            eventMostAct: [-1],
            eventMostActType: [-1],
            eventMostcourse: [-1],
        })
    }

    getEventCount = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let totalEvent = await this.eventService.getEventCount(date, org, filter, role, courseId);
        this.setState({ totalEvent: totalEvent });
    }

    getEventUser = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let eventUser = await this.eventService.getEventUser(date, org, filter, role, courseId);
        this.setState({ eventUser: eventUser });
    }

    getEventByDate = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        console.log('role=' + role);
        let eventDateList = await this.eventService.getEventByDate(date, org, filter, role, courseId);
        let [eventAry, userAry]: any = await this.settingDailyData(eventDateList, "event");
        this.setState({ eventAry: eventAry, userAry: userAry });
    }

    getEventFinishCourseByDate = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let eventFinCourseDate = await this.eventService.getEventFinishCourseByDate(date, org, filter, role, courseId);
        let courseAry = await this.settingDailyData(eventFinCourseDate, "course");
        this.setState({ courseAry: courseAry });
    }

    getEventByMostUserAndVerb = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let eventList = await this.eventService.getEventByFacet(date, org, filter, role, courseId);
        //this.setState({ eventMostUserList: eventMostUserList });
    }

    getEventByMostUser = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let eventMostUserList = await this.eventService.getEventByMostUser(date, org, filter, role, courseId);
        for(let i=0; i<eventMostUserList.length; i++){
            if(!eventMostUserList[i].name){
                eventMostUserList[i].name = '匿名';
            }
        }
        this.setState({ eventMostUserList: eventMostUserList });
    }

    getEventByMostVerb = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let eventMostVerb = await this.eventService.getEventByMostVerb(date, org, filter, role, courseId);
        //let verbLegend = await this.processLegend(eventMostVerb);
        let verbLegend = [];
        for(let i=0; i<eventMostVerb.length; i++){
            verbLegend.push({name: (eventMostVerb[i].name) ? eventMostVerb[i].name: 'undefined'});
        }
        this.setState({ eventMostVerb: eventMostVerb, verbLegend: verbLegend });
    }

    getEventByMostActivities = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let eventMostAct = await this.eventService.getEventByMostActivities(date, org, filter, role, courseId);
        let actLegend = [];
        for(let i=0; i<eventMostAct.length; i++){
            if(!eventMostAct[i].name){
                eventMostAct[i].name = 'undefined';
            }
            actLegend.push({name: (eventMostAct[i].name) ? eventMostAct[i].name: 'undefined'});
        }
        //let actLegend = await this.processLegend(eventMostAct);
        this.setState({ eventMostAct: eventMostAct, actLegend: actLegend });
    }

    getEventByMostActType = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let eventMostActType = await this.eventService.getEventByMostActType(date, org, filter, role, courseId);
        //let actTypeLegend = await this.processLegend(eventMostActType);
        let actTypeLegend = [];
        for(let i=0; i<eventMostActType.length; i++){
            if(!eventMostActType[i].name){
                eventMostActType[i].name = 'undefined';
            }
            let typeName = '';
            switch(eventMostActType[i].name){
                case 'https://w3id.org/xapi/acrossx/activities/video':
                    typeName = '影片';
                    break;
                case 'http://adlnet.gov/expapi/activities/cmi.interaction':
                    typeName = '題目';
                    break;
                case 'http://adlnet.gov/expapi/activities/link':
                    typeName = '網頁連結';
                    break;
                case 'http://adlnet.gov/expapi/activities/assessment':
                    typeName = '測驗';
                    break;
                case 'http://adlnet.gov/expapi/activities/course':
                    typeName = '課程';
                    break;
                case 'http://activitystrea.ms/schema/1.0/file':
                    typeName = '檔案';
                    break;
                case 'http://id.tincanapi.com/activitytype/discussion':
                    typeName = '討論';
                    break;
                case 'http://adlnet.gov/expapi/activities/homework':
                    typeName = '作業';
                    break;
                default:
                    typeName = '未定義';
            }
            actTypeLegend.push({name: typeName});
        }
        this.setState({ eventMostActType: eventMostActType, actTypeLegend: actTypeLegend });
    }

    getEventCourseByMost = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let eventMostcourse = await this.eventService.getEventCourseByMost(date, org, filter, role);
        let courseLegend = await this.processLegend(eventMostcourse, "course");
        this.setState({ eventMostcourse: eventMostcourse, courseLegend: courseLegend });
    }

    getFullCourses = async (org: string) => {
        let fullCourses: any = await this.courseService.getFullCourses(org);
        let courseSelectValues = fullCourses.fullCourseList.map((course: any) => {
            return {
                label: course.name,
                value: course.courseId
            }
        });
        this.setState({ courseList: courseSelectValues });
    }

    processLegend = (ary: any[], type: string = "123") => {
        let nameAry = [];
        for (let i = 0; i < ary.length; i++) {
            let data = {}
            if (type === "course") {
                data = {
                    name: `${Math.ceil(ary[i].totalAttend / ary.map((x: any) => x.totalAttend).reduce((a: any, c: any) => a + c) * 100)}% ${ary[i].name}}`
                }
            } else {
                data = {
                    name: `${Math.ceil(ary[i].event / ary.map((x: any) => x.event).reduce((a: any, c: any) => a + c) * 100)}% ${ary[i].name}${(ary[i].name.length > 7) ? "..." : ""}`
                }
            }
            nameAry.push(data)
        }
        return nameAry;
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 200) {
            alert("日期區間請在六個月內")
        } else {
            this.resetState();
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
            this.getEventCount(this.date, this.org, this.account, this.context.role, this.state.courseId);
            this.getEventUser(this.date, this.org, this.account, this.context.role, this.state.courseId);
            this.getEventByDate(this.date, this.org, this.account, this.context.role, this.state.courseId);
            this.getEventFinishCourseByDate(this.date, this.org, this.account, this.context.role, this.state.courseId);
            this.getEventByMostUser(this.date, this.org, this.account, this.context.role, this.state.courseId);
            this.getEventByMostVerb(this.date, this.org, this.account, this.context.role, this.state.courseId);
            this.getEventByMostActivities(this.date, this.org, this.account, this.context.role, this.state.courseId);
            this.getEventByMostActType(this.date, this.org, this.account, this.context.role, this.state.courseId);
            this.getEventCourseByMost(this.date, this.org, this.account, this.context.role, this.state.courseId);
        }
    }

    roleType = () => {
        if (this.state.role === "admin" || this.state.role === "owner")
            return true;
        else
            return false;
    }

    refresh = (id: number) => {
        let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
        switch (id) {
            case 0: {
                this.setState({ eventAry: [-1] });
                this.getEventByDate(date, this.org, this.account, this.context.role, this.state.courseId);
                break;
            }
            case 1: {
                this.setState({ userAry: [-1] });
                this.getEventByDate(date, this.org, this.account, this.context.role, this.state.courseId);
                break;
            }
            case 2: {
                this.setState({ eventMostVerb: [-1] });
                this.getEventByMostVerb(date, this.org, this.account, this.context.role, this.state.courseId);
                break;
            }
            case 3: {
                this.setState({ eventMostAct: [-1] });
                this.getEventByMostActivities(date, this.org, this.account, this.context.role, this.state.courseId);
                break;
            }
            case 4: {
                this.setState({ eventMostActType: [-1] });
                this.getEventByMostActType(date, this.org, this.account, this.context.role, this.state.courseId);
                break;
            }
            case 5: {
                this.setState({ eventMostcourse: [-1] });
                this.getEventCourseByMost(date, this.org, this.account, this.context.role, this.state.courseId);
                break;
            }
            case 6: {
                this.setState({ eventMostUserList: [-1] });
                this.getEventByMostUser(date, this.org, this.account, this.context.role, this.state.courseId);
                break;
            }
            case 7: {
                this.setState({ courseAry: [-1] });
                this.getEventFinishCourseByDate(date, this.org, this.account, this.context.role, this.state.courseId);
                break;
            }
        }
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any[], type: string) => {
        let eventAry = [], userAry = [], courseAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false
            let index = 0
            let eventData = {
                x: dateAry[i],
                y: 0
            }
            let userData = {
                x: dateAry[i],
                y: 0
            }
            let courseData = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }
            if (hasValue) {
                if (type === "event") {
                    eventData.y = Math.floor(ary[index].event);
                    userData.y = Math.floor(ary[index].users)
                    eventAry.push(eventData);
                    userAry.push(userData);
                } else if (type === "course") {
                    courseData.y = Math.floor(ary[index].totalFinish);
                    courseAry.push(courseData);
                }

            }
            else {
                eventAry.push(eventData);
                userAry.push(userData);
                courseAry.push(courseData);
            }
        }
        if (type === "event")
            return [eventAry, userAry];
        else
            return courseAry;
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    periodDateHandler = (e: any) => {
        const targetPeriod = e.target.value;
        if(targetPeriod){
            const periodStartDate = targetPeriod.split('/')[0];
            const periodEndDate = targetPeriod.split('/')[1];
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: true,
                eDateDisabled: true,
                sdate: periodStartDate,
                edate: periodEndDate
            });
        }else{
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: false,
                eDateDisabled: false
            });
        }
    }

    handleCourseChange = (e: any) => {
        if (e === null)
            this.setState({ courseId: '' })
        else
            this.setState({ courseId: e.value })
    }

    processActTypeNameTooltip = (name: any) => {
        let typeName = '';
        switch(name){
            case 'https://w3id.org/xapi/acrossx/activities/video':
                typeName = '影片';
                break;
            case 'http://adlnet.gov/expapi/activities/cmi.interaction':
                typeName = '題目';
                break;
            case 'http://adlnet.gov/expapi/activities/link':
                typeName = '網頁連結';
                break;
            case 'http://adlnet.gov/expapi/activities/assessment':
                typeName = '測驗';
                break;
            case 'http://adlnet.gov/expapi/activities/course':
                typeName = '課程';
                break;
            case 'http://activitystrea.ms/schema/1.0/file':
                typeName = '檔案';
                break;
            case 'http://id.tincanapi.com/activitytype/discussion':
                typeName = '討論';
                break;
            case 'http://adlnet.gov/expapi/activities/homework':
                typeName = '作業';
                break;
            default:
                typeName = '未定義';
        }
        return typeName;
    }

    render() {
        return (
            <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <ul id="title-spacer" className="breadcrumbs">
                            <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >總覽</span></Link></li>
                        </ul>
                    </div>
                    <div className="d-md-flex justify-content-md-end">
                        <div className="col-md-3 py-1">
                            <h5>課程</h5>
                            <Select options={this.state.courseList} onChange={this.handleCourseChange} isClearable={true} />
                        </div>
                        <div className="col-md-3 py-1" style={{marginLeft: '0.5em'}}>
                            <h5>學期</h5>
                            <Form.Select value={this.state.periodDate} onChange={this.periodDateHandler}>
                                <option value="">自訂</option>
                                <option value="2022-02-01/2022-07-31">110-第二學期</option>
                                <option value="2021-08-01/2022-01-31">110-第一學期</option>
                                <option value="2021-02-01/2021-07-31">109-第二學期</option>
                                <option value="2020-08-01/2021-01-31">109-第一學期</option>
                            </Form.Select>
                        </div>
                        <div className="col-md-5 py-1">
                            <h5>時間</h5>
                            <div className="row">
                                <div className="col-auto ms-2">
                                    <div className="input-group">
                                        <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} disabled={this.state.sDateDisabled} />
                                    </div>
                                </div>
                                <div className="col-auto ms-2 py-2 m-none lh-lg">~</div>
                                <div className="col-auto ms-2">
                                    <div className="input-group">
                                        <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} disabled={this.state.eDateDisabled} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto ms-2 py-1">
                            <h5>動作</h5>
                            <button className="btn btn-secondary" onClick={this.submitDateRange}>篩選</button>
                        </div>
                    </div>
                    <OverviewGrid
                        totalRecord={this.state.totalEvent.number}
                        totalStudent={this.state.eventUser.agents}
                        totalFinishEvent={this.state.eventUser.activities}
                    />
                </div>
                <div className="row">
                    <div className="col-md-6 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>學習活躍度</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">學習活躍度</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，每一天的所有學習紀錄的數量 (<a href="https://leaverij.gitbook.io/visca/tech/recipes" target="_blank" rel="noreferrer">所有學習行為說明頁面</a>)
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split "
                                            data-bs-toggle="dropdown" aria-expanded="false" >更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/overview/event/${this.date}/${this.org}` : `/embed/overview/event/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li>
                                            <li><button className="dropdown-item" onClick={() => this.refresh(0)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.eventAry} /></li>
                                            <li><ImgBtn id={0} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <OverviewChart
                                tickFormat={this.tickFormat}
                                labelx={"日期"}
                                labely={"筆數"}
                                data={this.state.eventAry}
                                datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} records`}
                                chartType={"line"}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>活躍使用者</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">活躍使用者</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，每一天的有進行學習的不重複的學生數量(<a href="https://leaverij.gitbook.io/visca/tech/recipes" target="_blank" rel="noreferrer">所有學習行為說明頁面</a>)
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/overview/users/${this.date}/${this.org}` : `/embed/overview/users/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li>                                            
                                            <li><button className="dropdown-item" onClick={() => this.refresh(1)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.userAry} /></li>
                                            <li><ImgBtn id={1} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <OverviewChart
                                tickFormat={this.tickFormat}
                                labelx={"日期"}
                                labely={"人數"}
                                data={this.state.userAry}
                                datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} users`}
                                chartType={"line"}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>熱門學習動作</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">熱門學習動作</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，前十個有最多學習紀錄的學習「動作」(<a href="https://leaverij.gitbook.io/visca/tech/recipes" target="_blank" rel="noreferrer">所有學習行為說明頁面</a>)
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/overview/verb/${this.date}/${this.org}` : `/embed/overview/verb/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li>                            
                                            <li><button className="dropdown-item" onClick={() => this.refresh(2)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.eventMostVerb} /></li>
                                            <li><ImgBtn id={2} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <OverviewChart
                                data={this.state.eventMostVerb}
                                name={this.state.verbLegend}
                                x={"name"}
                                y={"event"}
                                chartType={"circle"}
                                datum={({ datum }: { datum: any }) => `${datum.name}\n${Math.ceil(datum.event / this.state.eventMostVerb.map(x => x.event).reduce((a, c) => a + c) * 100)}%`}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>熱門學習活動類型</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">熱門學習活動類型</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，前十個有最多學生進行學習行為的「活動」的「活動類型」
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/overview/type/${this.date}/${this.org}` : `/embed/overview/type/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li>                                               
                                            <li><button className="dropdown-item" onClick={() => this.refresh(4)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.eventMostActType} /></li>
                                            <li><ImgBtn id={4} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <OverviewChart
                                data={this.state.eventMostActType}
                                name={this.state.actTypeLegend}
                                x={"name"}
                                y={"event"}
                                datum={({ datum }: { datum: any }) => `${(this.processActTypeNameTooltip(datum.name))}\n${Math.ceil(datum.event / this.state.eventMostActType.map(x => x.event).reduce((a, c) => a + c) * 100)}%`}
                                chartType={"circle"}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>熱門學習活動</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">熱門學習活動</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，前十個有最多學生進行學習的「活動」(<a href="https://leaverij.gitbook.io/visca/tech/recipes" target="_blank" rel="noreferrer">所有學習行為說明頁面</a>)
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/overview/act/${this.date}/${this.org}` : `/embed/overview/act/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li>                                      
                                            <li><button className="dropdown-item" onClick={() => this.refresh(3)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.eventMostAct} /></li>
                                            <li><ImgBtn id={3} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <OverviewChart
                                height={200}
                                legendx={250}
                                legendGutter={10}
                                legendFontSize={6}
                                data={this.state.eventMostAct}
                                name={this.state.actLegend}
                                x={"name"}
                                y={"event"}
                                chartType={"circle"}
                                datum={({ datum }: { datum: any }) => `${(datum.name)}\n${Math.ceil(datum.event / this.state.eventMostAct.map(x => x.event).reduce((a, c) => a + c) * 100)}%`}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>熱門課程</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">熱門課程</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，每一天被學生進入次數最多的前十個課程
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/overview/course/${this.date}/${this.org}` : `/embed/overview/course/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li>                                               
                                            <li><button className="dropdown-item" onClick={() => this.refresh(5)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.eventMostcourse} /></li>
                                            <li><ImgBtn id={5} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <OverviewChart
                                height={200}
                                legendx={250}
                                legendGutter={10}
                                legendFontSize={6}
                                data={this.state.eventMostcourse}
                                name={this.state.courseLegend}
                                x={"name"}
                                y={"totalAttend"}
                                chartType={"circle"}
                                datum={({ datum }: { datum: any }) => `${(datum.name)}\n${Math.ceil(datum.totalAttend / this.state.eventMostcourse.map(x => x.totalAttend).reduce((a, c) => a + c) * 100)}%`}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>最積極學生</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">最積極學生</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，有最多學習紀錄的學生的排行榜(<a href="https://leaverij.gitbook.io/visca/tech/recipes" target="_blank" rel="noreferrer">所有學習行為說明頁面</a>)
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/overview/user/${this.date}/${this.org}` : `/embed/overview/user/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li>                                            
                                            <li><button className="dropdown-item" onClick={() => this.refresh(6)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.eventMostUserList} /></li>
                                            <li><ImgBtn id={6} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <OverviewChart
                                data={this.state.eventMostUserList}
                                x={"name"}
                                y={"event"}
                                angle={45}
                                datamFunc={({ datum }: { datum: any }) => `${datum.event}次`}
                                domain={{ x: [0, this.state.eventMostUserList.length + 1], y: [0, Math.max(...this.state.eventMostUserList.map(p => p.event)) + 1000] }}
                                chartType={"bar"}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>完課人數</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">完課人數</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，每一天完成課程的學生人數
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/overview/finish/${this.date}/${this.org}` : `/embed/overview/finish/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li>                             
                                            <li><button className="dropdown-item" onClick={() => this.refresh(7)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.courseAry} /></li>
                                            <li><ImgBtn id={7} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <OverviewChart
                                tickFormat={this.tickFormat}
                                labelx={"日期"}
                                labely={"人數"}
                                data={this.state.courseAry}
                                datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} users`}
                                chartType={"line"}
                            />
                        </div>
                    </div>
                </div>
            </main>
        );
    }

}

export default Overview;