import moment from 'moment';
import React from 'react';
import VerbChart from './verbChart';
import EventService from '../../services/eventService';
import CourseService from '../../services/courseService';
import { Link } from 'react-router-dom';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import LinkBtn from '../opionBtn/exportLink';
import { LoginContext } from '../../loginContext';
import Select from 'react-select';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';

interface VProps { }
interface VState {
    courseList: any[];
    courseId: string;
    eventMostVerb: any[];
    eventDailyVerb: any[];
    eventCSV: any[];
    verbName: any[];
    sdate: string;
    edate: string;
    sDateDisabled: boolean;
    eDateDisabled: boolean;
    periodDate: string;
}

class VerbList extends React.Component<VProps, VState>{
    private courseService: CourseService;
    private eventService: EventService;
    private sdate: string;
    private edate: string;
    private org: string;
    private account: string;
    private role: string;
    private date: string;
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        this.courseService = new CourseService();
        this.eventService = new EventService();
        this.org = "";
        this.account = "";
        this.role = "";
        this.state = {
            courseList: [-1],
            courseId: '',
            eventMostVerb: [-1],
            eventDailyVerb: [-1],
            eventCSV: [],
            verbName: [],
            sdate: '2021-08-01',//moment().subtract(14, 'day').format('YYYY-MM-DD'),
            edate: '2022-01-31',//moment().format('YYYY-MM-DD')
            sDateDisabled: true,
            eDateDisabled: true,
            periodDate: '2021-08-01/2022-01-31'
        }
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
        this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
        //this.getAllCoursefinish(this.org, this.account, this.role);
        this.getFullCourses(this.org);
        this.getEventByMostVerb(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getEventByDailyVerb(this.date, this.org, this.account, this.role, this.state.courseId);
    }

    resetState = () => {
        this.setState({
            courseId: '',
            eventMostVerb: [-1],
            eventDailyVerb: [-1]
        })
    }

    getAllCoursefinish = async (org: string, filter: string, role: string) => {
        let courseInfo: any = await this.courseService.getAllCoursefinish(org, filter, role);
        let courseSelectValues = courseInfo.courseList.map((course: any) => {
            return {
                label: course.name,
                value: course._id
            }
        });
        this.setState({ courseList: courseSelectValues });
    }

    getFullCourses = async (org: string) => {
        let fullCourses: any = await this.courseService.getFullCourses(org);
        let courseSelectValues = fullCourses.fullCourseList.map((course: any) => {
            return {
                label: course.name,
                value: course.courseId
            }
        });
        this.setState({ courseList: courseSelectValues });
    }

    getEventByMostVerb = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let eventMostVerb = await this.eventService.getEventByMostVerb(date, org, filter, role, courseId);
        this.setState({ eventMostVerb: eventMostVerb });
    }

    getEventByDailyVerb = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let eventList = await this.eventService.getEventByDailyVerb(date, org, filter, role, courseId);
        let eventDailyVerb = await this.settingDailyData(eventList);
        let eventCAV = await this.processData(eventDailyVerb);
        let verbName = await this.setLegendName(eventList)
        this.setState({ eventDailyVerb: eventDailyVerb, verbName: verbName, eventCSV: eventCAV });
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    refresh = (id: number) => {
        let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
        switch (id) {
            case 0: {
                this.setState({ eventDailyVerb: [-1] })
                this.getEventByDailyVerb(date, this.org, this.account, this.role, this.state.courseId);

                break;
            }
            case 1: {
                this.setState({ eventMostVerb: [-1] })
                this.getEventByMostVerb(date, this.org, this.account, this.role, this.state.courseId);
                break;

            }

        }
    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 200) {
            alert("日期區間請在六個月內")
        } else {
            this.resetState();
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
            this.getEventByMostVerb(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getEventByDailyVerb(this.date, this.org, this.account, this.role, this.state.courseId);
        }
    }

    processData = async(data: any[]) => {
        let ary: any = [];
        for (let i = 0; i < data.length; i++) {
                ary = ary.concat(data[i])
        }
        return ary;
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    setLegendName = (ary: any[]) => {
        let nameAry = [];
        for (let j = 0; j < ary.length; j++) {
            let data = {
                name: ary[j].events[0].name
            }
            nameAry.push(data)
        }
        return nameAry;
    }

    settingDailyData = (ary: any[]) => {
        let dataAry: any[] = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let j = 0; j < ary.length; j++) {
            let temp: any[] = []
            for (let i = 0; i < dayOfMonth; i++) {
                let hasValue = false
                let index = 0
                for (let k = 0; k < ary[j].events.length; k++) {
                    if (`${ary[j].events[k].year}/${("0" + ary[j].events[k].month).slice(-2)}/${("0" + ary[j].events[k].day).slice(-2)}` === dateAry[i]) {
                        hasValue = true;
                        index = k;
                        break;
                    }
                }
                let data = {
                    x: dateAry[i],
                    y: 0,
                    name: ""
                }
                if (hasValue) {
                    data.y = ary[j].events[index].event
                    data.name = ary[j].events[index].name
                    temp.push(data)
                }
                else {
                    temp.push(data)
                }
            }
            dataAry.push(temp);
        }
        return dataAry;
    }

    roleType = () => {
        if (this.role === "admin" || this.role === "owner")
            return true;
        else
            return false;
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    handleCourseChange = (e: any) => {
        if (e === null)
            this.setState({ courseId: '' })
        else
            this.setState({ courseId: e.value })
    }

    periodDateHandler = (e: any) => {
        const targetPeriod = e.target.value;
        if(targetPeriod){
            const periodStartDate = targetPeriod.split('/')[0];
            const periodEndDate = targetPeriod.split('/')[1];
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: true,
                eDateDisabled: true,
                sdate: periodStartDate,
                edate: periodEndDate
            });
        }else{
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: false,
                eDateDisabled: false
            });
        }
    }

    render() {
        return (
            <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <ul id="title-spacer" className="breadcrumbs">
                            <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >學習行為分析</span></Link>/</li>
                            <li><span>學習動作紀錄</span></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 py-1">
                        <h5>課程</h5>
                        <Select options={this.state.courseList} onChange={this.handleCourseChange} isClearable={true} />
                    </div>
                    <div className="col-md-3 py-1">
                        <h5>學期</h5>
                        <Form.Select value={this.state.periodDate} onChange={this.periodDateHandler}>
                            <option value="">自訂</option>
                            <option value="2022-02-01/2022-07-31">110-第二學期</option>
                            <option value="2021-08-01/2022-01-31">110-第一學期</option>
                            <option value="2021-02-01/2021-07-31">109-第二學期</option>
                            <option value="2020-08-01/2021-01-31">109-第一學期</option>
                        </Form.Select>
                    </div>
                    <div className="col-md-5">
                        <h5>日期</h5>
                        <div className="row">
                            <div className="col-auto ms-2 py-1">
                                <div className="input-group">
                                    <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} disabled={this.state.sDateDisabled} />
                                </div>
                            </div>
                            <div className="col-auto ms-2 py-2 m-none lh-lg">~</div>
                            <div className="col-auto ms-2 py-1">
                                <div className="input-group">
                                    <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} disabled={this.state.eDateDisabled} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 py-1">
                        <h5>動作</h5>
                        <button className="btn btn-secondary" onClick={this.submitDateRange}>篩選</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>學習動作紀錄</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">學習動作紀錄</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，每一天的學習記錄中的「動作」的數量
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/verb/event/${this.date}/${this.org}` : `/embed/verb/event/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li>                                                <li><button className="dropdown-item" onClick={() => this.refresh(0)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.eventCSV} /></li>
                                            <li><ImgBtn id={0} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <VerbChart
                                tickFormat={this.tickFormat}
                                nameData={this.state.verbName}
                                dataStack={this.state.eventDailyVerb}
                                datamFunc={({ datum }: { datum: any }) => (datum.y !== 0) ? `${datum.x} \n${datum.name} ${datum.y} times` : ""}
                                chartType={"line"}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>熱門學習動作</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">熱門學習動作</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，前十個有最多學習紀錄的學習「動作」
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/verb/most/${this.date}/${this.org}` : `/embed/verb/most/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li>
                                            <li><button className="dropdown-item" onClick={() => this.refresh(1)}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.eventMostVerb} /></li>
                                            <li><ImgBtn id={1} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <VerbChart
                                data={this.state.eventMostVerb}
                                x={"name"}
                                y={"event"}
                                chartType={"circle"}
                                datum={({ datum }: { datum: any }) => `${datum.name}\n${Math.ceil(datum.event / this.state.eventMostVerb.map(x => x.event).reduce((a, c) => a + c) * 100)}%`} />
                        </div>
                    </div>
                </div>
            </main>
        );
    }

}

export default VerbList;