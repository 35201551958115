import HttpHandler from './APIServices';
import { VideoAPI } from './API';
import { Video, Videosday, VideoInfo, VideoEvent, Videoday } from '../dataModel/videoModel';
import moment from 'moment';
import { roleFilter } from './queryFilter';

class VideoService {
    private api: VideoAPI;

    constructor() {
        this.api = new VideoAPI()
    }

    getVideoList = async (sdtae: string, org: string, filter:string, role:string, courseId?: string): Promise<Video[]> => {
        let url = this.api.basePath + this.api.getVideos + '/' + sdtae + '/' + org;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        let videoList: Video[] = data.videos;
        for (let i = 0; i < videoList.length; i++) {
            let durarion = parseFloat(videoList[i].durarion);
            let avgWatchHour = parseFloat(videoList[i].avgWatchHour);
            videoList[i].durarion = moment().startOf('day').seconds(durarion).format('HH:mm:ss');
            videoList[i].avgWatchHour = `${moment().startOf('day').seconds(avgWatchHour).format('HH:mm:ss')}(${((avgWatchHour / durarion) * 100).toFixed(2)}%)`;
        }
        return videoList;
    }

    getTotalWatchPeople = async (sdtae: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = this.api.basePath + this.api.getTotalWatchPeople + '/' + sdtae + '/' + org;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getWatchTimesAndHours = async (sdtae: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = this.api.basePath + this.api.getWatchTimesAndHours + '/' + sdtae + '/' + org;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        data.totalWatchHours = this.processTimeFormat(data.totalWatchHours);
        data.avgWatchHours = this.processTimeFormat(data.avgWatchHours);
        return data;
    }

    getVideosByDay = async (sdtae: string, org: string, filter:string, role:string, courseId?: string): Promise<Videosday[]> => {
        let url = this.api.basePath + this.api.getVideosByDay + '/' + sdtae + '/' + org;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data.videoList;
    }

    getSingleVideo = async (sdtae: string, videoId: string, org: string, filter:string, role:string, courseId?: string): Promise<VideoInfo> => {
        let url = `${this.api.basePath}${this.api.getSingleVideo}/${sdtae}/${org}?${this.api.videoParams}=${encodeURIComponent(videoId)}`;
        url = await roleFilter(url + '&', filter, role, courseId)
        let data = await HttpHandler(url);
        let videoInfo: VideoInfo = data;
        videoInfo.durarionAsSec = data.durarion
        videoInfo.totalWatchHours = this.processTimeFormat(data.totalWatchHours);
        videoInfo.avgWatchHourV = moment().startOf('day').seconds(data.avgWatchHourV).format('HH:mm:ss')
        videoInfo.avgWatchHour = this.processTimeFormat(data.avgWatchHour);
        videoInfo.durarion = moment().startOf('day').seconds(data.durarion).format('HH:mm:ss')
        videoInfo.avgWatchHourPerTimes = moment().startOf('day').seconds(data.avgWatchHourPerTimes).format('HH:mm:ss')
        return videoInfo;
    }

    getSingleVideoByDay = async (sdtae: string, videoId: string, org: string, filter:string, role:string, courseId?: string): Promise<Videoday[]> => {
        let url = `${this.api.basePath}${this.api.getSingleVideoByDay}/${sdtae}/${org}?${this.api.videoParams}=${encodeURIComponent(videoId)}`;
        url = await roleFilter(url + '&', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getSingleVideoFinish = async (sdtae: string, videoId: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getSingleVideoFinish}/${sdtae}/${org}?${this.api.videoParams}=${encodeURIComponent(videoId)}`;
        url = await roleFilter(url + '&', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getSingleVideoEvent = async (sdtae: string, videoId: string, org: string, filter:string, role:string, courseId?: string): Promise<VideoEvent[]> => {
        let url = `${this.api.basePath}${this.api.getSingleVideoEvent}/${sdtae}/${org}?${this.api.videoParams}=${encodeURIComponent(videoId)}`;
        url = await roleFilter(url + '&', filter, role, courseId)
        let data: VideoEvent[] = await HttpHandler(url);
        for (let i = 0; i < data.length; i++) {
            data[i].watchHours = moment().startOf('day').seconds(parseFloat(data[i].watchHours)).format('HH:mm:ss');
            data[i].lastWatchTime = {
                date: moment(data[i].date).format('YYYY-MM-DD HH:mm:ss'),
                start: moment().startOf('day').seconds(data[i].start).format('HH:mm:ss'),
                end: moment().startOf('day').seconds(data[i].end).format('HH:mm:ss')
            }
        }
        return data;
    }

    processTimeFormat = (totalSeconds: number): string => {
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = Math.floor(totalSeconds % 60);
        let time = `${hours}h${minutes}m${seconds}s`;
        return time;
    }
}

export default VideoService;