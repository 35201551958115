import React from 'react';
import { VictoryLine, VictoryChart, VictoryAxis, DataGetterPropType, VictoryLabel, VictoryBar, VictoryStack, VictoryTooltip, VictoryTheme, VictoryScatter, VictoryVoronoiContainer } from 'victory';
import Loading from 'react-loading';

interface CCProps {
    chartType?: string;
    tickValues?: any[];
    tickFormat?: any;
    x?: DataGetterPropType;
    y?: DataGetterPropType;
    labelx?: string;
    labely?: string;
    dataStack?: any[];
    domain?: any;
    data?: any[];
    datum?: any;
    datamFunc?: any;
}

interface CCState { }

class CourseChart extends React.Component<CCProps, CCState>{
    renderLine() {
        return (
            <VictoryChart
                height={200}
                width={400}
                //theme={VictoryTheme.material}
                containerComponent={
                    <VictoryVoronoiContainer
                        labels={this.props.datamFunc}
                        labelComponent={
                            <VictoryTooltip
                                cornerRadius={10}
                                flyoutWidth={50}
                                flyoutStyle={{
                                    stroke: "none",
                                    fill: "black"
                                }}
                                style={{ fill: "white", fontSize: 6 }}
                            />
                        }
                    />
                }
            >
                <VictoryLabel x={20} y={24}
                    text={this.props.labely}
                    style={{ fontSize: "6px" }}
                />
                <VictoryLabel x={400} y={280}
                    text={this.props.labelx}
                    style={{ fontSize: "6px" }}
                />
                <VictoryAxis
                    style={{
                        tickLabels: {
                            fontSize: 6
                        }
                    }}
                    tickValues={this.props.tickValues}
                    tickFormat={this.props.tickFormat}
                />
                <VictoryAxis
                    dependentAxis
                    style={{
                        tickLabels: {
                            fontSize: 6
                        }
                    }}
                />
                <VictoryLine
                    data={this.props.data}
                    x={this.props.x}
                    y={this.props.y}
                    style={{
                        data: {
                            stroke: "#33FFFF",
                            strokeWidth: 1.0
                        }
                    }}
                />
            </VictoryChart>
        )
    }
    renderBar() {
        return (
            <VictoryChart height={200}
                width={400} domainPadding={{ x: 40 }}
                containerComponent={
                    <VictoryVoronoiContainer
                        labels={this.props.datamFunc}
                        labelComponent={
                            <VictoryTooltip
                                cornerRadius={10}
                                flyoutWidth={40}
                                flyoutStyle={{
                                    stroke: "none",
                                    fill: "black"
                                }}
                                style={{ fill: "white", fontSize: 6 }}
                            />
                        }
                    />
                }>
                <VictoryLabel x={20} y={24}
                    text={this.props.labely}
                    style={{ fontSize: "10px" }}
                />
                <VictoryLabel x={400} y={280}
                    text={this.props.labelx}
                    style={{ fontSize: "10px" }}
                />
                <VictoryAxis
                    style={{
                        tickLabels: {
                            fontSize: 10
                        }
                    }}
                    tickValues={this.props.tickValues}
                    tickFormat={this.props.tickFormat}
                />
                <VictoryAxis
                    dependentAxis
                    style={{
                        tickLabels: {
                            fontSize: 10
                        }
                    }}
                />
                <VictoryBar
                    data={this.props.data}
                    x={this.props.x}
                    y={this.props.y}
                    style={{
                        data: {
                            fill: "#00DDDD",
                            stroke: "#666666",
                            fillOpacity: 0.7,
                            strokeWidth: 0.5
                        }
                    }}
                />
            </VictoryChart>
        )
    }

    renderPoint() {
        return (
            <VictoryChart
                height={200}
                width={400}
                theme={VictoryTheme.material}
                domain={{ x: [0, 100], y: [0, 100] }}
                domainPadding={{ x: 5, y: 5 }}
                containerComponent={
                    <VictoryVoronoiContainer
                        labels={this.props.datamFunc}
                        labelComponent={
                            <VictoryTooltip
                                cornerRadius={10}
                                flyoutWidth={40}
                                flyoutStyle={{
                                    stroke: "none",
                                    fill: "black"
                                }}
                                style={{ fill: "white", fontSize: 6 }}
                            />
                        }
                    />
                }
            >
                <VictoryLabel x={20} y={24}
                    text={this.props.labely}
                    style={{ fontSize: "6px" }}
                />
                <VictoryLabel x={320} y={320}
                    text={this.props.labelx}
                    style={{ fontSize: "6px" }}
                />
                <VictoryAxis
                    style={{
                        tickLabels: {
                            fontSize: 6
                        }
                    }}
                    tickValues={this.props.tickValues}
                    tickFormat={this.props.tickFormat}
                />
                <VictoryAxis
                    dependentAxis
                    style={{
                        tickLabels: {
                            fontSize: 6
                        }
                    }}
                />
                <VictoryScatter
                    style={{ data: { fill: "#c43a31" } }}
                    size={2}
                    data={this.props.data}
                />
            </VictoryChart>
        )
    }

    renderStack() {
        let data: any = this.props.dataStack
        const getBarData = () => {
            if (data !== undefined && data.length > 0) {
                let bar = data.map((data: any, index: any) => {
                    return <VictoryBar key={index} data={data} />
                })
                return bar;
            }
        }
        return (
            <>
                <VictoryChart
                    theme={VictoryTheme.material}
                    domain={this.props.domain}
                    domainPadding={{ x: 30 }}
                    height={200}
                    width={400}
                    containerComponent={
                        <VictoryVoronoiContainer
                            labels={this.props.datamFunc}
                            labelComponent={
                                <VictoryTooltip
                                    cornerRadius={10}
                                    flyoutWidth={30}
                                    flyoutStyle={{
                                        stroke: "none",
                                        fill: "black"
                                    }}
                                    style={{ fill: "white", fontSize: 6 }}
                                />
                            }
                        />
                    }>
                    <VictoryLabel x={20} y={24}
                        text={this.props.labely}
                        style={{ fontSize: "6px" }}
                    />
                    <VictoryLabel x={400} y={280}
                        text={this.props.labelx}
                        style={{
                            fontSize: "6px",
                        }}
                    />
                    <VictoryAxis
                        tickLabelComponent={<VictoryLabel angle={45} dx={12}
                            verticalAnchor="start" />}
                        style={{
                            tickLabels: {
                                fontSize: 6,

                            },
                            axisLabel: {

                            }
                        }}
                        tickValues={this.props.tickValues}
                        tickFormat={this.props.tickFormat}
                    />
                    <VictoryAxis
                        dependentAxis
                        style={{
                            tickLabels: {
                                fontSize: 6
                            }
                        }}
                    />
                    <VictoryStack colorScale={["green", "red"]} >
                        {getBarData()}
                    </VictoryStack>

                </VictoryChart>
            </>
        )
    }

    renderLoading() {
        return <Loading type={"cylon"} color={"black"} height={'30%'} width={'30%'}></Loading>
    }

    chartType = () => {
        if (this.props.data !== undefined && this.props.data[0] === -1) {
            return (
                <>
                    {this.renderLoading()}
                </>
            )
        }
        else if (this.props.chartType === "line")
            return (
                <>
                    {this.renderLine()}
                </>
            )
        else if (this.props.chartType === "bar")
            return (
                <>
                    {this.renderBar()}
                </>
            )
        else if (this.props.chartType === "point")
            return (
                <>
                    {this.renderPoint()}
                </>
            )
        else if (this.props.chartType === "stack")
            return (
                <>
                    {this.renderStack()}
                </>
            )
    }

    render() {
        return (
            <>
                {this.chartType()}
            </>
        )
    }
}

export default CourseChart;