import { CSVLink } from "react-csv";
import React from 'react';
import { getRandomString } from './randomFileName';

interface MProps {
    data: any[];
}
interface MState {

}

class CSVBtn extends React.Component<MProps, MState>{
    render() {
        return (
            <CSVLink data={(this.props.data[0] === -1) ? [] : this.props.data} className="dropdown-item download"
                filename={`${getRandomString()}_${new Date().toJSON().slice(0, 10)}.csv`}
            >
                匯出CSV
            </CSVLink>
        )
    }
}

export default CSVBtn;