import React from 'react';


interface QCProps {
    data: any;
}
interface QCtate { }

class BlockchainDetail extends React.Component<QCProps, QCtate>{

    render() {
        return (
            <div className="modal fade" id="exampleModal" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="my-3 p-3 bg-body rounded shadow-sm">
                                <h3 className="border-bottom pb-2 mb-0">詳細內容</h3>
                                <div className="d-flex text-muted pt-3">
                                    <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                        <h6><strong className="text-gray-dark">ID</strong> {this.props.data.ID}</h6>
                                    </div>
                                </div>
                                <div className="d-flex text-muted pt-3">
                                    <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <h6><strong className="text-gray-dark">Cert ID</strong> {this.props.data.certId}</h6>
                                    </div>
                                </div>
                                <div className="d-flex text-muted pt-3">
                                    <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <h6><strong className="text-gray-dark">名稱</strong> {this.props.data.name}</h6>
                                    </div>
                                </div>
                                <div className="d-flex text-muted pt-3">
                                    <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <h6><strong className="text-gray-dark">日期</strong> {this.props.data.date}</h6>
                                    </div>
                                </div>
                                <div className="d-flex text-muted pt-3">
                                    <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <h6><strong className="text-gray-dark">發行人</strong> {this.props.data.issuer}</h6>
                                    </div>
                                </div>
                                <div className="d-flex text-muted pt-3">
                                    <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <h6><strong className="text-gray-dark">持有者</strong> {this.props.data.user_account}</h6>
                                    </div>
                                </div>
                                <div className="d-flex text-muted pt-3">
                                    <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <h6><strong className="text-gray-dark">連結</strong> {this.props.data.imgOrFile}</h6>
                                    </div>
                                </div>
                                <div className="d-flex text-muted pt-3">
                                    <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <h6><strong className="text-gray-dark">驗證機構</strong> {this.props.data.verification}</h6>
                                    </div>
                                </div>
                                <div className="d-flex text-muted pt-3">
                                    <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <h6><strong className="text-gray-dark">描述</strong> {this.props.data.describe}</h6>
                                    </div>
                                </div>
                                <div className="d-flex text-muted pt-3">
                                    <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <h6><strong className="text-gray-dark">Hash</strong> {this.props.data['Transaction ID']}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BlockchainDetail;