import React from 'react';
import Loading from 'react-loading';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface QGProps {
    attendPeople: number;
    passPeople: number;
    avgScore: number;
    avgCostTime: string;
    sigma: number;
    highRange: number;
    lowRange: number;
}
interface QGtate { }

class QuizGrid extends React.Component<QGProps, QGtate>{

    renderLoading() {
        return <Loading type={"spin"} color={"#ffffff"} height={'20%'} width={'20%'}></Loading>
    }

    render() {
        return (
            <>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">參與人數</Popover.Header>
                                        <Popover.Body>
                                        參與此測驗的學生人數
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.attendPeople === -1) ? this.renderLoading() : this.props.attendPeople}</h2>
                        <h5>參與人數</h5>
                    </div>
                </div>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">通過人數</Popover.Header>
                                        <Popover.Body>
                                        通過此測驗(達 60 分以上)的學生人數
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.passPeople === -1) ? this.renderLoading() : this.props.passPeople}</h2>
                        <h5>通過人數</h5>
                    </div>
                </div>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">平均分數</Popover.Header>
                                        <Popover.Body>
                                        此測驗的平均分數
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.avgScore === -1) ? this.renderLoading() : this.props.avgScore}</h2>
                        <h5>平均分數</h5>
                    </div>
                </div>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">平均花費時間</Popover.Header>
                                        <Popover.Body>
                                        學生完成測驗的平均花費時間
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.avgCostTime === "-1") ? this.renderLoading() : this.props.avgCostTime}</h2>
                        <h5>平均花費時間</h5>
                    </div>
                </div>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">標準差</Popover.Header>
                                        <Popover.Body>
                                        此測驗的標準差
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.sigma === -1) ? this.renderLoading() : this.props.sigma}</h2>
                        <h5>標準差</h5>
                    </div>
                </div>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">高標</Popover.Header>
                                        <Popover.Body>
                                        此測驗的最高分數
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.highRange === -1) ? this.renderLoading() : this.props.highRange}</h2>
                        <h5>高標</h5>
                    </div>
                </div>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">低標</Popover.Header>
                                        <Popover.Body>
                                        此測驗的最低分數
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.lowRange === -1) ? this.renderLoading() : this.props.lowRange}</h2>
                        <h5>低標</h5>
                    </div>
                </div>
            </>
        )
    }
}

export default QuizGrid;