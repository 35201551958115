import HttpHandler from './APIServices';
import { ActivityAPI } from './API';
import { roleFilter } from './queryFilter';
import moment from 'moment';

class ActivityService {
    private api: ActivityAPI;

    constructor() {
        this.api = new ActivityAPI()
    }

    getTotalAct = async (date: string, org:string, filter:string, role:string, courseId: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getTotalAct}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getActList = async (date: string, org:string, filter:string, role:string, courseId: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getActList}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getLastAct = async (actId: string, org:string, filter:string, role:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getLastAct}/${org}?actId=${encodeURIComponent(actId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        let lastevent = {
            date:moment(data[0].timestamp).format('YYYY-MM-DD HH:mm:ss')
        }
        return lastevent;
    }

    getActInfo = async (date: string, actId: string, org:string, filter:string, role:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getActInfo}/${date}/${org}?actId=${encodeURIComponent(actId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getEventByDailyAct = async (date: string, actId: string, org:string, filter:string, role:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventByDailyAct}/${date}/${org}?actId=${encodeURIComponent(actId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getActByVerb = async (date: string, actId: string, org:string, filter:string, role:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getActByVerb}/${date}/${org}?actId=${encodeURIComponent(actId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getActBySucess = async (date: string, actId: string, org:string, filter:string, role:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getActBySucess}/${date}/${org}?actId=${encodeURIComponent(actId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getRecentAct = async (date: string, actId: string, org:string, filter:string, role:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getRecentAct}/${date}/${org}?actId=${encodeURIComponent(actId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }
}

export default ActivityService;