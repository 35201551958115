import HttpHandler from './APIServices';
import { AccountAPI } from './API';

class AccountService {
    private api: AccountAPI;

    constructor() {
        this.api = new AccountAPI()
    }

    getAdmin = async (): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getAdmin}`;
        let credentials: RequestCredentials = "include"
        let option = {
            method: 'GET',
            credentials: credentials
        }
        let data = await HttpHandler(url, option);
        return data;
    }

    getStudentAccount = async (): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getStudentAccount}`;
        let credentials: RequestCredentials = "include"
        let option = {
            method: 'GET',
            credentials: credentials
        }
        let data = await HttpHandler(url, option);
        return data;
    }

    getTeacherAccount = async (): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getTeacherAccount}`;
        let credentials: RequestCredentials = "include"
        let option = {
            method: 'GET',
            credentials: credentials
        }
        let data = await HttpHandler(url, option);
        return data;
    }

    getApp = async (): Promise<any []> => {
        let url = `${this.api.basePath}${this.api.getApp}`;
        let credentials: RequestCredentials = "include"
        let option = {
            method: 'GET',
            credentials: credentials
        }
        let data = await HttpHandler(url, option);
        return data;
    }

    getOpencert = async (): Promise<any []> => {
        let url = `${this.api.basePath}${this.api.getOpencert}`;
        let credentials: RequestCredentials = "include"
        let option = {
            method: 'GET',
            credentials: credentials
        }
        let data = await HttpHandler(url, option);
        return data;
    }

    logout = async (): Promise<any> => {
        let url = `${this.api.basePath}${this.api.logout}`;
        let data = await HttpHandler(url);
        return data;
    }

    login = async (user: any): Promise<any> => {
        let url = `${this.api.basePath}${this.api.login}`;
        let option = {
            method: 'POST',
            body: JSON.stringify(user),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }
        let data = await HttpHandler(url, option);
        return data;
    }

    register = async (user: any): Promise<any> => {
        let url = `${this.api.basePath}${this.api.register}`;
        let option = {
            method: 'POST',
            body: JSON.stringify(user),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }
        let data = await HttpHandler(url, option);
        return data;
    }

    isLogin = async (): Promise<any> => {
        let url = `${this.api.basePath}${this.api.isLogin}`;
        let data = await HttpHandler(url);
        return data;
    }

    deleteAdmin = async (user: any): Promise<any> => {
        let url = `${this.api.basePath}${this.api.deleteAdmin}`;
        let option = {
            method: 'DELETE',
            body: JSON.stringify(user),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }
        let data = await HttpHandler(url, option);
        return data;
    }

}

export default AccountService;