import HttpHandler from './APIServices';
import { CourseAPI } from './API';
import moment from 'moment';
import { roleFilter } from './queryFilter';

class CourseService {
    private api: CourseAPI;

    constructor() {
        this.api = new CourseAPI()
    }

    getAllCoursefinish = async (org: string, filter: string, role: string): Promise<any> => {
        let url = this.api.basePath + this.api.getAllCoursefinish + '/' + org;
        url = await roleFilter(url + '?', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getCoursefinish = async (date: string, org: string, filter: string, role: string, courseId?: string): Promise<any> => {
        let url = this.api.basePath + this.api.getCoursefinish + '/' + date + '/' + org;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getFullCourses = async (org: string): Promise<any> => {
        let url = this.api.basePath + this.api.getFullCourses + '/' + org;
        //url = await roleFilter(url + '?', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getCourseHoursByDay = async (date: string, org: string, filter: string, role: string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getCourseHoursByDay}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getCourseTimesByDay = async (date: string, org: string, filter: string, role: string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getCourseTimesByDay}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getSingleCourse = async (date: string, courseId: string, org: string, filter: string, role: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getSingleCourse}/${date}/${org}?courseId=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getSingleCourseHours = async (date: string, courseId: string, org: string, filter: string, role: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getSingleCourseHours}/${date}/${org}?courseId=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getSingleCourseAttend = async (date: string, courseId: string, org: string, filter: string, role: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getSingleCourseAttend}/${date}/${org}?courseId=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getSingleCourseAttendByUser = async (date: string, courseId: string, org: string, filter: string, role: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getSingleCourseAttendByUser}/${date}/${org}?courseId=${encodeURIComponent(courseId)}`;
        let totalData = await HttpHandler(url);
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        let max = Math.max.apply(Math, totalData.map(function (o: any) { return o.attendTimes; }))
        let min = Math.min.apply(Math, totalData.map(function (o: any) { return o.attendTimes; }))
        let course: any[] = [];
        for (let i = 0; i < data.length; i++) {
            let person = {
                name: data[i].name,
                x: Math.floor(((data[i].attendTimes - min) / (max - min)) * 100),
                y: (data[i].score.length > 0) ? data[i].score[0].score : 0
            }
            course.push(person);
        }
        return course;
    }

    getSingleCourseAttendByUnit = async (date: string, courseId: string, org: string, filter: string, role: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getSingleCourseAttendByUnit}/${date}/${org}?courseId=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        for (let i = 0; i < data.length; i++) {
            data[i].avgAttendTime = Math.round(data[i].avgAttendTime);
            data[i].avgCostTime = moment().startOf('day').second(data[i].avgCostTime).format("mm:ss");
            data[i].costTime = Math.round(data[i].costTime / 3600);
            switch (data[i].type) {
                case 'http://activitystrea.ms/schema/1.0/file':
                    data[i].type = "文件"
                    break;
                case 'http://adlnet.gov/expapi/activities/link':
                    data[i].type = "連結"
                    break;
                case 'video':
                    data[i].type = "影片"
                    break;
                case 'quiz':
                    data[i].type = "測驗"
                    break;
            }
        }
        return data;
    }

    getSingleCourseEvent = async (courseId: string, org: string, filter: string, role: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getSingleCourseEvent}/${org}?courseId=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        let eventList = [];
        for (let i = 0; i < data.length; i++) {
            let event = {
                name: data[i].statement.actor.name,
                do: data[i].statement.verb.display['zh-TW'],
                what: data[i].statement.object.definition.name['zh-TW'],
                date: moment(data[i].statement.timestamp).format('YYYY-MM-DD'),
                time: moment(data[i].statement.timestamp).format('HH:mm:ss')
            }
            eventList.push({ event: event })
        }
        return eventList;
    }

    getCourseWarning = async (courseId: string, org: string, filter: string, role: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getCourseWarning}/${org}?courseId=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getCourseWeekWarning = async (courseId: string, org: string, filter: string, role: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getCourseWeekWarning}/${org}?courseId=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }
}

export default CourseService;