import React from 'react';
import { VictoryLine, VictoryChart, VictoryAxis, DataGetterPropType, VictoryLabel, VictoryTooltip, VictoryVoronoiContainer, VictoryPie, VictoryLegend, VictoryContainer } from 'victory';
import Loading from 'react-loading';

interface VCProps {
    chartType?: string;
    tickValues?: any[];
    tickFormat?: any;
    x?: DataGetterPropType;
    y?: DataGetterPropType;
    labelx?: string;
    labely?: string;
    data?: any[];
    dataStack?: any[];
    datum?: any;
    datamFunc?: any;
    domain?: any;
    angle?: number;
    nameData?:any[];
}

interface VCState { }

class VerbChart extends React.Component<VCProps, VCState>{

    renderStack() {
        let data: any = this.props.dataStack
        let colorScale = ["tomato", "orange", "gold", "#00FF99", "#00FFCC", "#00DDAA", "blue", "green", "#9900FF"];
        const getBarData = () => {
            if (data !== undefined && data.length > 0) {
                let bar = data.map((data: any, index: any) => {
                    return <VictoryLine key={index} data={data} style={{
                        data: {
                            stroke: colorScale[index],
                            strokeWidth: 2.0
                        }
                    }} />
                })
                return bar;
            }
        }
        return (
            <>
                <VictoryChart
                    height={200}
                    width={400}
                    domain={this.props.domain}
                    domainPadding={{ x: 10 }}
                    containerComponent={
                        <VictoryVoronoiContainer
                            labels={this.props.datamFunc}
                            labelComponent={
                                <VictoryTooltip
                                    cornerRadius={10}
                                    flyoutWidth={70}
                                    flyoutStyle={{
                                        stroke: "none",
                                        fill: "black"
                                    }}
                                    style={{ fill: "white", fontSize: 6 }}
                                />
                            }
                        />
                    }>
                    <VictoryLegend x={0} y={10}
                        orientation="horizontal"
                        gutter={20}
                        itemsPerRow={10}
                        style={{ labels: { fontSize: 6 } }}
                        colorScale={["tomato", "orange", "gold", "#00FF99", "#00FFCC", "#00DDAA", "blue", "green", "#9900FF"]}
                        data={this.props.nameData}
                        centerTitle
                        containerComponent={<VictoryContainer responsive={true}/>}
                    />
                    <VictoryLabel x={20} y={24}
                        text={this.props.labely}
                        style={{ fontSize: "8x" }}
                    />
                    <VictoryLabel x={400} y={280}
                        text={this.props.labelx}
                        style={{
                            fontSize: "8px",
                        }}
                    />
                    <VictoryAxis
                        style={{
                            tickLabels: {
                                fontSize: 8,

                            },
                            axisLabel: {

                            }
                        }}
                        tickValues={this.props.tickValues}
                        tickFormat={this.props.tickFormat}
                    />
                    <VictoryAxis
                        dependentAxis
                        style={{
                            tickLabels: {
                                fontSize: 8
                            }
                        }}
                    />
                    {getBarData()}
                </VictoryChart>
            </>
        )
    }

    renderCircle() {
        return (
            <VictoryChart 
                padding={{ left: -170, top: 10, bottom: 20 }}
                height={200} 
                width={400}
            >
                <VictoryLegend x={250} y={10}
                    orientation="vertical"
                    gutter={40}
                    style={{ labels: { fontSize: 8 } }}
                    colorScale={["#BBFFEE", "#77FFCC", "#33FFAA", "#00FF99", "#00FFCC", "#00DDAA", "#00AA88", "#008866"]}
                    data={this.props.data}
                />
                <VictoryPie
                    labelComponent={
                        <VictoryTooltip
                            cornerRadius={10}
                            flyoutWidth={40}
                            flyoutStyle={{
                                stroke: "none",
                                fill: "black"
                            }}
                            style={{ fill: "white", fontSize: 6 }}
                        />
                    }
                    style={{
                        data: {
                            fillOpacity: 0.9, stroke: "#FFFFFF", strokeWidth: 1
                        },
                        // labels: {
                        // fontSize: 6
                        //  }
                    }}
                    colorScale={["#BBFFEE", "#77FFCC", "#33FFAA", "#00FF99", "#00FFCC", "#00DDAA", "#00AA88", "#008866"]}
                    x={this.props.x}
                    y={this.props.y}
                    labels={this.props.datum}
                    data={this.props.data}
                />
                <VictoryAxis style={{
                    axis: { stroke: "transparent" },
                    ticks: { stroke: "transparent" },
                    tickLabels: { fill: "transparent" }
                }} />
            </VictoryChart>
        )
    }

    renderLoading() {
        return <Loading type={"cylon"} color={"black"} height={'30%'} width={'30%'}></Loading>
    }

    chartType = () => {
        if((this.props.data !== undefined && this.props.data[0] === -1) || (this.props.dataStack !== undefined && this.props.dataStack[0] === -1)){
            return (
                <>
                    {this.renderLoading()}
                </>
            )
        }
        else if (this.props.chartType === "line")
            return (
                <>
                    {this.renderStack()}
                </>
            )
        else if (this.props.chartType === "circle")
            return (
                <>
                    {this.renderCircle()}
                </>
            )
    }

    render() {
        return (
            <>
                {this.chartType()}
            </>
        )
    }

}

export default VerbChart;