import HttpHandler from './APIServices';
import { EventAPI } from './API';
import { roleFilter } from './queryFilter';

class EventService {
    private api: EventAPI;

    constructor() {
        this.api = new EventAPI()
    }

    getEventList = async (date: string, org: string, agent: string, verb: string, activity: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventList}/${date}/${org}?verbs=${verb}&agents=${agent}&activities=${activity}`;
        url = await roleFilter(url + '&', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getEventTextAgent = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventTextAgent}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId);
        let data = await HttpHandler(url);
        return data;
    }

    getEventTextVerb = async (date: string, org: string, filter:string, role:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventTextVerb}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getEventTextAct = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventTextAct}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getEventCount = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventCount}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getEventUser = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventUser}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getEventByDate = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventByDate}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getEventFinishCourseByDate = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventFinishCourseByDate}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getEventByFacet = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getFacet}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getEventByMostUser = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventByMostUser}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getEventByMostVerb = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventByMostVerb}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getEventByMostActivities = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventByMostActivities}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getEventByMostActType = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventByMostActType}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getEventCourseByMost = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventCourseByMost}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getEventByUser = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventByUser}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

    getEventByDailyVerb = async (date: string, org: string, filter:string, role:string, courseId?: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getEventByDailyVerb}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }
}

export default EventService;