import React from 'react';
import { VictoryLine, VictoryChart, VictoryAxis, DataGetterPropType, VictoryLabel, VictoryBar, VictoryTooltip, VictoryVoronoiContainer, VictoryStack, VictoryLegend } from 'victory';
import Loading from 'react-loading';

interface ACProps {
    chartType?: string;
    tickValues?: any[];
    tickFormat?: any;
    x?: DataGetterPropType;
    y?: DataGetterPropType;
    labelx?: string;
    labely?: string;
    data?: any[];
    data2?: any[] | undefined;
    datum?: any;
    datamFunc?: any;
    domain?: any;
    angle?: number;
    dataStack?: any[] | undefined;
}

interface ACState { }

class ActorChart extends React.Component<ACProps, ACState>{
    renderLine() {
        return (
            <VictoryChart
                height={400}
                width={800}
                //theme={VictoryTheme.material}
                containerComponent={
                    <VictoryVoronoiContainer
                        labels={this.props.datamFunc}
                        labelComponent={
                            <VictoryTooltip
                                cornerRadius={10}
                                flyoutWidth={90}
                                flyoutStyle={{
                                    stroke: "none",
                                    fill: "black"
                                }}
                                style={{ fill: "white", fontSize: 8 }}
                            />
                        }
                    />
                }
                domain={this.props.domain}
            >
                <linearGradient id="lineGradient"
                    x1="0%"
                    x2="100%"
                    y1="0%"
                    y2="100%">
                    <stop offset="0%" stopColor="#00FFCC" />
                    <stop offset="50%" stopColor="#77FFCC" />
                    <stop offset="100%" stopColor="#99FF99" />
                </linearGradient>
                <VictoryLabel x={20} y={24}
                    text={this.props.labely}
                    style={{ fontSize: "10px" }}
                />
                <VictoryLabel x={750} y={380}
                    text={this.props.labelx}
                    style={{ fontSize: "10px" }}
                />
                <VictoryAxis
                    tickLabelComponent={<VictoryLabel />}
                    style={{
                        tickLabels: {
                            fontSize: 10
                        }
                    }}
                    tickValues={this.props.tickValues}
                    tickFormat={this.props.tickFormat}
                />
                <VictoryAxis
                    dependentAxis
                    style={{
                        tickLabels: {
                            fontSize: 10
                        }
                    }}
                />
                <VictoryLine
                    data={this.props.data}
                    x={this.props.x}
                    y={this.props.y}
                    style={{
                        data: {
                            stroke: 'url(#lineGradient)',
                            strokeWidth: 3.0
                        }
                    }}
                />
            </VictoryChart>
        )
    }

    renderDoubleLine() {
        return (
            <VictoryChart
                //theme={VictoryTheme.material}
                containerComponent={
                    <VictoryVoronoiContainer
                        labels={this.props.datamFunc}
                        labelComponent={
                            <VictoryTooltip
                                cornerRadius={10}
                                flyoutWidth={90}
                                flyoutStyle={{
                                    stroke: "none",
                                    fill: "black"
                                }}
                                style={{ fill: "white", fontSize: 10 }}
                            />
                        }
                    />
                }
                domain={this.props.domain}
            >
                <VictoryLegend x={370} y={10}
                    orientation="vertical"
                    //itemsPerRow={7}
                    gutter={50}
                    style={{ border: { stroke: "black" }, labels: { fontSize: 12 } }}
                    colorScale={["url(#lineGradient)", "black"]}
                    data={[ { name: "個人" }, { name: "平均" }]}
                />
                <linearGradient id="lineGradient"
                    x1="0%"
                    x2="100%"
                    y1="0%"
                    y2="100%">
                    <stop offset="0%" stopColor="#00FFCC" />
                    <stop offset="50%" stopColor="#77FFCC" />
                    <stop offset="100%" stopColor="#99FF99" />
                </linearGradient>
                <VictoryLabel x={20} y={24}
                    text={this.props.labely}
                    style={{ fontSize: "10px" }}
                />
                <VictoryLabel x={400} y={280}
                    text={this.props.labelx}
                    style={{ fontSize: "10px" }}
                />
                <VictoryAxis
                    style={{
                        tickLabels: {
                            fontSize: 10
                        }
                    }}
                    tickValues={this.props.tickValues}
                    tickFormat={this.props.tickFormat}
                />
                <VictoryAxis
                    dependentAxis
                    style={{
                        tickLabels: {
                            fontSize: 10
                        }
                    }}
                />
                <VictoryLine
                    data={this.props.data}
                    x={this.props.x}
                    y={this.props.y}
                    style={{
                        data: {
                            stroke: 'url(#lineGradient)',
                            strokeWidth: 5.0
                        }
                    }}
                />
                <VictoryLine
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    style={{
                        data: {
                            stroke: 'black',
                            strokeWidth: 5.0
                        }
                    }}
                />
            </VictoryChart>
        )
    }

    renderStack() {

        let data: any = this.props.dataStack
        const getBarData = () => {
            if (data !== undefined && data.length > 0) {
                let bar = data.map((data: any, index: any) => {
                    return <VictoryBar key={index} data={data} />
                })
                return bar;
            }
        }

        return (
            <>
                <VictoryChart
                    //theme={VictoryTheme.material}
                    domain={this.props.domain}
                    domainPadding={{ x: 10 }}
                    containerComponent={
                        <VictoryVoronoiContainer
                            labels={this.props.datamFunc}
                            labelComponent={
                                <VictoryTooltip
                                    cornerRadius={10}
                                    flyoutWidth={90}
                                    flyoutStyle={{
                                        stroke: "none",
                                        fill: "black"
                                    }}
                                    style={{ fill: "white", fontSize: 10 }}
                                />
                            }
                        />
                    }>
                    <VictoryLabel x={20} y={24}
                        text={this.props.labely}
                        style={{ fontSize: "10px" }}
                    />
                    <VictoryLabel x={400} y={280}
                        text={this.props.labelx}
                        style={{
                            fontSize: "10px",
                        }}
                    />
                    <VictoryAxis
                        // tickLabelComponent={<VictoryLabel angle={43} />}
                        style={{
                            tickLabels: {
                                fontSize: 10,

                            },
                            axisLabel: {

                            }
                        }}
                        tickValues={this.props.tickValues}
                        tickFormat={this.props.tickFormat}
                    />
                    <VictoryAxis
                        dependentAxis
                        style={{
                            tickLabels: {
                                fontSize: 10
                            }
                        }}
                    />
                    <VictoryStack colorScale={"green"}>
                        {getBarData()}
                    </VictoryStack>

                </VictoryChart>
            </>
        )
    }

    renderBar() {
        return (
            <>
                <VictoryChart
                    //theme={VictoryTheme.material}
                    domain={this.props.domain}
                    domainPadding={{ x: 10 }}
                    containerComponent={
                        <VictoryVoronoiContainer
                            labels={this.props.datamFunc}
                            labelComponent={
                                <VictoryTooltip
                                    cornerRadius={10}
                                    flyoutWidth={90}
                                    flyoutStyle={{
                                        stroke: "none",
                                        fill: "black"
                                    }}
                                    style={{ fill: "white", fontSize: 10 }}
                                />
                            }
                        />
                    }>
                    <linearGradient id="barGradient"
                        x1="0%"
                        x2="0%"
                        y1="0%"
                        y2="100%">
                        <stop offset="0%" stopColor="#00FFCC" />
                        <stop offset="50%" stopColor="#77FFCC" />
                        <stop offset="100%" stopColor="#99FF99" />
                    </linearGradient>
                    <VictoryLabel x={20} y={24}
                        text={this.props.labely}
                        style={{ fontSize: "10px" }}
                    />
                    <VictoryLabel x={400} y={280}
                        text={this.props.labelx}
                        style={{
                            fontSize: "10px",
                        }}
                    />
                    <VictoryAxis
                        // tickLabelComponent={<VictoryLabel angle={43} />}
                        style={{
                            tickLabels: {
                                fontSize: 10,

                            },
                            axisLabel: {

                            }
                        }}
                        tickValues={this.props.tickValues}
                        tickFormat={this.props.tickFormat}
                    />
                    <VictoryAxis
                        dependentAxis
                        style={{
                            tickLabels: {
                                fontSize: 10
                            }
                        }}
                    />
                    <VictoryBar
                        barWidth={20}
                        data={this.props.data}
                        x={this.props.x}
                        y={this.props.y}
                        style={{
                            data: {
                                fill: 'url(#barGradient)',
                                fillOpacity: 0.7,
                            }
                        }}
                    />
                </VictoryChart>
            </>
        )
    }

    renderLoading() {
        return <Loading type={"cylon"} color={"black"} height={'30%'} width={'30%'}></Loading>
    }
    chartType = () => {
        if ((this.props.data !== undefined && this.props.data[0] === -1) || (this.props.data2 !== undefined && this.props.data2[0] === -1) || (this.props.dataStack !== undefined && this.props.dataStack[0] === -1))
            return (
                <>
                    {this.renderLoading()}
                </>
            )
        else if (this.props.chartType === "line")
            return (
                <>
                    {this.renderLine()}
                </>
            )
        else if (this.props.chartType === "bar")
            return (
                <>
                    {this.renderBar()}
                </>
            )
        else if (this.props.chartType === "doubleLine")
            return (
                <>
                    {this.renderDoubleLine()}
                </>
            )
        else if (this.props.chartType === "stack")
            return (
                <>
                    {this.renderStack()}
                </>
            )
    }
    render() {
        return (
            <>
                {this.chartType()}
            </>
        )
    }
}

export default ActorChart;