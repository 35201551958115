import React from 'react';
import Loading from 'react-loading';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface VWGProps {
    watchPeople: number;
    avgWatchPeople: number;
    watchTimes: number;
    avgWatchTimes: number;
    watchHours: string;
    avgWatchHours: string;
    totalWatchFinish?: number;
    avgWatchFinish?: string
}
interface VWGtate { }

class VideoWatchGrid extends React.Component<VWGProps, VWGtate>{
    renderLoading() {
        return <Loading type={"spin"} color={"#ffffff"} height={'20%'} width={'20%'}></Loading>
    }

    render() {
        return (
            <>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">總觀看人數</Popover.Header>
                                        <Popover.Body>
                                        在篩選條件下，所有影片不重複觀看學生人數的總計
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.watchPeople === -1) ? this.renderLoading() : this.props.watchPeople}</h2>
                        <h5>總觀看人數</h5>
                    </div>
                </div>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">完成影片觀看人數</Popover.Header>
                                        <Popover.Body>
                                        在篩選條件下，所有影片不重複完成觀看學生人數的總計
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.totalWatchFinish === -1) ? this.renderLoading() : this.props.totalWatchFinish}</h2>
                        完成率<span className="fs-5 ms-2">{(this.props.avgWatchFinish === "-1") ? "" : this.props.avgWatchFinish}</span>
                        <h5>完成影片觀看人數</h5>
                    </div>
                </div>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">總觀看次數</Popover.Header>
                                        <Popover.Body>
                                        在篩選條件下，所有影片被所有學生觀看次數的總計
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.watchTimes === -1) ? this.renderLoading() : this.props.watchTimes}</h2>
                        平均<span className="fs-5 ms-2">{(this.props.avgWatchTimes === -1) ? "" : this.props.avgWatchTimes}</span>
                        <h5>總觀看次數</h5>
                    </div>
                </div>
                <div className="col-md-3 py-1">
                    <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">總觀看時數</Popover.Header>
                                        <Popover.Body>
                                        在篩選條件下，所有影片被所有學生觀看時數的總計
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.watchHours === "-1") ? this.renderLoading() : this.props.watchHours}</h2>
                        平均<span className="fs-5 ms-2">{(this.props.avgWatchHours === "-1") ? "" : this.props.avgWatchHours}</span>
                        <h5>總觀看時數</h5>
                    </div>
                </div>
            </>
        )
    }
}

export default VideoWatchGrid;