import React from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component'

interface ATProps {
    columns: IDataTableColumn[];
    data?: any
    columnName?: string;
    loading?: boolean;
}
interface ATState { }

class ActorTable extends React.Component<ATProps, ATState>{

    render() {
        return (
            <DataTable
                columns={this.props.columns}
                data={this.props.data}
                noHeader={true}
                defaultSortField={this.props.columnName}
                pagination
                progressPending={this.props.loading}
            />
        )
    }
}

export default ActorTable;