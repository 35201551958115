import React from 'react';
import AccountTable from './accountTable';
import { Link } from 'react-router-dom';
import AccountService from '../../services/accountService';
import PortalService from '../../services/portalService';

const columns =  [
    {
        name: "使用者名稱",
        sortable: true,
        width: "50%",
        cell: (row: any) => { return row.name.name }
    },
    {
        name: "帳戶",
        selector: "mail",
        sortable: true,
        width: "50%"
    }
];

interface AState {
    studentList:any[];
    selectRow: [];
    account?: string;
    name?: string;
    status?: string;
    choice?: string;
}

class NewStudent extends React.Component<{}, AState>{
    private accountService: AccountService;
    private portalService: PortalService;

    constructor(props: any) {
        super(props);
        this.accountService = new AccountService();
        this.portalService = new PortalService();
        this.state = {
            selectRow: [],
            studentList: [-1],
            account: '',
            name: '',
            status: '',
            choice: "single"
        }
    }

    componentDidMount(){
        this.getStudentAccount();
    }

    getStudentAccount = async () => {
        let studentList = await this.accountService.getStudentAccount();
        this.setState({ studentList: studentList });
    }

    handleAccount = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        this.setState({ account: e.target.value })
    }

    handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        this.setState({ name: e.target.value })
    }

    handleRowChange = (state: any) => {
        this.setState({ selectRow: state.selectedRows })
    };

    choiceOption = (e: any) => {
        this.setState({ choice: e });
    }

    validateEmail = (email: any) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    submitInfo = async () => {
        let ary = [];
        if (this.state.choice === "single") {
            let password = this.state.account?.split("@")[0] + "Visca";
            let data = {
                name: this.state.name,
                account: this.state.account,
                password: password,
                email: this.state.account,
                role: "student"
            }
            if(!this.validateEmail(data.account)){
                this.setState({ status: '帳號格式錯誤' });
                return;
            }
            if (data.account === '' || data.name === '' || data.password === '')
                this.setState({ status: '欄位不得空白' });
            else {
                ary.push(data)
                let msg = await this.portalService.register(ary);
                if (msg.success === false)
                    this.setState({ status: '帳號已存在!' });
                else {
                    this.setState({ status: '' });
                    window.location.href = '/lrs/account/student';
                }
            }
        } else {
            let row: any[] = this.state.selectRow;
            for (let i = 0; i < row.length; i++) {
                let password = row[i].mail?.split("@")[0] + "Visca";
                let data = {
                    name:  row[i].name.name,
                    account: row[i].mail,
                    password: password,
                    email: row[i].mail,
                    role: "student"
                }
                ary.push(data);
            }
            let msg = await this.portalService.register(ary);
            if (msg.success === false)
                this.setState({ status: '帳號已存在!' });
            else {
                this.setState({ status: '' });
                window.location.href = '/lrs/account/student';
            }
        }
    }


    render() {
        return (
            <>
                <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                    <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><Link to="/lrs/account/student" style={{ textDecoration: "none", color: "#000000" }}><span>帳戶設定</span></Link>/</li>
                                <li><Link to="/lrs/account/student" style={{ textDecoration: "none", color: "#000000" }}><span>學生帳戶</span></Link>/</li>
                                <li><span>新增學生帳戶</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 pt-1 pb-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="one-user-tab" data-bs-toggle="tab"
                                                data-bs-target="#one-user" type="button" role="tab" aria-controls="one-user"
                                                aria-selected="true" onClick={() => this.choiceOption("single")}>新增使用者</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="multi-user-tab" data-bs-toggle="tab"
                                                data-bs-target="#multi-user" type="button" role="tab"
                                                aria-controls="multi-user" aria-selected="false" onClick={() => this.choiceOption("multi")}>批次匯入使用者</button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="one-user" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div className="col-md-12">
                                            <div className="input-group py-3">
                                                <label className="col-lg-1 me-2">帳戶名稱</label>
                                                <input type="text" className="form-control date"
                                                    placeholder="jokic@iii.org.tw" name="account" value={this.state.account} onChange={this.handleAccount}/>
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-1 me-2">使用者名稱</label>
                                                <input type="text" className="form-control date" placeholder="金塊阿肥" name="name" value={this.state.name} onChange={this.handleName}/>
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-1 me-2">帳戶密碼</label>
                                                自動產生 email(@之前) + "Visca" <span className="text-gray ms-1">(ex: emailVisca)</span>
                                            </div>
                                            <div className="input-group py-3">
                                                <label style={{ color: "red" }}>{this.state.status}</label>
                                            </div>
                                            <div className="text-center pb-3">
                                                <Link to="/lrs/account/student"
                                                    className="genric-btn circle red-border">取消</Link>&nbsp;&nbsp;<button
                                                    className="genric-btn circle white-border" onClick={this.submitInfo}>建立</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade show" id="multi-user" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div className="col-md-12 py-3">
                                            {this.state.studentList[0] === -1 ? < AccountTable columns={columns} loading={true} /> : <AccountTable columns={columns} data={this.state.studentList} selected={true} handleChange={this.handleRowChange}/>}
                                            <br /><br />
                                            <div className="text-center pb-3">
                                                <Link to="/lrs/account/student"
                                                    className="genric-btn circle red-border">取消</Link>&nbsp;&nbsp;<button
                                                    className="genric-btn circle white-border" onClick={this.submitInfo}>建立</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default NewStudent;