import React from 'react';
import { 
    VictoryLine, 
    VictoryChart, 
    VictoryAxis, 
    DataGetterPropType, 
    VictoryLabel, 
    VictoryBar, 
    //VictoryTheme,
    VictoryTooltip, 
    VictoryVoronoiContainer, 
    VictoryPie, 
    VictoryLegend 
} from 'victory';
import Loading from 'react-loading';

interface OCProps {
    chartType?: string;
    tickValues?: any[];
    tickFormat?: any;
    x?: DataGetterPropType;
    y?: DataGetterPropType;
    labelx?: string;
    labely?: string;
    data?: any[];
    datum?: any;
    datamFunc?: any;
    domain?: any;
    angle?: number;
    name?: any[];
    height?: number;
    legendx?: number;
    legendGutter?: number;
    legendFontSize?: number;
}

interface OCState { ref: any }

class OverviewChart extends React.Component<OCProps, OCState>{
    renderLine() {
        return (
            <VictoryChart
                //theme={VictoryTheme.material}
                containerComponent={
                    <VictoryVoronoiContainer
                        labels={this.props.datamFunc}
                        labelComponent={
                            <VictoryTooltip
                                cornerRadius={10}
                                flyoutWidth={90}
                                flyoutStyle={{
                                    stroke: "none",
                                    fill: "black"
                                }}
                                style={{ fill: "white", fontSize: 10 }}
                            />

                        }

                    />
                }
                domain={this.props.domain}
            >
                <linearGradient id="lineGradient"
                    x1="0%"
                    x2="100%"
                    y1="0%"
                    y2="100%">
                    <stop offset="0%" stopColor="#00FFCC" />
                    <stop offset="50%" stopColor="#77FFCC" />
                    <stop offset="100%" stopColor="#99FF99" />
                </linearGradient>
                <VictoryLabel x={20} y={24}
                    text={this.props.labely}
                    style={{ fontSize: "10px" }}
                />
                <VictoryLabel x={400} y={280}
                    text={this.props.labelx}
                    style={{ fontSize: "10px" }}
                />
                <VictoryAxis
                    style={{
                        tickLabels: {
                            fontSize: 10
                        }
                    }}
                    tickValues={this.props.tickValues}
                    tickFormat={this.props.tickFormat}
                />
                <VictoryAxis
                    dependentAxis
                    style={{
                        tickLabels: {
                            fontSize: 10
                        }
                    }}
                />
                <VictoryLine
                    data={this.props.data}
                    x={this.props.x}
                    y={this.props.y}
                    style={{
                        data: {
                            stroke: 'url(#lineGradient)',
                            strokeWidth: 5.0
                        }
                    }}
                />
            </VictoryChart>
        )
    }
    renderBar() {
        return (
            <>
                <VictoryChart
                    //theme={VictoryTheme.material}
                    domain={this.props.domain}
                    domainPadding={{ x: 10 }}
                    containerComponent={
                        <VictoryVoronoiContainer
                            labels={this.props.datamFunc}
                            labelComponent={
                                <VictoryTooltip
                                    cornerRadius={10}
                                    flyoutWidth={90}
                                    flyoutStyle={{
                                        stroke: "none",
                                        fill: "black"
                                    }}
                                    style={{ fill: "white", fontSize: 10 }}
                                />
                            }
                        />
                    }>
                    <svg>
                        <defs>
                            <linearGradient id="myGradient"
                                x1="0%"
                                x2="0%"
                                y1="0%"
                                y2="100%">
                                <stop offset="0%" stopColor="#00FFCC" />
                                <stop offset="50%" stopColor="#77FFCC" />
                                <stop offset="100%" stopColor="#99FF99" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <VictoryLabel x={20} y={24}
                        text={this.props.labely}
                        style={{ fontSize: "10px" }}
                    />
                    <VictoryLabel x={400} y={280}
                        text={this.props.labelx}
                        style={{
                            fontSize: "10px",
                        }}
                    />
                    <VictoryAxis
                        tickLabelComponent={<VictoryLabel angle={43} />}
                        style={{
                            tickLabels: {
                                fontSize: 10,

                            },
                            axisLabel: {

                            }
                        }}
                        tickValues={this.props.tickValues}
                        tickFormat={this.props.tickFormat}
                    />
                    <VictoryAxis
                        dependentAxis
                        style={{
                            tickLabels: {
                                fontSize: 10
                            }
                        }}
                    />
                    <VictoryBar
                        barWidth={20}
                        data={this.props.data}
                        x={this.props.x}
                        y={this.props.y}
                        style={{
                            data: {
                                fill: 'url(#myGradient)',
                                fillOpacity: 0.7,
                            }
                        }}
                    />
                </VictoryChart>
            </>
        )
    }

    renderCircle() {
        return (
            <VictoryChart 
                padding={{ left: -170, top: 10, bottom: 10 }}
                height={this.props.height? this.props.height:300}
            >
                <VictoryLegend 
                    x={this.props.legendx? this.props.legendx:300} 
                    y={10}
                    orientation="vertical"
                    //itemsPerRow={2}
                    gutter={this.props.legendGutter? this.props.legendGutter:55}
                    style={{ labels: { fontSize: this.props.legendFontSize? this.props.legendFontSize:12 } }}
                    colorScale={["#BBFFEE", "#77FFCC", "#33FFAA", "#00FF99", "#00FFCC", "#00DDAA", "#00AA88", "#008866"]}
                    data={this.props.name}
                />
                <VictoryPie
                    labelComponent={
                        <VictoryTooltip
                            cornerRadius={5}
                            flyoutWidth={200}
                            flyoutPadding={5}
                            flyoutStyle={{
                                stroke: "none",
                                fill: "black"
                            }}
                            style={{ fill: "white", fontSize: 10 }}
                        />
                    }
                    style={{
                        data: {
                            fillOpacity: 0.9, stroke: "#FFFFFF", strokeWidth: 1
                        },
                        // labels: {
                        // fontSize: 6
                        //  }
                    }}
                    colorScale={["#BBFFEE", "#77FFCC", "#33FFAA", "#00FF99", "#00FFCC", "#00DDAA", "#00AA88", "#008866"]}
                    x={this.props.x}
                    y={this.props.y}
                    labels={this.props.datum}
                    data={this.props.data}
                />
                <VictoryAxis style={{
                    axis: { stroke: "transparent" },
                    ticks: { stroke: "transparent" },
                    tickLabels: { fill: "transparent" }
                }} />
            </VictoryChart>
        )
    }

    renderLoading() {
        return <Loading type={"cylon"} color={"black"} height={'30%'} width={'30%'}></Loading>
    }

    chartType = () => {
        if (this.props.data !== undefined && this.props.data[0] === -1) {
            return (
                <>
                    {this.renderLoading()}
                </>
            )
        }
        else if (this.props.chartType === "line")
            return (
                <>
                    {this.renderLine()}
                </>
            )
        else if (this.props.chartType === "bar")
            return (
                <>
                    {this.renderBar()}
                </>
            )
        else if (this.props.chartType === "circle")
            return (
                <>
                    {this.renderCircle()}
                </>
            )
    }
    render() {
        return (
            <>
                {this.chartType()}
            </>
        )
    }
}

export default OverviewChart;