import React from 'react';
import { Link } from 'react-router-dom';
import config from '../config';

interface NProps{}
interface NState{}

class HomeNavBar extends React.Component<NProps, NState>{

    render(){
        return (
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <Link to="/" style={{ textDecoration: "none", color: "#FFFFFF" }}>
                        {/* <img src="/img/visca-logo.png" width="150" alt="VisCa" /> */}
                        <p>{config.SITE_NAME}</p>
                    </Link>
                    {/* <Link to="/">
                        <img src="/img/chihleeCombineB.png" width="150" alt="VisCa" />
                    </Link> */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#home-about">關於 Visca</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#home-service">服務說明</a>
                            </li>
                            {/*
                            <li className="nav-item">
                                <a className="nav-link" href="#feature">功能特色</a>
                            </li>
                            */}
                            <li className="nav-item">
                                <a className="nav-link" href="#home-contact">聯絡我們</a>
                            </li>
                            <li className="nav-item">
                                <Link to="/login" className="genric-btn red-border circle ms-2">立即體驗</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }

}

export default HomeNavBar;