import React from 'react';
import { VictoryLine, VictoryChart, VictoryAxis, DataGetterPropType, VictoryLabel, VictoryVoronoiContainer, VictoryTooltip } from 'victory';
import Loading from 'react-loading';

interface VCProps {
    tickValues?: any[];
    tickFormat?: any;
    x?: DataGetterPropType;
    y?: DataGetterPropType;
    labelx?: string;
    labely?: string;
    data?: any[];
    datamFunc?: any;
    domain?: any;
}

interface VCState { }

class VideoChart extends React.Component<VCProps, VCState>{

    renderLoading() {
        return <Loading type={"cylon"} color={"black"} height={'30%'} width={'30%'}></Loading>
    }

    renderChart() {
        return (
            <VictoryChart
                height={200}
                width={400}
                domainPadding={{ y: 30 }}
                containerComponent={
                    <VictoryVoronoiContainer
                        labels={this.props.datamFunc}
                        labelComponent={
                            <VictoryTooltip
                                cornerRadius={10}
                                flyoutWidth={50}
                                flyoutStyle={{
                                    stroke: "none",
                                    fill: "black"
                                }}
                                style={{ fill: "white", fontSize: 6 }} />
                        }
                    />
                }
            >
                <VictoryLabel x={20} y={24}
                    text={this.props.labely}
                    style={{ fontSize: "6px" }}
                />
                <VictoryLabel x={400} y={280}
                    text={this.props.labelx}
                    style={{ fontSize: "6px" }}
                />
                <VictoryAxis
                    style={{
                        tickLabels: {
                            fontSize: 6
                        }
                    }}
                    tickValues={this.props.tickValues}
                    tickFormat={this.props.tickFormat}
                />
                <VictoryAxis
                    dependentAxis
                    domain={this.props.domain}
                    style={{
                        tickLabels: {
                            fontSize: 6
                        }
                    }}
                />
                <VictoryLine
                    //interpolation="natural"
                    data={this.props.data}
                    x={this.props.x}
                    y={this.props.y}
                    style={{
                        data: {
                            stroke: "#0080FF",
                            strokeWidth: 1
                        }
                    }}
                />
            </VictoryChart>
        )
    }

    render() {
        if (this.props.data !== undefined && this.props.data[0] === -1) {
            return (
                <>
                    {this.renderLoading()}
                </>
            )
        }
        else
            return (
                <>
                    {this.renderChart()}
                </>
            )
    }
}

export default VideoChart;