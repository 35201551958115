import React from 'react';
import { Link } from 'react-router-dom';
import VideoWatchGrid from './videoWatchGrid';
import VideoChart from './videochart';
import VideoTable from './videoTables';
import memoize from 'memoize-one';
import VideoService from '../../services/videoService';
import CourseService from '../../services/courseService';
import moment from 'moment';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import LinkBtn from '../opionBtn/exportLink';
import { LoginContext } from '../../loginContext';
import Select from 'react-select';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';

const columns = memoize((handleAction) => [
    {
        name: "影片名稱",
        selector: "name",
        sortable: true,
        width: "30%",
        wrap: true
    },
    {
        name: "課程名稱",
        selector: "courseName",
        sortable: true,
        width: "10%",
        wrap: true
    },
    {
        name: "長度",
        selector: "durarion",
        sortable: true,
        width: "12%",
        center: true
    },
    {
        name: "觀看次數",
        selector: "totalWatchTimes",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "平均觀看時間長度",
        selector: "avgWatchHour",
        sortable: true,
        width: "18%",
        center: true
    },
    {
        name: "觀看人次",
        selector: "TotalwatchPeople",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "詳細內容",
        cell: (row: any) => <button className="genric-btn white-border" onClick={() => { handleAction(row) }}>檢視</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: "10%",
        center: true
    }
]);

interface VLState {
    courseList: any[];
    courseId: string;
    videoList: any[];
    watchPeople: {
        totalWatchPeople: number;
        avgWatchPeople: number;
    }
    watchTimesAndHours: {
        totalWatchTimes: number;
        avgWatchTimes: number;
        totalWatchHours: string;
        avgWatchHours: string;
        totalWatchFinish: number;
        avgWatchFinish: string;
    };
    timesAry: any[];
    hourAry: any[];
    sdate: string;
    edate: string;
    sDateDisabled: boolean;
    eDateDisabled: boolean;
    periodDate: string;
    anayType: string;
    typeText: string;
}

class VideoList extends React.Component<{}, VLState>{
    private courseService: CourseService;
    private videoService: VideoService;
    private sdate: string;
    private edate: string;
    private org: string;
    private account: string;
    private role: string;
    private date: string;
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        this.courseService = new CourseService();
        this.videoService = new VideoService();
        this.org = "";
        this.account = "";
        this.role = "";
        this.state = {
            courseList: [-1],
            courseId: '',
            videoList: [-1],
            watchPeople: {
                totalWatchPeople: -1,
                avgWatchPeople: -1
            },
            watchTimesAndHours: {
                totalWatchTimes: -1,
                avgWatchTimes: -1,
                totalWatchHours: "-1",
                avgWatchHours: "-1",
                totalWatchFinish: -1,
                avgWatchFinish: "-1"
            },
            timesAry: [-1],
            hourAry: [-1],
            sdate: '2021-08-01',//moment().subtract(14, 'day').format('YYYY-MM-DD'),
            edate: '2022-01-31',//moment().format('YYYY-MM-DD')
            sDateDisabled: true,
            eDateDisabled: true,
            periodDate: '2021-08-01/2022-01-31',
            anayType: "times",
            typeText: "觀看次數"
        }
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
        this.date = ""
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
        this.getFullCourses(this.org);
        this.getVideoList(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getTotalWatchPeople(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getWatchTimesAndHours(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getVideoByDay(this.date, this.org, this.account, this.role, this.state.courseId);
    }

    resetState = () => {
        this.setState({
            courseId: '',
            videoList: [-1],
            watchPeople: {
                totalWatchPeople: -1,
                avgWatchPeople: -1
            },
            watchTimesAndHours: {
                totalWatchTimes: -1,
                avgWatchTimes: -1,
                totalWatchHours: "-1",
                avgWatchHours: "-1",
                totalWatchFinish: -1,
                avgWatchFinish: "-1"
            },
            timesAry: [-1],
            hourAry: [-1]
        })
    }

    getFullCourses = async (org: string) => {
        let fullCourses: any = await this.courseService.getFullCourses(org);
        let courseSelectValues = fullCourses.fullCourseList.map((course: any) => {
            return {
                label: course.name,
                value: course.courseId
            }
        });
        this.setState({ courseList: courseSelectValues });
    }

    getVideoList = async (sdate: string, org: string, filter: string, role: string, courseId: string) => {
        let videoList = await this.videoService.getVideoList(sdate, org, filter, role, courseId);
        this.setState({ videoList: videoList });
    }

    getVideoByDay = async (sdate: string, org: string, filter: string, role: string, courseId: string) => {
        let videoList = await this.videoService.getVideosByDay(sdate, org, filter, role, courseId);
        let [timesAry, hoursAry] = this.settingDailyData(videoList)
        this.setState({ timesAry: timesAry, hourAry: hoursAry });
    }

    getTotalWatchPeople = async (sdate: string, org: string, filter: string, role: string, courseId: string) => {
        let watchPeople = await this.videoService.getTotalWatchPeople(sdate, org, filter, role, courseId);
        watchPeople.avgWatchPeople = (watchPeople.avgWatchPeople).toFixed(2);
        this.setState({ watchPeople: watchPeople });
    }

    getWatchTimesAndHours = async (sdate: string, org: string, filter: string, role: string, courseId: string) => {
        let watchTimesAndHours = await this.videoService.getWatchTimesAndHours(sdate, org, filter, role, courseId);
        watchTimesAndHours.avgWatchTimes = (watchTimesAndHours.avgWatchTimes).toFixed(2);
        this.setState({ watchTimesAndHours: watchTimesAndHours });
    }

    viewContent = (data: any) => {
        let sdate = this.state.sdate;
        let edate = this.state.edate;
        let id = encodeURIComponent(data._id);
        window.location.href = `/lrs/video/content?id=${id}&sdate=${sdate}&edate=${edate}`
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    changeAnalysis = (type: any, text: string) => {
        this.setState({ typeText: text })
        this.setState({ anayType: type });
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any[]) => {
        let timesAry = [], hoursAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false;
            let index = 0;
            let hourData = {
                x: dateAry[i],
                y: 0
            }
            let timesData = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }
            if (hasValue) {
                hourData.y = Math.floor(ary[index].watchHours / 3600);
                timesData.y = Math.floor(ary[index].watchTimes)
                timesAry.push(timesData);
                hoursAry.push(hourData);
            }
            else {
                timesAry.push(timesData);
                hoursAry.push(hourData);
            }
        }
        return [timesAry, hoursAry];
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    analysisType = () => {
        if (this.state.anayType === "times") {
            return (
                <VideoChart
                    tickFormat={this.tickFormat}
                    labelx={"日期"}
                    labely={"次數"}
                    data={this.state.timesAry}
                    datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nwatch ${datum.y} times`}
                />
            )
        } else {
            return (
                <VideoChart
                    tickFormat={this.tickFormat}
                    labelx={"日期"}
                    labely={"小時"}
                    data={this.state.hourAry}
                    datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nwatch ${datum.y} hours`}
                />
            )
        }
    }

    refresh = () => {
        let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
        this.setState({ timesAry: [-1], hourAry: [-1] });
        this.getVideoByDay(date, this.org, this.account, this.role, this.state.courseId);
    }

    roleType = () => {
        if (this.role === "admin" || this.role === "owner")
            return true;
        else
            return false;
    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 200) {
            alert("日期區間請在六個月內")
        } else {
            this.resetState();
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
            this.getVideoList(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getTotalWatchPeople(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getWatchTimesAndHours(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getVideoByDay(this.date, this.org, this.account, this.role, this.state.courseId);
        }
    }

    handleCourseChange = (e: any) => {
        if (e === null)
            this.setState({ courseId: '' })
        else
            this.setState({ courseId: e.value })
    }

    periodDateHandler = (e: any) => {
        const targetPeriod = e.target.value;
        if(targetPeriod){
            const periodStartDate = targetPeriod.split('/')[0];
            const periodEndDate = targetPeriod.split('/')[1];
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: true,
                eDateDisabled: true,
                sdate: periodStartDate,
                edate: periodEndDate
            });
        }else{
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: false,
                eDateDisabled: false
            });
        }
    }

    render() {
        return (
            <>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >學習內容分析</span></Link> \</li>
                                <li><span>影片學習分析</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 py-1">
                            <h5>課程</h5>
                            <Select options={this.state.courseList} onChange={this.handleCourseChange} isClearable={true} />
                        </div>
                        <div className="col-md-3 py-1" style={{marginLeft: '0.5em'}}>
                            <h5>學期</h5>
                            <Form.Select value={this.state.periodDate} onChange={this.periodDateHandler}>
                                <option value="">自訂</option>
                                <option value="2022-02-01/2022-07-31">110-第二學期</option>
                                <option value="2021-08-01/2022-01-31">110-第一學期</option>
                                <option value="2021-02-01/2021-07-31">109-第二學期</option>
                                <option value="2020-08-01/2021-01-31">109-第一學期</option>
                            </Form.Select>
                        </div>
                        <div className="col-md-5 py-1">
                            <h5>時間</h5>
                            <div className="row">
                                <div className="col-auto">
                                    <div className="input-group">
                                        <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} disabled={this.state.sDateDisabled} />
                                    </div>
                                </div>
                                <div className="col-auto py-2 m-none lh-lg">~</div>
                                <div className="col-auto">
                                    <div className="input-group">
                                        <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} disabled={this.state.eDateDisabled} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 py-1">
                            <h5>動作</h5>
                            <button className="btn btn-secondary" onClick={this.submitDateRange}>篩選</button>
                        </div>
                    </div>
                    <div className="row">
                        <VideoWatchGrid
                            watchPeople={this.state.watchPeople.totalWatchPeople}
                            avgWatchPeople={this.state.watchPeople.avgWatchPeople}
                            watchTimes={this.state.watchTimesAndHours.totalWatchTimes}
                            avgWatchTimes={this.state.watchTimesAndHours.avgWatchTimes}
                            watchHours={this.state.watchTimesAndHours.totalWatchHours}
                            avgWatchHours={this.state.watchTimesAndHours.avgWatchHours}
                            totalWatchFinish={this.state.watchTimesAndHours.totalWatchFinish}
                            avgWatchFinish={this.state.watchTimesAndHours.avgWatchFinish}
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                                data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                                aria-selected="true" onClick={() => this.changeAnalysis("times", "觀看次數")}>觀看次數</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                                data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                                aria-selected="false" onClick={() => this.changeAnalysis("hours", "觀看時數")}>觀看時數</button>
                                        </li>
                                    </ul>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                            <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                <Popover>
                                                    <Popover.Header as="h3"></Popover.Header>
                                                    <Popover.Body>
                                                    在篩選條件下，每一天所有影片被所有學生觀看的次數與時數總計
                                                    </Popover.Body>
                                                </Popover>
                                            )}>
                                                <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="input-group">
                                            <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                                data-bs-toggle="dropdown" aria-expanded="false">更多
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li><LinkBtn link={(this.roleType()) ? `/embed/video/${this.state.anayType}/${this.date}/${this.org}` : `/embed/video/${this.state.anayType}/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li>                  
                                                <li><button className="dropdown-item" onClick={this.refresh}>重新整理</button></li>
                                                <li><CSVBtn data={(this.state.anayType === "times") ? this.state.timesAry : this.state.hourAry} /></li>
                                                <li><ImgBtn id={0} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {this.analysisType()}
                            </div>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel"
                                    aria-labelledby="home-tab">
                                    {this.state.videoList[0] === -1 ? < VideoTable columns={columns(this.viewContent)} loading={true} /> : < VideoTable columns={columns(this.viewContent)} data={this.state.videoList} columnName={"name"} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default VideoList;