import moment from 'moment';
import React from 'react';
import DiscussionChart from './discussionChart';
import DiscussionService from '../../services/discussionService';
import Loading from 'react-loading';

interface FProps { }
interface FState {
    discussList: any[];
    mostList: any[];
    courseList: any[];
    userList: any[];
    mostLegend: any[];
    courseLegend: any[];
    userLegend: any[];
}

class DiscussionEmbed extends React.Component<FProps, FState>{
    private discussionService: DiscussionService;
    private sdate: string;
    private edate: string;
    private type: string;
    private date: string;
    private org: string;
    private account: string;
    private role: string;
    private courseId: string;

    constructor(props: any) {
        super(props);
        this.discussionService = new DiscussionService();
        this.org = window.location.pathname.split("/")[5];
        this.type = window.location.pathname.split("/")[3];
        this.date = window.location.pathname.split("/")[4];
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.role = urlParams.get("role") || "";
        this.account = urlParams.get("acct") || "";
        this.courseId = urlParams.get("id") || "";
        this.state = {
            discussList: [-1],
            mostList: [-1],
            courseList: [-1],
            userList: [-1],
            courseLegend: [],
            userLegend: [],
            mostLegend: [],
        }
        this.sdate = moment(this.date.slice(0, 8)).format('YYYY-MM-DD');
        this.edate = moment(this.date.slice(8, 16)).format('YYYY-MM-DD');
    }

    componentDidMount() {
        switch (this.type) {
            case "most":
                this.getDiscussionByMost(this.date, this.org, this.account, this.role, this.courseId);
                break;
            case "event":
                this.getDiscussionByDaily(this.date, this.org, this.account, this.role, this.courseId);
                break;
            case "course":
                this.getDiscussionByCourse(this.date, this.org, this.account, this.role, this.courseId);
                break;
            case "user":
                this.getDiscussionByUser(this.date, this.org, this.account, this.role, this.courseId);
                break;
        }
    }


    getDiscussionByDaily = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let data = await this.discussionService.getDiscussionByDaily(date, org, filter, role, courseId);
        let discussList = await this.settingDailyData(data)
        this.setState({ discussList: discussList });
    }

    getDiscussionByMost = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let data = await this.discussionService.getDiscussionByMost(date, org, filter, role, courseId);
        let mostLegend = await this.processLegend(data);
        this.setState({ mostList: data, mostLegend: mostLegend });
    }

    getDiscussionByCourse = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let data = await this.discussionService.getDiscussionByCourse(date, org, filter, role, courseId);
        let courseLegend = await this.processLegend(data);
        this.setState({ courseList: data, courseLegend: courseLegend });
    }

    getDiscussionByUser = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let data = await this.discussionService.getDiscussionByUser(date, org, filter, role, courseId);
        let userLegend = await this.processLegend(data);
        this.setState({ userList: data, userLegend: userLegend });
    }

    processLegend = (ary: any[]) => {
        let nameAry = [];
        for (let i = 0; i < ary.length; i++) {
            let data = {
                name: `${Math.ceil(ary[i].total / ary.map((x: any) => x.total).reduce((a: any, c: any) => a + c) * 100)}% ${ary[i].name.slice(0, 7)}${(ary[i].name.length > 8) ? "..." : ""}`
            }
            nameAry.push(data)
        }
        return nameAry;
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any[]) => {
        let discussAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false
            let index = 0
            let discussData = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }
            if (hasValue) {
                discussData.y = Math.floor(ary[index].total)
                discussAry.push(discussData);
            }
            else {
                discussAry.push(discussData);
            }
        }
        return discussAry;
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    renderLoading() {
        return <Loading type={"spin"} color={"#ffffff"} height={'20%'} width={'20%'}></Loading>
    }

    renderType = () => {
        switch (this.type) {
            case "most":
                return (
                    <DiscussionChart
                        data={this.state.mostList}
                        name={this.state.mostLegend}
                        x={"name"}
                        y={"total"}
                        chartType={"circle"}
                        datum={({ datum }: { datum: any }) => `${(datum.name).slice(0, 9)}...\n${Math.ceil(datum.total / this.state.mostList.map(x => x.total).reduce((a, c) => a + c) * 100)}%`}
                    />
                );
            case "event":
                return (
                    <DiscussionChart
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"筆數"}
                        data={this.state.discussList}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} records`}
                        chartType={"line"}
                    />
                )
            case "course":
                return (
                    <DiscussionChart
                        data={this.state.courseList}
                        name={this.state.courseLegend}
                        x={"name"}
                        y={"total"}
                        chartType={"circle"}
                        datum={({ datum }: { datum: any }) => `${(datum.name).slice(0, 9)}...\n${Math.ceil(datum.total / this.state.courseList.map(x => x.total).reduce((a, c) => a + c) * 100)}%`}
                    />
                )
            case "user":
                return (
                    <DiscussionChart
                        data={this.state.userList}
                        name={this.state.userLegend}
                        x={"name"}
                        y={"total"}
                        chartType={"circle"}
                        datum={({ datum }: { datum: any }) => `${(datum.name).slice(0, 9)}...\n${Math.ceil(datum.total / this.state.userList.map(x => x.total).reduce((a, c) => a + c) * 100)}%`}
                    />
                )
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 pt-3">
                    {this.renderType()}
                </div>
            </div>
        );
    }
}

export default DiscussionEmbed;