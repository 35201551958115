import React from 'react';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import QuizChart from './quizChart';
import QuizTable from './quizTable';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface QCProps {
    column: any[];
    quizTable?: any[];
    quizList: any[];
    quizPass: any[];
}
interface QCtate {
    scoreCSV: any[];
    passCSV: any[];
}

class QuizCompare extends React.Component<QCProps, QCtate>{

    constructor(props: any) {
        super(props);
        this.state = {
            scoreCSV: [],
            passCSV: []
        }
    }

    async componentDidMount() {
        let scoreCSV: any = await this.processData(this.props.quizList);
        let passCSV: any = await this.processData(this.props.quizPass);
        this.setState({ scoreCSV: scoreCSV, passCSV: passCSV })
    }

    processData = async (data: any[]) => {
        let ary: any = [];
        for (let i = 0; i < data.length; i++) {
            ary = ary.concat(data[i])
        }
        return ary;
    }

    render() {
        return (
            <>
                <div className="row">
                    <QuizTable columns={this.props.column} data={this.props.quizTable} selected={false} />
                </div>
                <div className="row">
                    <div className="col-md-6 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>測驗成果趨勢比較</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">測驗成果趨勢比較</Popover.Header>
                                                <Popover.Body>
                                                    多個測驗的高標、低標、標準差與平均分數的比較        
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split "
                                            data-bs-toggle="dropdown" aria-expanded="false" >更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            {/* {<li><LinkBtn link={(this.roleType()) ? `/embed/overview/event/${this.date}/${this.org}` : `/embed/overview/event/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li> */}
                                            <li><CSVBtn data={this.state.scoreCSV} /></li>
                                            <li><ImgBtn id={0} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <QuizChart
                                labely={"分數"}
                                dataStack={this.props.quizList}
                                domain={{ y: [0, 100] }}
                                datamFunc={({ datum }: { datum: any }) => `${datum.x} \n 分數 ${datum.y}`}
                                chartType={"lineStack"}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>測驗通過率比較</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">測驗通過率比較</Popover.Header>
                                                <Popover.Body>
                                                多個測驗的通過率的比較   
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            {/* <li><LinkBtn link={(this.roleType()) ? `/embed/overview/users/${this.date}/${this.org}` : `/embed/overview/users/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li> */}
                                            <li><CSVBtn data={this.state.passCSV} /></li>
                                            <li><ImgBtn id={1} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <QuizChart
                                labely={"%"}
                                dataStack={this.props.quizPass}
                                datamFunc={({ datum }: { datum: any }) => `${datum.y}% \n(${datum.people})`}
                                chartType={"stack"}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default QuizCompare;