const StudentInfo = (studentEmail) => {
    if(studentEmail && studentEmail.indexOf('mail.chihlee.edu.tw') !== -1){
        const schoolCode = studentEmail.charAt(0); // 學制
        const yearCode = studentEmail.charAt(1) + studentEmail.charAt(2); // 學年度
        const departmentCode = studentEmail.charAt(3) + studentEmail.charAt(4);  // 科系
        let studentBack= '('+ yearCode + ')';
        switch(schoolCode){
            case '1':
                studentBack += '日四技';
                break;
            case '2':
                studentBack += '日二技';
                break;
            case '5':
                studentBack += '日五專';
                break;
            case 'G':
                studentBack += '碩士班';
                break;
            case 'T':
                studentBack += '日二專(雙軌)';
                break;
            case 'U':
                studentBack += '日二技(雙軌)';
                break;
            case 'H':
                studentBack += '日四技(產學)';
                break;
            case 'O':
                studentBack += '日四技(雙軌)';
                break;
            case 'J':
                studentBack += '四技國際產學合作班';
                break;
            case 'V':
                studentBack += '二技國際產學合作班';
                break;
            case 'P':
                studentBack += '陸生';
                break;
            case 'Q':
                studentBack += '陸生短期專班';
                break;
            case 'R':
                studentBack += '國際青年交換生';
                break;
            case 'S':
                studentBack += '國際短期留學生';
                break;
            case '6':
                studentBack += '夜四技';
                break;
            case '7':
                studentBack += '夜二技';
                break;
            case 'K':
                studentBack += '多元專長培力';
                break;
            case 'L':
                studentBack += '四技在營專班(軍)';
                break;
            case 'M':
                studentBack += '碩士在職專班';
                break;
            case 'N':
                studentBack += '四技(雙軌)';
                break;
            case 'W':
                studentBack += '二技自辦專班';
                break;
            case 'Y':
                studentBack += '四技自辦專班';
                break;
            default:
        }

        switch(departmentCode){
            case '01':
                studentBack += '-國貿系(科)';
                break;
            case '02':
                studentBack += '-企管系(科)';
                break;
            case '07':
                studentBack += '-財金系(科)';
                break;
            case '08':
                studentBack += '-應英系(科)';
                break;
            case '10':
                studentBack += '-資管系(科)';
                break;
            case '11':
                studentBack += '-會資系(科)';
                break;
            case '12':
                studentBack += '-保金系(科)';
                break;
            case '13':
                studentBack += '-行管系(科)';
                break;
            case '14':
                studentBack += '-企管系服管碩(職)班';
                break;
            case '22':
                studentBack += '應日系(科)';
                break;
            case '23':
                studentBack += '-休閒系(科)';
                break;
            case '32':
                studentBack += '-多設系(科)';
                break;
            case '33':
                studentBack += '-商管系(科)';
                break;
            case '09':
                studentBack += '-會計科';
                break;
            case '24':
                studentBack += '-財法系(科)';
                break;
            case '25':
                studentBack += '-兩岸經貿';
                break;
            case '34':
                studentBack += '-資網系(科)';
                break;
            case '35':
                studentBack += '-投資理財';
                break;
            default:
        }

        return studentBack;
    }else{
        return '';
    }
}

export default StudentInfo;