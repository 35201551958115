import HttpHandler from './APIServices';
import { DiscussionAPI } from './API';
import { roleFilter } from './queryFilter';

class DiscussionService {
    private api: DiscussionAPI;

    constructor() {
        this.api = new DiscussionAPI()
    }

    getDisscussionInfo = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getDisscussionInfo}/${date}/${org}?cid=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getDiscussionByDaily = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getDiscussionByDaily}/${date}/${org}?cid=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getDiscussionByMost = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getDiscussionByMost}/${date}/${org}?cid=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getDiscussionByCourse = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getDiscussionByCourse}/${date}/${org}?cid=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getDiscussionByUser = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getDiscussionByUser}/${date}/${org}?cid=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getDiscussListByCourse = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getDiscussListByCourse}/${date}/${org}?cid=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getCourseInDiscussion = async (date: string, org: string, filter: string, role: string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getCourseInDiscussion}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role)
        let data = await HttpHandler(url);
        return data;
    }

    getWordCloud = async (date: string, org: string, filter: string, role: string, courseId:string): Promise<any> => {
        let url = `${this.api.basePath}${this.api.getWordCloud}/${date}/${org}?cid=${encodeURIComponent(courseId)}`;
        url = await roleFilter(url + '&', filter, role)
        let data = await HttpHandler(url);
        return data;
    }
}

export default DiscussionService;