import React from 'react';
import { Link } from 'react-router-dom';
import AnalyticsSettingService from '../../services/analyticsSettingService';

interface AState {
    courseId?: string;
    courseName?: string;
    sdate?: string;
    edate?: string;
    preTest?: number;
    test?: number;
    postTest?: number;
    homework?: number;
    project?: number;
    passScore?: number;
    questionnaire?: number;
    discussion?: number;
    videoDuration?: number;
    totalDuration?: string;
    status?: string;
}

class NewCourseSetting extends React.Component<{}, AState>{

    private analyticsSettingService: AnalyticsSettingService;
    constructor(props: any) {
        super(props);
        this.analyticsSettingService = new AnalyticsSettingService();
        this.state = {
            courseId: '',
            courseName: '',
            sdate: '',
            edate: '',
            preTest: 0,
            test: 0,
            postTest: 0,
            homework: 0,
            project: 0,
            passScore: 60,
            questionnaire: 0,
            discussion: 0,
            videoDuration: 0,
            totalDuration: '',
            status: ''
        }
    }

    submitInfo = async () => {
        let data = {
            courseId: this.state.courseId,
            courseName: this.state.courseName,
            sdate: this.state.sdate,
            edate: this.state.edate,
            preTest: this.state.preTest,
            test: this.state.test,
            homework: this.state.homework,
            project: this.state.project,
            passScore: this.state.passScore,
            questionnaire: this.state.questionnaire,
            discussion: this.state.discussion,
            videoDuration: this.state.videoDuration,
            totalDuration: this.state.totalDuration
        }
        let msg = await this.analyticsSettingService.registerCourse(data);
        if (msg.success === false) {
            this.setState({ status: '課程已存在!' });
        } else {
            this.setState({ status: '' });
            window.location.href = '/lrs/analytics/setting/courses';
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        this.setState({ [e.target.name]: e.target.value }) //html tag <name="name" value="value">
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    render() {
        return (
            <>
                <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><span>分析設定</span> \</li>
                                <li><Link to="/lrs/analytics/setting/courses" style={{ textDecoration: "none", color: "#000000" }}><span>課程設定</span></Link> \</li>
                                <li><span>新增課程</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 pt-1 pb-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="one-user-tab" data-bs-toggle="tab"
                                                data-bs-target="#one-user" type="button" role="tab" aria-controls="one-user"
                                                aria-selected="true">新增課程</button>
                                        </li>
                                        {/* <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="multi-user-tab" data-bs-toggle="tab"
                                                data-bs-target="#multi-user" type="button" role="tab"
                                                aria-controls="multi-user" aria-selected="false">批次匯入課程</button>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="one-user" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div className="col-md-12">
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">課程 ID</label>
                                                <input type="text" className="form-control date"
                                                    placeholder="" name="courseId" value={this.state.courseId} onChange={this.handleChange} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">課程名稱</label>
                                                <input type="text" className="form-control date"
                                                    placeholder="" name="courseName" value={this.state.courseName} onChange={this.handleChange} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">課程開始日期</label>
                                                <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">課程結束日期</label>
                                                <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">課程問卷</label>
                                                <input type="number" className="form-control" name="questionnaire" value={this.state.questionnaire} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">課程前測</label>
                                                <input type="number" className="form-control" name="preTest" value={this.state.preTest} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">課程單元(平時)測驗</label>
                                                <input type="number" className="form-control" name="test" value={this.state.test} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">教材閱讀(影片觀看)</label>
                                                <input type="number" className="form-control" name="videoDuration" value={this.state.videoDuration} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">課程總測驗(後測)</label>
                                                <input type="number" className="form-control" name="postTest" value={this.state.postTest} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">課程討論</label>
                                                <input type="number" className="form-control" name="discussion" value={this.state.discussion} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">課程作業</label>
                                                <input type="number" className="form-control" name="homework" value={this.state.homework} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">專題</label>
                                                <input type="number" className="form-control" name="project" value={this.state.project} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">課程通過分數</label>
                                                <input type="number" className="form-control" name="passScore" value={this.state.passScore} min="0" max="100" onChange={this.handleChange} />
                                            </div>
                                            <div className="input-group py-3">
                                                <label className="col-lg-2 me-2">影片總時長</label>
                                                <input type="text" className="form-control" name="totalDuration" value={this.state.totalDuration} onChange={this.handleChange} />
                                            </div>
                                            <div className="text-center pb-3">
                                                <Link to="/lrs/analytics/setting/courses"
                                                    className="genric-btn circle red-border">取消</Link>&nbsp;&nbsp;<button
                                                        className="genric-btn circle white-border" onClick={this.submitInfo}>建立</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default NewCourseSetting;