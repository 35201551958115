import React from 'react';
import moment from 'moment';
import ActivityChart from './activityChart';
import ActivityService from '../../services/activityService'
import Loading from 'react-loading';

interface QLState {
    recentAct: any[];
    verbList: any[];
    sucessList: any[];
    actList: any[];
    actorList: any[];
}

class ActivityEmbed extends React.Component<{}, QLState>{
    private actId: string;
    private sdate: string;
    private edate: string;
    private type: string;
    private org: string;
    private account: string;
    private role: string;
    private date: string;
    private activityService: ActivityService

    constructor(props: any) {
        super(props);
        this.activityService = new ActivityService();
        this.org = window.location.pathname.split("/")[5];
        this.type = window.location.pathname.split("/")[3];
        this.date = window.location.pathname.split("/")[4];
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.role = urlParams.get("role") || "";
        this.account = urlParams.get("acct") || "";
        this.actId = decodeURIComponent(urlParams.get("id") || "")
        this.state = {
            recentAct: [-1],
            verbList: [-1],
            sucessList: [-1],
            actList: [-1],
            actorList: [-1],
        }
        this.sdate = moment(this.date.slice(0, 8)).format('YYYY-MM-DD');
        this.edate = moment(this.date.slice(8, 16)).format('YYYY-MM-DD');
    }

    componentDidMount() {
        switch (this.type) {
            case "event":
                this.getEventByDailyAct(this.date, this.actId, this.org, this.account, this.role);
                break;
            case "verb":
                this.getActByVerb(this.date, this.actId, this.org, this.account, this.role);
                break;
            case "sucess":
                this.getActBySucess(this.date, this.actId, this.org, this.account, this.role);
                break;
            case "user":
                this.getEventByDailyAct(this.date, this.actId, this.org, this.account, this.role);
                break;
        }
    }

    getEventByDailyAct = async (date: string, actId: string, org: string, filter: string, role: string): Promise<any> => {
        let list = await this.activityService.getEventByDailyAct(date, actId, org, filter, role);
        let [actList, actorList] = await this.settingDailyData(list);
        this.setState({ actList: actList, actorList: actorList });
    }

    getActByVerb = async (date: string, actId: string, org: string, filter: string, role: string): Promise<any> => {
        let verbList = await this.activityService.getActByVerb(date, actId, org, filter, role);
        this.setState({ verbList: verbList })
    }

    getActBySucess = async (date: string, actId: string, org: string, filter: string, role: string): Promise<any> => {
        let sucessList = await this.activityService.getActBySucess(date, actId, org, filter, role);
        this.setState({ sucessList: sucessList })
    }

    getRecentAct = async (date: string, actId: string, org: string, filter: string, role: string): Promise<any> => {
        let recentAct = await this.activityService.getRecentAct(date, actId, org, filter, role);
        this.setState({ recentAct: recentAct });
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any) => {
        let actAry = [];
        let actorAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false
            let index = 0
            let actData = {
                x: dateAry[i],
                y: 0
            }
            let actordata = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                    break;
                }
            }
            if (hasValue) {
                actData.y = ary[index].event;
                actordata.y = ary[index].people;
                actAry.push(actData);
                actorAry.push(actordata);
            }
            else {
                actAry.push(actData);
                actorAry.push(actordata)
            }
        }
        return [actAry, actorAry];
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    renderLoading() {
        return <Loading type={"spin"} color={"#ffffff"} height={'20%'} width={'20%'}></Loading>
    }

    renderType = () => {
        switch (this.type) {
            case "event":
                return (
                    < ActivityChart
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"筆數"}
                        data={this.state.actList}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} records`}
                        chartType={"line"}
                    />
                );
            case "user":
                return (
                    < ActivityChart
                        tickFormat={this.tickFormat}
                        data={this.state.actorList}
                        labelx={"日期"}
                        labely={"筆數"}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} students`}
                        chartType={"line"}
                    />
                )
            case "verb":
                return (
                    < ActivityChart
                        x={"name"}
                        y={"event"}
                        data={this.state.verbList}
                        datum={({ datum }: { datum: any }) => `${datum.name}\n${Math.ceil(datum.event / this.state.verbList.map(x => x.event).reduce((a, c) => a + c) * 100)}%`}
                        chartType={"circle"}
                    />
                )
            case "sucess":
                return (
                    < ActivityChart
                        x={"name"}
                        y={"total"}
                        data={this.state.sucessList}
                        chartType={"circle"}
                    />
                )
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 pt-3">
                    {this.renderType()}
                </div>
            </div>
        );
    }
}

export default ActivityEmbed;