import React from 'react';
import Loading from 'react-loading';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface CGProps {
    totalRegisterPeople?: number;
    totalFinishPeople?: number;
    avgCostTime?: string;
}

interface CGState { }

class CourseGrid extends React.Component<CGProps, CGState>{

    renderLoading() {
        return <Loading type={"spin"} color={"#ffffff"} height={'20%'} width={'20%'}></Loading>
    }

    render() {
        return (
            <>
                <div className="col-md-3 py-1">
                    <div className="p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">註冊人數</Popover.Header>
                                        <Popover.Body>
                                        在篩選條件下，所有課程的課程註冊學生人數
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.totalRegisterPeople === -1) ? this.renderLoading() : this.props.totalRegisterPeople}</h2>
                        <h5>註冊人數</h5>
                    </div>
                </div>
                <div className="col-md-3 py-1">
                    <div className="p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">完課人數</Popover.Header>
                                        <Popover.Body>
                                        在篩選條件下，所有完成課程的人數
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.totalFinishPeople === -1) ? this.renderLoading() : this.props.totalFinishPeople}</h2>
                        <h5>完課人數</h5>
                    </div>
                </div>
                <div className="col-md-3 py-1">
                    <div className="p-3 bg-dark rounded-3 text-center text-white">
                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">平均花費時間</Popover.Header>
                                        <Popover.Body>
                                        在篩選條件下，學生完成每門課程平均的花費時間
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <h2>{(this.props.avgCostTime === "-1") ? this.renderLoading() : this.props.avgCostTime}</h2>
                        <h5>平均花費時間</h5>
                    </div>
                </div>
            </>
        )
    }
}

export default CourseGrid;