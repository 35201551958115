import HttpHandler from './APIServices';
import { QuizAPI } from './API';
import { roleFilter } from './queryFilter';

class QuizService {
        private api: QuizAPI;

        constructor() {
            this.api = new QuizAPI()
        }

        getQuizs = async (sdtae: string, org: string, filter:string, role:string, courseId: string): Promise<any> => {
            let url = this.api.basePath + this.api.getQuizs + '/' + sdtae + '/' + org;
            url = await roleFilter(url + '?', filter, role, courseId)
            let data = await HttpHandler(url);
            return data;
        }

        getSingleQuiz = async (sdtae: string, quizId: string, org: string, filter:string, role:string): Promise<any> => {
            let url = `${this.api.basePath}${this.api.getSingleQuiz}/${sdtae}/${org}?quizId=${encodeURIComponent(quizId)}`;
            url = await roleFilter(url + '&', filter, role)
            let data = await HttpHandler(url);
            return data;
        }

        getSingleQuizByUser = async (sdtae: string, quizId: string, org: string, filter:string, role:string): Promise<any> => {
            let url = `${this.api.basePath}${this.api.getSingleQuizByUser}/${sdtae}/${org}?quizId=${encodeURIComponent(quizId)}`;
            url = await roleFilter(url + '&', filter, role)
            let data = await HttpHandler(url);
            return data;
        }

        getSingleQuizByQuesion = async (sdtae: string, quizId: string, org: string, filter:string, role:string): Promise<any> => {
            let url = `${this.api.basePath}${this.api.getSingleQuizByQuesion}/${sdtae}/${org}?quizId=${encodeURIComponent(quizId)}`;
            url = await roleFilter(url + '&', filter, role)
            let data = await HttpHandler(url);
            return data;
        }

        getQuizQuesionByUser = async (sdtae: string, quizId: string, org: string, filter:string, role:string): Promise<any> => {
            let url = `${this.api.basePath}${this.api.getQuizQuesionByUser}/${sdtae}/${org}?quizId=${encodeURIComponent(quizId)}`;
            url = await roleFilter(url + '&', filter, role)
            let data = await HttpHandler(url);
            return data;
        }
    }

export default QuizService;