const HttpHandler = async(url: string, option?:RequestInit):Promise<any> => {
    let data: any;
    await fetch(url, option)
        .then(res => {
            if(res.ok)
                return res.json();
            throw {success:res.ok};
        })
        .then(json => data = json)
        .catch(error => data = error)
    return data;
}

export default HttpHandler;
