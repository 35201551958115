import moment from 'moment';
import React from 'react';
import BehaviorTable from './behaviorTable';
import EventService from '../../services/eventService';
import CourseService from '../../services/courseService';
import Select from 'react-select'
import { Link } from 'react-router-dom';
import { LoginContext } from '../../loginContext';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';

interface BProps { }
interface BState {
    courseList: any[];
    courseId: string;
    eventList: any[];
    eventTextAgent: any[];
    eventTextVerb: any[];
    eventTextAct: any[];
    sdate: string;
    edate: string;
    agent: string;
    verb: string;
    activity: string;
    sDateDisabled: boolean;
    eDateDisabled: boolean;
    actSelectDisabled: boolean;
    periodDate: string;
}

const ExpandableComponent = ({ data }: { data: any }) => {
    return (
        <div className="card">
            <div className="card-body">
                <pre>
                    {JSON.stringify(data, undefined, 4)}
                </pre>
            </div>
        </div>
    )
};

const columns = [
    {
        name: "",
        selector: "statement",
        width: "100%",
        cell: (row: any) => {
            return (
                <p><strong>{row.statement.actor.name}</strong> {row.statement.verb.display['zh-TW']} {(row.statement.object.definition?.name !== undefined) ? row.statement.object.definition.name['zh-TW'] : row.statement.object.id} <span className="text-primary">{(row.statement.result?.score !== undefined) ? `成績${row.statement.result.score.raw}` : ""}{(row.statement.result?.duration !== undefined) ? ` 花費了${moment().startOf('day').second(moment.duration(row.statement.result.duration).asSeconds()).format('HH:mm:ss')}` : ""} </span> {moment(row.statement.timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</p>
            )
        }

    }
];

const defaultVerbList = [
    {
        label: '回覆',
        value: 'http://id.tincanapi.com/verb/replied'
    },
    {
        label: '繳交',
        value: 'http://activitystrea.ms/schema/1.0/submit'
    },
    {
        label: '參加課程',
        value: 'http://activitystrea.ms/schema/1.0/attend'
    },
    {
        label: '觀看影片',
        value: 'https://w3id.org/xapi/acrossx/verbs/watched'
    },
    {
        label: '貼文',
        value: 'http://id.tincanapi.com/verb/posted'
    },
    {
        label: '互動',
        value: 'http://xapi.iiiedu.org.tw/verbs/Interacted'
    },
    {
        label: '進入測驗',
        value: 'http://adlnet.gov/expapi/verbs/attempted'
    },
    {
        label: '評論貼文',
        value: 'http://adlnet.gov/expapi/verbs/commented'
    },
    {
        label: '跳過觀看影片',
        value: 'https://w3id.org/xapi/acrossx/verbs/skipped'
    },
    {
        label: '閱讀',
        value: 'http://activitystrea.ms/schema/1.0/read'
    },
    {
        label: '回答',
        value: 'http://adlnet.gov/expapi/verbs/answered'
    },
    {
        label: '存取節點',
        value: 'http://activitystrea.ms/schema/1.0/access'
    },
    {
        label: '註冊',
        value: 'http://adlnet.gov/expapi/verbs/registered'
    },
    {
        label: '批改',
        value: 'http://adlnet.gov/expapi/verbs/scored'
    },
    {
        label: '完成',
        value: 'http://adlnet.gov/expapi/verbs/completed'
    }
];

class Behavior extends React.Component<BProps, BState>{
    private eventService: EventService;
    private courseService: CourseService;
    private sdate: string;
    private edate: string;
    private org: string;
    private account: string;
    private role: string;
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        this.eventService = new EventService();
        this.courseService = new CourseService();
        this.org = "";
        this.account = "";
        this.role = "";
        this.state = {
            courseList: [-1],
            courseId: '',
            eventList: [-1],
            eventTextAgent: [{label: '讀取資料中', value: '-1'}],
            eventTextAct: [{label: '讀取資料中', value: '-1'}],
            eventTextVerb: [-1],
            agent: "",
            verb: "",
            activity: "",
            sdate: '2021-08-01',//moment().subtract(14, 'day').format('YYYY-MM-DD'),
            edate: '2022-01-31',//moment().format('YYYY-MM-DD')
            sDateDisabled: true,
            eDateDisabled: true,
            periodDate: '2021-08-01/2022-01-31',
            actSelectDisabled: true
        }
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
        this.getFullCourses(this.org);
        //this.getAllCoursefinish(this.org, this.account, this.role);
        this.getEventList(date, this.org, this.account, this.role, this.state.agent, this.state.verb, this.state.activity, this.state.courseId);
        this.getEventTextAgent(date, this.org, this.account, this.role);
        this.getEventTextAct(date, this.org, this.account, this.role);
        //this.getEventTextVerb(date, this.org, this.account, this.role);
    }

    resetState = () => {
        this.setState({
            eventList: [-1],
            eventTextAct: [{label: '讀取資料中', value: '-1'}],
            eventTextAgent: [{label: '讀取資料中', value: '-1'}]
        });
    }

    getAllCoursefinish = async (org: string, filter: string, role: string) => {
        let courseInfo: any = await this.courseService.getAllCoursefinish(org, filter, role);
        let courseSelectValues = courseInfo.courseList.map((course: any) => {
            return {
                label: course.name,
                value: course._id
            }
        });
        this.setState({ courseList: courseSelectValues });
    }

    getFullCourses = async (org: string) => {
        let fullCourses: any = await this.courseService.getFullCourses(org);
        let courseSelectValues = fullCourses.fullCourseList.map((course: any) => {
            return {
                label: course.name,
                value: course.courseId
            }
        });
        this.setState({ courseList: courseSelectValues });
    }

    getEventList = async (date: string, org: string, filter: string, role: string, agent: string, verb: string, activity: string, courseId?: string): Promise<any> => {
        let eventList = await this.eventService.getEventList(date, org, agent, verb, activity, filter, role, courseId);
        this.setState({ eventList: eventList });
    }

    getEventTextAgent = async (date: string, org: string, filter: string, role: string, courseId?: string): Promise<any> => {
        let eventTextAgent = await this.eventService.getEventTextAgent(date, org, filter, role, courseId);
        this.setState({ eventTextAgent: eventTextAgent });
    }

    getEventTextVerb = async (date: string, org: string, filter: string, role: string): Promise<any> => {
        let eventTextVerb = await this.eventService.getEventTextVerb(date, org, filter, role);
        this.setState({ eventTextVerb: eventTextVerb });
    }

    getEventTextAct = async (date: string, org: string, filter: string, role: string, courseId?: string): Promise<any> => {
        let eventTextAct = await this.eventService.getEventTextAct(date, org, filter, role, courseId);
        this.setState({ eventTextAct: eventTextAct });
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 200) {
            alert("日期區間請在六個月內")
        } else {
            this.resetState();
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
            this.getEventList(date, this.org, this.account, this.role, this.state.agent, this.state.verb, this.state.activity, this.state.courseId);
            //this.getEventTextAgent(date, this.org, this.account, this.role);
            //this.getEventTextAct(date, this.org, this.account, this.role);
            //this.getEventTextVerb(date, this.org, this.account, this.role);
        }
    }

    handleAgent = (e: any) => {
        if (e === null)
            this.setState({ agent: "" })
        else
            this.setState({ agent: e.value })
    }

    handleVerb = (e: any) => {
        if (e === null)
            this.setState({ verb: "" })
        else
            this.setState({ verb: e.value })
    }

    handleActivity = (e: any) => {
        if (e === null)
            this.setState({ activity: "" })
        else
            this.setState({ activity: e.value })
    }

    handleCourseChange = (e: any) => {
        if (e === null){
            this.setState({ 
                courseId: '',
                eventTextAct: [{label: '讀取資料中', value: '-1'}],
                eventTextAgent: [{label: '讀取資料中', value: '-1'}] 
            });
        }else{
            this.setState({ 
                courseId: e.value,
                eventTextAct: [{label: '讀取資料中', value: '-1'}],
                eventTextAgent: [{label: '讀取資料中', value: '-1'}] 
            });
        }
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
        let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
        this.getEventTextAgent(date, this.org, this.account, this.role, e.value);
        this.getEventTextAct(date, this.org, this.account, this.role, e.value);
    }

    periodDateHandler = (e: any) => {
        const targetPeriod = e.target.value;
        if(targetPeriod){
            const periodStartDate = targetPeriod.split('/')[0];
            const periodEndDate = targetPeriod.split('/')[1];
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: true,
                eDateDisabled: true,
                sdate: periodStartDate,
                edate: periodEndDate
            });
        }else{
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: false,
                eDateDisabled: false
            });
        }
    }

    render() {
        return (
            <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <ul id="title-spacer" className="breadcrumbs">
                            <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >學習行為分析</span></Link>/</li>
                            <li><span>行為紀錄</span></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 py-1">
                        <h5>課程</h5>
                        <Select options={this.state.courseList} onChange={this.handleCourseChange} isClearable={true} />
                    </div>
                    <div className="col-md-3 py-1">
                        <h5>學期</h5>
                        <Form.Select value={this.state.periodDate} onChange={this.periodDateHandler}>
                            <option value="">自訂</option>
                            <option value="2022-02-01/2022-07-31">110-第二學期</option>
                            <option value="2021-08-01/2022-01-31">110-第一學期</option>
                            <option value="2021-02-01/2021-07-31">109-第二學期</option>
                            <option value="2020-08-01/2021-01-31">109-第一學期</option>
                        </Form.Select>
                    </div>
                    <div className="col-md-6 py-1">
                        <h5>時間</h5>
                        <div className="row">
                            <div className="col-auto">
                                <div className="input-group">
                                    <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} disabled={this.state.sDateDisabled} />
                                </div>
                            </div>
                            <div className="col-auto py-2 m-none lh-lg">~</div>
                            <div className="col-auto">
                                <div className="input-group">
                                    <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} disabled={this.state.eDateDisabled} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/*
                    <div className="col-md-3 py-1">
                        <h5>學生</h5>
                        <Select options={this.state.eventTextAgent} onChange={this.handleAgent} isClearable={true} />
                    </div>
                    */}
                    <div className="col-md-3 py-1">
                        <h5>動作</h5>
                        <Select options={defaultVerbList} onChange={this.handleVerb} isClearable={true} />
                    </div>
                    <div className="col-md-3 py-1">
                        <h5>活動</h5>
                        <Select options={this.state.eventTextAct} onChange={this.handleActivity} isClearable={false} />
                    </div>
                    <div className="col-md-3 py-1">
                        <h5>學生</h5>
                        <Select options={this.state.eventTextAgent} onChange={this.handleAgent} isClearable={false} />
                    </div>
                    <div className="col-md-2 py-1">
                        <h5>動作</h5>
                        <div className="col-auto ms-2 py-1">
                            <button className="btn btn-secondary" onClick={this.submitDateRange}>篩選</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3 pb-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>行為紀錄</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">行為紀錄</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，所有學習行為紀錄原始的 log (<a href="https://leaverij.gitbook.io/visca/tech/recipes" target="_blank" rel="noreferrer">所有學習行為說明頁面</a>)
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        {/*
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            功能
                                        </button>
                                        */}
                                    </div>
                                </div>
                            </div>
                            {this.state.eventList[0] === -1 ? < BehaviorTable columns={columns} loading={true} /> : < BehaviorTable columns={columns} data={this.state.eventList} expandableComponent={<ExpandableComponent data={this.state.eventList} />} />}
                        </div>
                    </div>
                </div>
            </main>
        );
    }

}

export default Behavior;