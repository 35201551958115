import React from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component'

interface QTProps {
    columns: IDataTableColumn[];
    data?: any
    columnName?: string;
    selected?: boolean;
    handleChange?: any
    loading?: boolean;
    toggledClearRows?:boolean;

}
interface QTState {}

class QuizTable extends React.Component<QTProps, QTState>{

    render() {
        return (
            <DataTable
                columns={this.props.columns}
                data={this.props.data}
                noHeader={true}
                defaultSortField={this.props.columnName}
                pagination
                selectableRows={this.props.selected}
                onSelectedRowsChange={this.props.handleChange}
                selectableRowsNoSelectAll
                progressPending={this.props.loading}
                clearSelectedRows={this.props.toggledClearRows}
            />
        )
    }
}

export default QuizTable;