import moment from 'moment';
import React from 'react';
import VideoChart from './videochart';
import VideoService from '../../services/videoService';
import Loading from 'react-loading';

interface FProps { }
interface FState {
    timesAry: any[];
    hourAry: any[];
    videoDuration: any[];
    videoInfo: any;
}

class VideoEmbed extends React.Component<FProps, FState>{
    private videoService: VideoService;
    private sdate: string;
    private edate: string;
    private type: string;
    private date: string;
    private videoId: string;
    private org: string;
    private account: string;
    private role: string;

    constructor(props: any) {
        super(props);
        this.videoService = new VideoService();
        this.org = window.location.pathname.split("/")[5];
        this.type = window.location.pathname.split("/")[3];
        this.date = window.location.pathname.split("/")[4];
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.role = urlParams.get("role") || "";
        this.account = urlParams.get("acct") || "";
        this.videoId = decodeURIComponent(urlParams.get("id") || "");
        this.state = {
            timesAry: [-1],
            hourAry: [-1],
            videoDuration: [-1],
            videoInfo: {
                durarion: "00:00:00"
            }
        }
        this.sdate = moment(this.date.slice(0, 8)).format('YYYY-MM-DD');
        this.edate = moment(this.date.slice(8, 16)).format('YYYY-MM-DD');
    }

    componentDidMount() {
        switch (this.type) {
            case "times":
                this.getVideoByDay(this.date, this.org, this.account, this.role);
                break;
            case "hours":
                this.getVideoByDay(this.date, this.org, this.account, this.role);
                break;
            case "hoursct":
                this.getSingleVideoByDay(this.date, this.videoId, this.org, this.account, this.role);
                break;
            case "timesct":
                this.getSingleVideoByDay(this.date, this.videoId, this.org, this.account, this.role);
                break;
            case "duration":
                this.getSingleVideo(this.date, this.videoId, this.org, this.account, this.role);
                break;

        }
    }

    getVideoByDay = async (sdate: string, org: string, filter: string, role: string) => {
        let videoList = await this.videoService.getVideosByDay(sdate, org, filter, role);
        let [timesAry, hoursAry] = this.settingDailyData(videoList)
        this.setState({ timesAry: timesAry, hourAry: hoursAry });
    }

    getSingleVideo = async (sdate: string, videoId: string, org: string, filter: string, role: string) => {
        let video = await this.videoService.getSingleVideo(sdate, videoId, org, filter, role);
        let videoList = await this.videoService.getSingleVideoEvent(sdate, videoId, org, filter, role);
        let data = this.calculateTimesInDuration(videoList, video.durarionAsSec);
        this.setState({ videoDuration: data })
    }

    getSingleVideoByDay = async (sdate: string, videoId: string, org: string, filter: string, role: string) => {
        let videoList = await this.videoService.getSingleVideoByDay(sdate, videoId, org, filter, role);
        let [timesAry, hoursAry] = this.settingDailyData(videoList)
        this.setState({ timesAry: timesAry, hourAry: hoursAry });
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any[]) => {
        let timesAry = [], hoursAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false;
            let index = 0;
            let hourData = {
                x: dateAry[i],
                y: 0
            }
            let timesData = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }
            if (hasValue) {
                if(this.type === "hoursct"){
                    hourData.y = Math.floor(ary[index].watchHours / 60);
                }else{
                    hourData.y = Math.floor(ary[index].watchHours / 3600);
                }
                timesData.y = Math.floor(ary[index].watchTimes)
                timesAry.push(timesData);
                hoursAry.push(hourData);
            }
            else {
                timesAry.push(timesData);
                hoursAry.push(hourData);
            }
        }
        return [timesAry, hoursAry];
    }

    calculateTimesInDuration = (videoData: any[], durarionAsSec: number) => {
        let dataArray = [];
        for (let i = 0; i <= durarionAsSec; i++) {
            let data = {
                x: moment().startOf('day').seconds(i).format('HH:mm:ss'),
                y: 0
            }
            dataArray.push(data);
        }
        if (durarionAsSec >= 0) {
            for (let i = 0; i < videoData.length; i++) {
                for (let j = Math.floor(videoData[i].start); j < Math.floor(videoData[i].end); j++) {
                    dataArray[j].y++;
                }
            }
        }
        return dataArray;
    }


    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    tickFormatDur = (x: any) => {
        if (x === "00:00:00" || x === this.state.videoInfo.durarion)
            return x;
    }

    renderLoading() {
        return <Loading type={"spin"} color={"#ffffff"} height={'20%'} width={'20%'}></Loading>
    }

    renderType = () => {
        switch (this.type) {
            case "times":
                return (
                    <VideoChart
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"次數"}
                        data={this.state.timesAry}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nwatch ${datum.y} times`}
                    />
                );
            case "hours":
                return (
                    <VideoChart
                        tickFormat={this.tickFormat}
                        labelx={"日期"}
                        labely={"小時"}
                        data={this.state.hourAry}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nwatch ${datum.y} hours`}
                    />
                )
            case "timesct":
                return (
                    <VideoChart
                        labelx={"日期"}
                        labely={"次數"}
                        data={this.state.timesAry}
                        tickFormat={this.tickFormat}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nwatch ${datum.y} times`}
                    />
                )
            case "hoursct":
                return (
                    <VideoChart
                        labelx={"日期"}
                        labely={"分鐘"}
                        data={this.state.hourAry}
                        tickFormat={this.tickFormat}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nwatch ${datum.y} mins`}
                    />
                )
            case "duration":
                return (
                    <VideoChart
                        tickFormat={this.tickFormatDur}
                        labelx={"影片時間"}
                        labely={"次數"}
                        data={this.state.videoDuration}
                        //domain={[0, 7]}
                        datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nwatch ${datum.y} times`}
                    />
                )

        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 pt-3">
                    {this.renderType()}
                </div>
            </div>
        );
    }
}

export default VideoEmbed;