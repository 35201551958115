import React from 'react';
import { Link } from 'react-router-dom';

interface MProps {
    link: string;
}
interface MState {

}

class LinkBtn extends React.Component<MProps, MState>{
    render() {
        return (
            <Link to={this.props.link} className="dropdown-item download" target="_blank">產生連結</Link>
        )
    }
}

export default LinkBtn;