import moment from 'moment';
import React from 'react';
import BlockchainChart from './blockchainChart';
import BlockchainTable from './blockchainTable';
import BlockchainDetail from './blockchainDetail';
import Loading from 'react-loading';
import { Link } from 'react-router-dom';
import ImgBtn from '../opionBtn/exportImage';
import Select from 'react-select';
//import CSVBtn from '../opionBtn/exportCSV';
//import LinkBtn from '../opionBtn/exportLink';
import BlockchainService from '../../services/blockchainService';
import AccountService from '../../services/accountService';
import memoize from 'memoize-one';
import { LoginContext } from '../../loginContext';


interface FProps { }
interface FState {
    blockchainList: any[];
    blockchainDailyList: any[];
    blockchainInfo: any;
    blockchainDetail: any;
    opencertList: any[];
    sdate: string;
    edate: string;
    service: string;
}

const columns = memoize((handleAction) => [
    {
        name: "ID",
        selector: "ID",
        sortable: true,
        width: "10%"
    },
    {
        name: "certId",
        selector: "certId",
        sortable: true,
        width: "12%"
    },
    {
        name: "名稱",
        selector: "name",
        sortable: true,
        width: "20%"
    },
    {
        name: "日期",
        selector: "date",
        sortable: true,
        width: "20%"
    },
    {
        name: "發行人",
        selector: "issuer",
        sortable: true,
        width: "12%"
    },
    {
        name: "持有者",
        selector: "user_account",
        sortable: true,
        width: "13%"
    },
    {
        name: "詳細內容",
        cell: (row: any) => <button className="nav-link active" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => { handleAction(row) }}>詳細內容</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: "10%"
    }
]);

class Blockchain extends React.Component<FProps, FState>{
    private sdate: string;
    private edate: string;
    private org: string;
    private account: string;
    private role: string;
    private date: string;
    private app: any[];
    private opencert: any[];
    private blockchainService: BlockchainService;
    private accountService: AccountService;
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        this.org = "";
        this.account = "";
        this.role = "";
        this.blockchainService = new BlockchainService();
        this.accountService = new AccountService();
        this.state = {
            blockchainList: [-1],
            blockchainDailyList: [-1],
            opencertList: [-1],
            service: "IOTA",
            blockchainInfo: {
                total: -1,
                people: -1,
            },
            blockchainDetail: {},
            sdate: moment().subtract(14, 'day').format('YYYY-MM-DD'),
            edate: moment().format('YYYY-MM-DD')
        }
        this.app = [];
        this.opencert = [];
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
        this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
    }

    async componentDidMount() {
        this.app = await this.accountService.getApp()
        this.opencert = await this.accountService.getOpencert()
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
        if (this.app.length > 0)
            this.getData(this.app[0]);
        else {
            this.setState({
                blockchainList: [],
                blockchainDailyList: [],
                blockchainInfo: {
                    total: 0,
                    people: 0,
                }
            })
        }
    }

    getData = async (appId: string) => {
        let data = await this.blockchainService.getData(appId);
        let ary = await this.processData(data.data);
        let unique = ary.map(item => item.user_account)
            .filter((value, index, self) => self.indexOf(value) === index);
        let dailyAry = await this.settingDailyData(ary);
        this.setState({ blockchainList: ary, blockchainInfo: { total: ary.length, people: unique.length }, blockchainDailyList: dailyAry })
    }

    getOpenCert = async (appId: string) => {
        let data = await this.blockchainService.getOpenCerts(appId);
        let opencert = await this.processOpencert(data);
        let unique = opencert.map(item => item.user_account)
            .filter((value, index, self) => self.indexOf(value) === index);
        let dailyAry = await this.settingDailyData(opencert);

        this.setState({ blockchainList: opencert, blockchainInfo: { total: opencert.length, people: unique.length }, blockchainDailyList: dailyAry })
    }


    resetState = () => {
        this.setState({
            blockchainList: [-1],
            blockchainDailyList: [-1],
            blockchainInfo: {
                total: -1,
                people: -1,
            }
        })
    }

    processData = async (data: any) => {
        let ary = [];
        for (let i = 0; i < data.length; i++) {
            data[i].data.date = moment(data[i].data.date).format("YYYY/MM/DD")
            ary.push(data[i].data);
        }
        return ary;
    }

    processOpencert = async (data: any) => {
        let ary = [];
        let certList = Object.keys(data.data)
        console.log(certList)
        for (let i = 0; i < certList.length; i++) {
            let jsonData = JSON.parse(data.data[certList[i]])
            console.log(jsonData)
            let cert = {
                ID: `OpenCerts ${i}`,
                certId: jsonData.data.name.split(':')[0],
                name: jsonData.data.name.split(':')[2],
                date: jsonData.data.report.row1.col_sub1.row1.split(':')[2],
                issuer: jsonData.data.issuers[0].name.split(':')[2],
                user_account: jsonData.data.$template.name.split(':')[2],
                imgOrFile: jsonData.data.$template.url.split(':')[2] + ":" + jsonData.data.$template.url.split(':')[3],
                verification: jsonData.data.issuers[0].identityProof.location.split(':')[2],
                describe: jsonData.data.descriptors.title.text.split(':')[2],
                "Transaction ID": jsonData.signature.targetHash
            }
            //data[i].data.date = moment(data[i].data.date).format("YYYY/MM/DD")
            ary.push(cert);
        }
        return ary;
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any[]) => {
        let BCAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let BCData = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (ary[j].date === dateAry[i]) {
                    BCData.y++;
                }
            }
            BCAry.push(BCData);
        }
        return BCAry;
    }


    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    roleType = () => {
        if (this.role === "admin" || this.role === "owner")
            return true;
        else
            return false;
    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 90) {
            alert("日期區間請在三個月內")
        } else {
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
            this.resetState();
        }
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    renderLoading() {
        return <Loading type={"spin"} color={"#ffffff"} height={'20%'} width={'20%'}></Loading>
    }

    setvalue = (data: any) => {
        this.setState({ blockchainDetail: data })
    }

    detail = () => {
        return (
            <BlockchainDetail data={this.state.blockchainDetail} />
        )
    }

    handleService = (e: any) => {
        if (e === null || e.value === "IOTA") {
            if (this.app.length > 0)
                this.getData(this.app[0]);
            else {
                this.setState({
                    blockchainList: [],
                    blockchainDailyList: [],
                    blockchainInfo: {
                        total: 0,
                        people: 0,
                    }
                })
            }
        }
        else {
            if (this.app.length > 0)
                this.getOpenCert(this.opencert[0]);
            else {
                this.setState({
                    blockchainList: [],
                    blockchainDailyList: [],
                    blockchainInfo: {
                        total: 0,
                        people: 0,
                    }
                })
            }
        }
    }

    render() {
        return (
            <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <ul id="title-spacer" className="breadcrumbs">
                            <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >學習履歷</span></Link> \</li>
                            <li><span>區塊鏈</span></li>
                        </ul>
                    </div>
                    <div className="d-md-flex justify-content-md-end">
                        <div className="col-auto col-lg-3 ms-2 py-1">
                            <Select options={[{ label: "IOTA", value: "IOTA" }, { label: "OpenCerts", value: "opencert" }]} onChange={this.handleService} isClearable={false} />
                        </div>
                        {/* <div className="col-auto ms-2 py-1">
                            <div className="input-group">
                                <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} />
                            </div>
                        </div>
                        <div className="col-auto ms-2 py-2 m-none lh-lg">~</div>
                        <div className="col-auto ms-2 py-1">
                            <div className="input-group">
                                <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} />
                            </div>
                        </div>
                        <div className="col-auto ms-2 py-1">
                            <button className="btn btn-secondary" onClick={this.submitDateRange}>確定</button>
                        </div> */}
                    </div>
                    <div className="col-md-2 py-1">
                        <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                            <h2>{(this.state.blockchainInfo.people === -1) ? this.renderLoading() : this.state.blockchainInfo.people}</h2>
                            <h5>使用者</h5>
                        </div>
                    </div>
                    <div className="col-md-2 py-1">
                        <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                            <h2>{(this.state.blockchainInfo.total === -1) ? this.renderLoading() : this.state.blockchainInfo.total}</h2>
                            <h5>成果</h5>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>上鏈數量</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            {/* <li><LinkBtn link={(this.roleType()) ? `/embed/discuss/event/${this.date}/${this.org}` : `/embed/discuss/event/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li> */}
                                            {/* <li><button className="dropdown-item" onClick={() => this.refresh(0)}>重新整理</button></li> */}
                                            {/* <li><CSVBtn data={(this.state.blockchainDailyList)} /></li> */}
                                            <li><ImgBtn id={0} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <BlockchainChart
                                //tickFormat={this.tickFormat}
                                labelx={"日期"}
                                labely={"筆數"}
                                data={this.state.blockchainDailyList}
                                datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} records`}
                                chartType={"line"}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 pt-3">
                    <div className="bg-body rounded shadow-sm px-3">
                        <div
                            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                            <h4>上鏈紀錄</h4>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <div className="input-group">
                                    {/* <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        功能
                                    </button> */}
                                </div>
                            </div>
                        </div>
                        {this.state.blockchainList[0] === -1 ? < BlockchainTable columns={columns(this.setvalue)} loading={true} /> : < BlockchainTable columns={columns(this.setvalue)} data={this.state.blockchainList} />}
                        {this.detail()}
                    </div>
                </div>
            </main >
        );
    }

}

export default Blockchain;