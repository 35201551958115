import HttpHandler from './APIServices';
import { BCAPI } from './API';

class BlockchainService {
    private api: BCAPI;

    constructor() {
        this.api = new BCAPI()
    }

    getData = async (appId:string): Promise<any> => {
        let url = `${this.api.basePath}/${appId}/${this.api.getData}`;
        let credentials: RequestCredentials = "include"
        let option = {
            method: 'GET',
            credentials: credentials
        }
        let data = await HttpHandler(url, option);            
        return data;
    }

    getOpenCert = async (appId:string): Promise<any> => {
        let url = `${this.api.basePath}/${appId}/${this.api.getOpenCert}?id=`;
        let credentials: RequestCredentials = "include"
        let option = {
            method: 'GET',
            credentials: credentials
        }
        let data = await HttpHandler(url, option);            
        return data;
    }

    getOpenCerts = async (appId:string): Promise<any> => {
        let url = `${this.api.basePath}/${appId}/${this.api.getOpenCerts}`;
        let credentials: RequestCredentials = "include"
        let option = {
            method: 'GET',
            credentials: credentials
        }
        let data = await HttpHandler(url, option);            
        return data;
    }


    getOpenCertInfo = async (appId:string): Promise<any> => {
        let url = `${this.api.basePath}/${appId}/${this.api.getOpenCertInfo}`;
        let credentials: RequestCredentials = "include"
        let option = {
            method: 'GET',
            credentials: credentials
        }
        let data = await HttpHandler(url, option);            
        return data;
    }
/*
    addData = async (appId: any): Promise<any> => {
        let url = `${this.api.basePath}/${appId}/${this.api.addData}`;
        let option = {
            method: 'POST',
            body: JSON.stringify(user),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }
        let data = await HttpHandler(url, option);
        return data;
    }
*/
    login = async (user: any): Promise<any> => {
        let url = `${this.api.basePath}${this.api.login}`;
        let option = {
            method: 'POST',
            body: JSON.stringify(user),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }
        let data = await HttpHandler(url, option);
        return data;
    }
}

export default BlockchainService;