import React from 'react';
import CourseUnitRow from './courseUnitRow';
import AnalyticsSettingService from '../../services/analyticsSettingService';

interface AState {
    id: string;
    name: string;
    units: any;
}

class CourseContentSetting extends React.Component<{}, AState>{

    private analyticsSettingService: AnalyticsSettingService;
    private courseId: string;
    constructor(props: any) {
        super(props);
        this.analyticsSettingService = new AnalyticsSettingService();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.courseId = decodeURIComponent(urlParams.get("id") || "");
        this.state = {
            id: '',
            name: '',
            units: []
        };
    }

    componentDidMount() {
        this.getCourse(this.courseId);
    }

    getCourse = async (courseId: string) => {
        let course = await this.analyticsSettingService.getCourse(courseId);
        this.setState({
            id: course.courseId,
            name: course.courseName,
            units: course.units || []
        });
    }

    addUnit = () => {
        let units = this.state.units;
        units.push({unitName: '', videoDuration: '', unitType: '', unitWeek: 1});
        this.setState({units: units});
    }

    addContent = async () => {
        let data = {
            courseId: this.state.id,
            courseName: this.state.name,
            units: this.state.units
        };
        await this.analyticsSettingService.addCourseContent(data);
        alert('儲存成功!');
    }

    onInputChangeHandler = (targetName: string, targetValue: string, targetIndex: string) => {
        let currentUnits = this.state.units;
        currentUnits[targetIndex][targetName] = targetValue;
        this.setState({ units: currentUnits });
    }

    onSelectChangeHandler = (targetName: string, targetValue: string, targetIndex: string) => {
        let currentUnits = this.state.units;
        currentUnits[targetIndex][targetName] = targetValue;
        this.setState({ units: currentUnits });
    }

    render() {
        return (
            <>
                <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><span>分析設定</span> \</li>
                                <li><span>課程設定</span></li>
                                <li><span>課程內容設定</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <form>
                                <div className="form-group">
                                    <label>課程 ID</label>
                                    <input type="text" className="form-control-plaintext" value={this.state.id} readOnly />
                                </div>
                                <div className="form-group">
                                    <label>課程名稱</label>
                                    <input type="text" className="form-control-plaintext" value={this.state.name} readOnly />
                                </div>
                                <div className="form-group">
                                    <label>課程內容</label>
                                    <button type="button" className="ml-2" onClick={this.addUnit}>增加單元</button>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>單元名稱</th>
                                                <th>單元影片長度</th>
                                                <th>類型</th>
                                                <th>周次</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.units.map((unit: any, index: number) => <CourseUnitRow 
                                            unitName={unit.unitName} 
                                            unitType={unit.unitType}
                                            unitWeek={unit.unitWeek}
                                            videoDuration={unit.videoDuration}
                                            index={index}
                                            onInputChangeHandler={this.onInputChangeHandler}
                                            onSelectChangeHandler={this.onSelectChangeHandler}
                                        />)}
                                        </tbody>
                                    </table>
                                </div>
                                <button type="button" className="btn btn-primary" onClick={this.addContent}>儲存</button>
                            </form>
                        </div>
                    </div>
                </main>
            </>
        )
    }

}

export default CourseContentSetting;