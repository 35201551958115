import React from 'react';
import { VictoryChart, VictoryAxis, DataGetterPropType, VictoryLabel, VictoryBar, VictoryTooltip, VictoryVoronoiContainer, VictoryLine, VictoryStack, VictoryLegend, VictoryTheme } from 'victory';
import Loading from 'react-loading';

interface QCProps {
    tickValues?: any[];
    tickFormat?: any;
    x?: DataGetterPropType;
    y?: DataGetterPropType;
    labelx?: string;
    labely?: string;
    data?: any[];
    datum?: any;
    datamFunc?: any
    domain?: any;
    chartType?: string;
    dataStack?: any[];
    nameData?: any[];
}

interface QCState { }

class QuizChart extends React.Component<QCProps, QCState>{
    renderLoading() {
        return <Loading type={"cylon"} color={"black"} height={'30%'} width={'30%'}></Loading>
    }

    renderBar() {
        return (
            <VictoryChart
                domain={this.props.domain}
                height={200}
                width={400}
                domainPadding={{ x: 12 }}
                containerComponent={
                    <VictoryVoronoiContainer
                        labels={this.props.datamFunc}
                        labelComponent={
                            <VictoryTooltip
                                cornerRadius={10}
                                flyoutWidth={30}
                                flyoutStyle={{
                                    stroke: "none",
                                    fill: "black"
                                }}
                                style={{ fill: "white", fontSize: 6 }} />
                        }
                    />
                }
            >
                <VictoryLabel x={20} y={24}
                    text={this.props.labely}
                    style={{ fontSize: "6px" }}
                />
                <VictoryLabel x={400} y={280}
                    text={this.props.labelx}
                    style={{ fontSize: "6px" }}
                />
                <VictoryAxis
                    style={{
                        tickLabels: {
                            fontSize: 6
                        }
                    }}
                    tickValues={this.props.tickValues}
                    tickFormat={this.props.tickFormat}
                />
                <VictoryAxis
                    dependentAxis
                    style={{
                        tickLabels: {
                            fontSize: 6
                        }
                    }}
                />
                <VictoryBar
                    labels={this.props.datum}
                    //labelComponent={<VictoryTooltip style={{ fontSize: 5 }} />}
                    data={this.props.data}
                    x={this.props.x}
                    y={this.props.y}
                    style={{
                        data: {
                            fill: "#00DDDD",
                            stroke: "#c43a31",
                            fillOpacity: 0.7,
                            strokeWidth: 0.5
                        }
                    }}
                />
            </VictoryChart>
        )
    }

    renderLineStack() {
        let data: any = this.props.dataStack
        let colorScale = ["red", "blue", "purple", "green"];
        const getLineData = () => {
            if (data !== undefined && data.length > 0) {
                let line = data.map((data: any, index: any) => {
                    return <VictoryLine key={index} data={data}
                        style={{
                            data: {
                                stroke: colorScale[index],
                                strokeWidth: 2.0
                            }
                        }}
                        labelComponent={
                            <VictoryLabel
                                renderInPortal
                                dy={-3}
                                textAnchor="start"
                                style={{
                                    fontSize: "8",
                                }}
                            />
                        }
                    />
                })
                return line;
            }
        }
        return (
            <>
                <VictoryChart
                    theme={VictoryTheme.material}
                    domain={this.props.domain}
                    height={268}
                    width={400}
                    //domainPadding={{ x: 10 }}
                    containerComponent={
                        <VictoryVoronoiContainer
                            labels={this.props.datamFunc}
                            labelComponent={
                                <VictoryTooltip
                                    cornerRadius={10}
                                    flyoutWidth={70}
                                    flyoutStyle={{
                                        stroke: "none",
                                        fill: "black"
                                    }}
                                    style={{ fill: "white", fontSize: 6 }}
                                />
                            }
                        />
                    }>
                    <VictoryLegend x={150} y={10}
                        orientation="horizontal"
                        //itemsPerRow={7}
                        gutter={25}
                        style={{ border: { stroke: "black" }, labels: { fontSize: 8 } }}
                        colorScale={["red", "blue", "purple", "green"]}
                        data={[{ name: "高標" }, { name: "低標" }, { name: "標準差" }, { name: "平均分數" }]}
                    />
                    <VictoryLabel x={20} y={24}
                        text={this.props.labely}
                        style={{ fontSize: "6x" }}
                    />
                    <VictoryLabel x={400} y={280}
                        text={this.props.labelx}
                        style={{
                            fontSize: "6px",
                        }}
                    />
                    <VictoryAxis
                        tickLabelComponent={<VictoryLabel angle={45} dx={12}
                            verticalAnchor="start" />} style={{
                                tickLabels: {
                                    fontSize: 10,

                                },
                                axisLabel: {

                                }
                            }}
                        tickValues={this.props.tickValues}
                        tickFormat={this.props.tickFormat}
                    />
                    <VictoryAxis
                        dependentAxis
                        style={{
                            tickLabels: {
                                fontSize: 8
                            }
                        }}
                    />
                    {getLineData()}
                </VictoryChart>
            </>
        )
    }

    renderStack() {
        let data: any = this.props.dataStack
        const getBarData = () => {
            if (data !== undefined && data.length > 0) {
                let bar = data.map((data: any, index: any) => {
                    return <VictoryBar key={index} data={data} />
                })
                return bar;
            }
        }
        return (
            <>
                <VictoryChart
                    //theme={VictoryTheme.material}
                    domain={this.props.domain}
                    domainPadding={{ x: 30 }}
                    containerComponent={
                        <VictoryVoronoiContainer
                            labels={this.props.datamFunc}
                            labelComponent={
                                <VictoryTooltip
                                    cornerRadius={10}
                                    flyoutWidth={90}
                                    flyoutStyle={{
                                        stroke: "none",
                                        fill: "black"
                                    }}
                                    style={{ fill: "white", fontSize: 10 }}
                                />
                            }
                        />
                    }>
                    <VictoryLabel x={20} y={24}
                        text={this.props.labely}
                        style={{ fontSize: "10px" }}
                    />
                    <VictoryLabel x={400} y={280}
                        text={this.props.labelx}
                        style={{
                            fontSize: "10px",
                        }}
                    />
                    <VictoryAxis
                        tickLabelComponent={<VictoryLabel angle={45} dx={12}
                            verticalAnchor="start" />}
                        style={{
                            tickLabels: {
                                fontSize: 10,

                            },
                            axisLabel: {

                            }
                        }}
                        tickValues={this.props.tickValues}
                        tickFormat={this.props.tickFormat}
                    />
                    <VictoryAxis
                        dependentAxis
                        style={{
                            tickLabels: {
                                fontSize: 10
                            }
                        }}
                    />
                    <VictoryStack colorScale={["green", "red"]}>
                        {getBarData()}
                    </VictoryStack>

                </VictoryChart>
            </>
        )
    }



    render() {
        if (this.props.data !== undefined && this.props.data[0] === -1) {
            return (
                <>
                    {this.renderLoading()}
                </>
            )
        }
        else if (this.props.chartType === "bar")
            return (
                <>
                    {this.renderBar()}
                </>
            )
        else if (this.props.chartType === "lineStack")
            return (
                <>
                    {this.renderLineStack()}
                </>
            )
        else if (this.props.chartType === "stack")
            return (
                <>
                    {this.renderStack()}
                </>
            )
    }
}

export default QuizChart;